export const MODAL_ADD = 'MODAL_ADD';
export const MODAL_REMOVE = 'MODAL_REMOVE';
export const MODAL_TOGGLE = 'MODAL_TOGGLE';

export const addModal = id => ({
  type: MODAL_ADD,
  id: id
});

export const removeModal = id => ({
  type: MODAL_REMOVE,
  id: id
});

export const toggleModal= id => ({
  type: MODAL_TOGGLE,
  id: id
});
