import QueryPage from "../components/QueryPage";
import edit from "../icons/edit.png";
import FilterFormContractTracking from "../FilterForm/FilterFormContractTracking";
import {Button, Col, Label} from "reactstrap";
import React, {Component} from "react";
import {connect} from "react-redux";
import {getRequestBlob} from "../actions/api";
import {closeContractTracking, openContractTracking} from "../actions/contractTracking";
import ContractTrackingForm from "../DetailedForm/ContractTrackingForm";
import ReactTooltip from 'react-tooltip'
import {addModal, toggleModal} from '../actions/modal.js';
import Modal from "../components/Modal";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'

class ContractTrackingPage extends Component {

  toggleEditModal=(object = undefined) =>{
    if(object !== undefined){
      this.props.openContractTracking(object.id)
    }
    else{
      this.props.openContractTracking()
    }

    this.props.toggleModal('contract-edit');
  };

  // Wrapper for downloading a single file from the getFileById data access function
  getFile = (id, fileName) => {
    this.props.getFileById(id).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const modifiedName = fileName.split(".pdf")[0]
      link.setAttribute('download', modifiedName + '.' + response.data.type);
      document.body.appendChild(link);
      link.click();
    })
  }

  render() {
    return (
      <div>
        <QueryPage
          queryTableID='contracttracking'
          filterForm={<FilterFormContractTracking/>}
          pageURL="/kisokostest/contracts/tracking"
          optionalElements={
            [
              <Col className="col-1 justify-content-md-start text-left App-page-label-wider">
                <Label className="App-label App-label-title">Szerződés folyamat követése</Label>
              </Col>
            ]
          }
          headers={
            [
            {
              name: 'Dokumentumnév',
              processor: object => object.attachmentFileName,
              className: "Table-attachmentFileName",
              orderName: "attachmentFileName"
            },{
              name: 'Feltöltés dátuma',
              processor: object => object.attachmentFileUploaded.toString().slice(0,10).replace(/-/gi, '.') + '.',
              className: "Table-attachmentFileUploaded_contract",
              orderName: "attachmentFileUploaded_contract"
            },{
              name: 'Ügyfél email cím',
              processor: object => object.clientEmailAddress,
              className: "Table-clientEmailAddress",
              orderName: "clientEmailAddress"
            },{
              name: 'Tördelő',
              processor: object => object.tordelo,
              className: "Table-tordelo",
              orderName: "tordelo"
            },{
              name: 'Kiküldött kefe',
              processor: object => object.kefe_sent,
              className: "Table-kefe_sent",
              orderName: "kefe_sent"
            },{
              name: 'Kefe visszaigazolás',
              processor: object => object.kefe_confirmation,
              className: "Table-kefe_confirmation",
              orderName: "kefe_confirmation"
            },{
              name: 'Komment - 1',
              processor: object => object.comment1,
              className: "Table-comment1",
              orderName: "comment1"
            },{
              name: 'Komment - 2',
              processor: object => object.comment2,
              className: "Table-comment2",
              orderName: "comment2"
            },{
              name: 'Letöltés',
              processor: object => <FontAwesomeIcon className="faBlue" icon={faFileDownload} size="2x" cursor="pointer" onClick={() => this.getFile(object.attachmentFileId, object.attachmentFileName)} />,
              className: "Table-attachmentFileId",
              orderName: "attachmentFileId",
              ignoreExport: true
            },{
              name: '',
              processor: object => 
                <Button  className="App-icon-button" size={'sm'} onClick={() => this.toggleEditModal(object)}>
                  <img className="App-icon-button" src={edit}  alt={"Szerk."} data-tip="Szerkesztés"/>
                  <ReactTooltip />
                </Button>,
              className: "Table-btn",
              ignoreExport: true
            },{
              name: '',
              processor: object=> "",
              className: "Table-filler",
              ignoreExport: true
            }
            ]
          }
        />
        <Modal className="modal-dialog_wide" id='contract-edit' headerName="Szerződés adatainak módosítása">
          <ContractTrackingForm/>
        </Modal>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  const editId = state.contractTracking.contractTrackingId;
  return{
    editId
  }
};

const mapDispatchToProps = dispatch => ({
  closeContractTracking: () =>{
    dispatch(closeContractTracking())
  },
  openContractTracking: (id) =>{
    dispatch(openContractTracking(id));
  },
  addModal: id => {
    dispatch(addModal(id));
  },
  toggleModal: id => {
    dispatch(toggleModal(id));
  },
  getFileById: (fileId) => {
    return dispatch(getRequestBlob('/kisokostest/files/' + fileId))
  }
});

export default connect(mapStateToProps,mapDispatchToProps)(ContractTrackingPage);

