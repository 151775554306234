export const OPEN_COLLEAGUE = 'OPEN_COLLEAGUE';
export const CLOSE_COLLEAGUE = 'CLOSE_COLLEAGUE';

export const openColleague = (id) => ({
  type: OPEN_COLLEAGUE,
  colleagueId: id
});

export const closeColleague = () => ({
  type: CLOSE_COLLEAGUE
});
