import React, {Component} from 'react';
import QueryPage from "../components/QueryPage";
import {Button, Col, Label} from "reactstrap";
import Modal from "../components/Modal";
import {toggleModal} from "../actions/modal";
import {connect} from "react-redux";
import PaymentForm from "../DetailedForm/PaymentForm";
import FilterFormPayment from "../FilterForm/FilterFormPayment";
import {closePayment, openPayment} from "../actions/payment";
import {openContractItem} from "../actions/contractItem";
import edit from "../icons/edit.png";
import ReactTooltip from "react-tooltip";
import {vatkey} from "../Util";
import { getRequest } from "../actions/api";

class PaymentPage extends Component {
  toggleEditModal = (object = undefined) => {
    if (object !== undefined) {
      this.props.openPayment(object.id);
      this.props.openContractItem(object.contract_item.id)
    } else {
      this.props.openPayment();
      this.props.openContractItem()
    }

    this.props.toggleModal('payment-edit');
  };

  getCashInvoiceStatus = (invoiceNumber) => {
    this.props.getCashInvoiceStatus(invoiceNumber).then(response => {
      console.log('getCashInvoiceStatus', response.data);
    })
  }

  render() {
    return (
      <div>
        <QueryPage
          queryTableID='payments'
          filterForm={<FilterFormPayment/>}
          pageURL="/kisokostest/payments"
          optionalElements={
            [
              <Col className="col-2 justify-content-md-start text-left App-page-label">
                <Label className="App-label App-label-title">Befizetések</Label>
              </Col>,
              <Col className="col-2 justify-content-md-start text-left">

              </Col>
            ]
          }
          headers={
            [
            {
              name: 'Szerződés kelte',
              processor: object => object.contract_item.contract.contract_date,
              className: "Table-date",
              orderName: 'contract_item__contract__contract_date'
            }, {
              name: 'Szerződésszám',
              processor: object => object.contract_item.contract.contract_number,
              className: "Table-contract_no",
              orderName: 'contract_item__contract__contract_number'
            }, {
              name: 'Ügyfél neve',
              processor: object => object.contract_item.contract.client.client_name,
              className: "Table-invoice_name",
              orderName: 'contract_item__contract__client__client_name'
            }, {
              name: 'Irsz.',
              processor: object => object.contract_item.contract.client.billing_address.postcode,
              className: "Table-postcode",
              orderName: 'contract_item__contract__client__billing_address__postcode'
            }, {
              name: 'Város',
              processor: object => object.contract_item.contract.client.billing_address.city,
              className: "Table-city",
              orderName: 'contract_item__contract__client__billing_address__city'
            }, {
              name: 'Cím',
              processor: object => object.contract_item.contract.client.billing_address.address,
              className: "Table-address",
              orderName: 'contract_item__contract__client__billing_address__address'
            }, {
              name: 'Számlaszám',
              processor: object => object.identifier,
              className: "Table-contract_no",
              orderName: 'identifier'
            }, {
              name: 'Fizetési mód',
              processor: object => object.payment_method && object.payment_method.type,
              className: "Table-salesperson_name",
              orderName: 'payment_method__type'
            }, {
              name: 'Üzletkötő',
              processor: object => object.contract_item.contract.salesperson.name,
              className: "Table-salesperson_name",
              orderName: 'contract_item__contract__salesperson__name'
            }, {
              name: 'N.Összeg',
              processor: object => object.sum ? object.sum.toLocaleString("hu-HU") : 0,
              exportProcessor: object => object.sum ? object.sum : 0,
              className: "Table-summ"
            }, {
              name: 'Br.Összeg',
              processor: object => Math.round(object.sum * vatkey) ? Math.round(object.sum * vatkey).toLocaleString("hu-HU") : 0,
              exportProcessor: object => Math.round(object.sum * vatkey) ? Math.round(object.sum * vatkey) : 0,
              className: "Table-summ",
            }, {
              name: 'Befizetve',
              processor: object => object.payment_date,
              className: "Table-date",
              orderName: 'payment_date'
            }, {
              name: 'K.kód',
              processor: object => object.contract_item.product.code,
              className: "Table-contract_no",
              orderName: 'contract_item__product__code'
            }, {
              name: 'Bef. Azonosító',
              processor: object => object.certificate,
              className: "Table-contract_no",
              orderName: 'certificate'
            }, {
              name: 'Naplósorszám',
              processor: object => object.diary_page_number,
              className: "Table-contract_no",
              orderName: 'diary_page_number'
            }, {
              name: '',
              processor: object => <Button className="App-icon-button" size={'sm'} onClick={() => this.toggleEditModal(object)}><img className="App-icon-button" src={edit}  alt={"Szerk."} data-tip="Szerkesztés"/><ReactTooltip /></Button>,
              className: "Table-btn",
              ignoreExport: true
            }, {
              name: '',
              processor: object => "",
              className: "Table-filler",
              ignoreExport: true
            }
            ]
          }
        />
        <Modal className="modal-dialog_mid" id='payment-edit' headerName="Befizetés">
          <PaymentForm/>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const editId = state.client.clientId;
  const modal = state.modal['client-edit'];
  const modalOpen = modal ? modal.isOpen : false;
  return {
    editId,
    modalOpen
  }
};

const mapDispatchToProps = dispatch => ({
  closePayment: () => {
    dispatch(closePayment())
  },
  openPayment: (id) => {
    dispatch(openPayment(id));
  },
  openContractItem: (id) => {
    dispatch(openContractItem(id));
  },
  toggleModal: id => {
    dispatch(toggleModal(id));
  },
  getCashInvoiceStatus: (invoiceNumber) => {
    return dispatch(getRequest(`/kisokostest/invoice/cash?invoiceNumber=${invoiceNumber}`))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage)
