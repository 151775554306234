import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  Spinner,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getRequest, postRequest } from "../actions/api";
import { API_ROOT_PATH } from "../actions/api.js";
import { parseISO, differenceInHours, format } from "date-fns";

const displayDiscount = (discount) => {
  const discountAsDate = parseISO(discount);
  const diffHours = differenceInHours(discountAsDate, new Date(), {
    roundingMethod: "floor",
  });
  if (diffHours > 0 && diffHours <= 74) {
    return `aktív (${format(discountAsDate, "yyyy. MM. dd. HH:mm:ss")}-ig)`;
  } else {
    return "inaktív";
  }
};

class ClientList extends Component {
  render() {
    if (this.props.clients.length === 0) {
      return (
        <p
          className="large-alert"
          style={{
            color: "#721c24",
            backgroundColor: "#f8d7da",
            borderColor: "#f5c6cb",
            textAlign: "center",
            padding: "0.75rem 1.25rem",
          }}
        >
          Nincs találat a "{this.props.keyword}" keresésre!
        </p>
      );
    } else {
      return (
        <div>
          <div className="discount-body-web">
            <Table responsive striped hover>
              <thead>
                <tr>
                  <th>Ügyfélnév</th>
                  <th>Kedvezmény állapota</th>
                  <th>Kedvezmény aktiválása</th>
                </tr>
              </thead>
              <tbody>
                {this.props.clients.map((client) => {
                  const discount = displayDiscount(
                    client.isGreaterDiscountAvailable
                  );
                  return (
                    <tr key={client.id}>
                      <td>{client.client_name}</td>
                      <td>{discount}</td>
                      <td>
                        {discount.includes("-ig") ? (
                          <Button
                            active
                            color="danger"
                            onClick={() => this.props.deactivate(client.id)}
                          >
                            törlés
                          </Button>
                        ) : (
                          <Button
                            active
                            color="success"
                            onClick={() => this.props.activate(client.id)}
                          >
                            aktivál
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div className="discount-body-mobile">
            <ListGroup>
              {this.props.clients.map((client) => {
                const discount = displayDiscount(
                  client.isGreaterDiscountAvailable
                );
                return (
                  <ListGroupItem key={client.id}>
                    <Container>
                      <Row>
                        <Col>
                          <b>{client.client_name}</b>
                        </Col>
                      </Row>

                      <Row>
                        <Col>{discount}</Col>
                      </Row>

                      <Row>
                        <Col>
                          {discount.includes("-ig") ? (
                            <Button
                              active
                              color="danger"
                              onClick={() => this.props.deactivate(client.id)}
                            >
                              törlés
                            </Button>
                          ) : (
                            <Button
                              active
                              color="success"
                              onClick={() => this.props.activate(client.id)}
                            >
                              aktivál
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Container>
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          </div>
        </div>
      );
    }
  }
}

class DiscountsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasSearched: false,
      clients: [],
      clientName: "",
      isLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.findClientByName = this.findClientByName.bind(this);
    this.activateDiscount = this.activateDiscount.bind(this);
    this.deactivateDiscount = this.deactivateDiscount.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleChange(event) {
    console.log(this.state.clientName, event.target.value);
    this.setState({ clientName: event.target.value, hasSearched: false });
  }

  findClientByName() {
    this.setState({ isLoading: true });
    this.props.getClients(this.state.clientName).then((res) => {
      this.setState({
        clients: res.data.results,
        isLoading: false,
        hasSearched: true,
      });
    });
  }

  activateDiscount(id) {
    this.setState({ isLoading: true });
    this.props.activateDiscount(id).then((res) => {
      this.props.getClients(this.state.clientName).then((res) => {
        this.setState({
          clients: res.data.results,
          isLoading: false,
          hasSearched: true,
        });
      });
    });
  }

  deactivateDiscount(id) {
    this.setState({ isLoading: true });
    this.props.deactivateDiscount(id).then((res) => {
      this.props.getClients(this.state.clientName).then((res) => {
        this.setState({
          clients: res.data.results,
          isLoading: false,
          hasSearched: true,
        });
      });
    });
  }

  handleKeyPress(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      this.findClientByName();
    } else {
      this.handleChange(event);
    }
  }

  render() {
    return (
      <Container>
        <Row className="discount-header">
          <Col>
            <h1>Kedvezmény</h1>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form>
              <FormGroup>
                <Label for="client">Ügyfélnév</Label>
                <Input
                  type="text"
                  name="clientName"
                  id="clientName"
                  placeholder="Ügyfélnév"
                  value={this.state.clientName}
                  onChange={this.handleChange}
                  onKeyPress={this.handleKeyPress}
                />
              </FormGroup>
            </Form>
            <Button
              color="primary"
              style={{ marginTop: "10px" }}
              onClick={() => this.findClientByName()}
              disabled={this.state.clientName.length === 0}
            >
              Keresés
            </Button>
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "20px",
            textAlign: "center",
            paddingBottom: "30px",
          }}
        >
          <Col style={{ textAlign: "center" }}>
            {this.state.isLoading ? (
              <Spinner color="primary" />
            ) : this.state.hasSearched ? (
              <ClientList
                clients={this.state.clients}
                keyword={this.state.clientName}
                activate={this.activateDiscount}
                deactivate={this.deactivateDiscount}
              />
            ) : null}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => ({
  getClients: (clientName) => {
    return dispatch(
      getRequest(`${API_ROOT_PATH}/discount/clients?client_name=${clientName}`)
    );
  },
  activateDiscount: (id) => {
    return dispatch(postRequest(`${API_ROOT_PATH}/discount/${id}/activate`));
  },
  deactivateDiscount: (id) => {
    return dispatch(postRequest(`${API_ROOT_PATH}/discount/${id}/deactivate`));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DiscountsPage);
