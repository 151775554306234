import {QUERY_ADD, QUERY_INVALIDATE, QUERY_REMOVE, QUERY_SET_DATA, QUERY_VALIDATE} from '../actions/query.js';

const query = (state = {}, action) => {
  switch (action.type) {
  case QUERY_ADD: {
    const newState = {...state};
    newState[action.id] = {
      relativeURL: action.relativeURL,
      isValid: false,
      data: action.data
    };

    return newState;
  }
  case QUERY_VALIDATE: {
    const newState = {...state};
    newState[action.id].isValid = true;
    return newState;
  }
  case QUERY_INVALIDATE: {
    const newState = {...state};
    newState[action.id].isValid = false;
    return newState;
  }
  case QUERY_REMOVE: {
    const newState = {...state};
    delete newState[action.id];
    return newState;
  }
  case QUERY_SET_DATA: {
    const newState = {...state};
    newState[action.id].data = action.data;
    newState[action.id].isValid = false;
    return newState;
  }
  default:
    return state;
  }
};

export default query;
