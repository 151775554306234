import {Button, Col, Form, FormGroup, Input, Row,} from "reactstrap"
import React from "react";
import InputGroup from "reactstrap/es/InputGroup";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import InputGroupText from "reactstrap/es/InputGroupText";

class FilterFormPayment extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      client_name: '',
      contract_number: '',
      code: '',
      salesperson: '',
      payment_date__gte: '',
      payment_date__lte: '',
      identifier: '',
    };
  }

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => {
      const newState = {...prevState};
      newState[name] = value;
      return newState;
    });
  };



  render() {
    return (
      <div>
        <Form onSubmit={e => {
          e.preventDefault();
          this.props.handleSubmit(this.state);
        }}>
          <FormGroup>
            <Row style={{marginRight: 0}} className="mb-3">
              <Col md={6}>
                <InputGroup >
                  <InputGroupAddon color="primary" addonType="prepend"><InputGroupText
                    className="input-group-text float-right">Szerződés</InputGroupText></InputGroupAddon>
                  <Input type="text" name="contract_number" id="contract_number"
                         onChange={this.handleChange} value={this.state.contract_number} placeholder="Szerződésszám"/>
                </InputGroup>

                <InputGroup >
                  <InputGroupAddon color="primary" addonType="prepend"><InputGroupText
                    className="input-group-text float-right">Ügyfél</InputGroupText></InputGroupAddon>
                  <Input type="text" name="client_name" id="client_name"
                         onChange={this.handleChange} value={this.state.client_name} placeholder="Ügyfél"/>
                </InputGroup>

                <InputGroup>
                  <InputGroupAddon color="primary" addonType="prepend"><InputGroupText
                    className="input-group-text float-right">Kód</InputGroupText></InputGroupAddon>
                  <Input onChange={this.handleChange} value={this.state.code} type="text" name="code"
                         id="code" placeholder="Kód"/>
                </InputGroup>





              </Col>
              <Col md={6}>
                <InputGroup>
                  <InputGroupAddon color="primary" addonType="prepend"><InputGroupText
                      className="input-group-text float-right">Üzletkötő</InputGroupText></InputGroupAddon>
                  <Input  className="input-search" onChange={this.handleChange} value={this.state.salesperson} type="text" name="salesperson"
                          id="salesperson" placeholder="Üzletkötő"/>
                </InputGroup>
                <InputGroup>
                  <InputGroupAddon color="primary" addonType="prepend"><InputGroupText
                      className="input-group-text float-right">Befiz. dátuma</InputGroupText></InputGroupAddon>
                  <Input  onChange={this.handleChange} value={this.state.payment_date__gte}  type="date"  name="payment_date__gte"
                          id="payment_date__gte" min="2000-01-01" max="2025-12-31" placeholder="Dátum nagyobb mint"/>
                  -
                  <Input onChange={this.handleChange} value={this.state.payment_date__lte} type="date" name="payment_date__lte"
                         id="payment_date__lte" min="2000-01-01" max="2025-12-31" placeholder="Dátum ig"/>
                </InputGroup>
                <InputGroup >
                  <InputGroupAddon color="primary" addonType="prepend"><InputGroupText
                      className="input-group-text float-right">Számlaszám</InputGroupText></InputGroupAddon>
                  <Input type="text" name="identifier" id="identifier"
                         onChange={this.handleChange} value={this.state.identifier} placeholder="Számlaszám"/>
                </InputGroup>
                <InputGroup className="App-searchLine" >
                  <Col className={"p-0"}>
                    <div className="text-right">
                      <Button outline color="primary">Keresés</Button>
                    </div>
                  </Col>
                </InputGroup>

              </Col>
            </Row>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

export default FilterFormPayment