import React from 'react'

const headerProcess = () => {
    let size = 3;
    let width = 27;

    const firstRow = [
        "", "", 
        "Számlázási cím", 
        "", "", "", "", 
        "Levelezési cím", 
        "", "", "", "", "", "",
        "", "", "", "", "", "",
        "Számlázási cím", "Levelezési cím"
    ];
    const secondRow = [
        "", "Partner megnevezése:", "Irányítószám", "Település", "Utca, házszám",
        "Fizetési mód", "Kelt", "Teljesítés", "Fizetési határidő", "Név",
        "Irányítószám", "Település", "Utca, házszám", "Megjegyzés", "Szállító bankszámlaszáma",
        "Email", "Nyelv", "Logófájl neve", "Rendelésszám", "Partner adószáma",
        "Vevő főkönyvi szám", "Vevő főkönyvi azonosító", "Partner EU-s adószáma", "Folyamatos teljesítésű","Könyvelés dátuma",
        "Ország", "Ország"
    ];
    const thirdRow = [
        "", "Tétel megnevezés", "Mennyiség", "Mennyiségi egység", "Nettó egységár",
        "Áfakulcs", "Tétel nettó", "Tétel áfa", "Tétel bruttó", "Tétel megjegyzés",
        "Árbevétel főkönyvi szám", "Áfa főkönyvi szám", "Gazdasági esemény azonosító", "Áfa gazdasági esemény azonosító", "",
        "", "", "", "", "",
        "", "", "", "", "",
        "", ""
    ];
  
    let headerRow = [
        firstRow,
        secondRow,
        thirdRow
    ]

    // Headers
    let rows = [];

    for (var i = 0; i < size; i++){
      let rowID = `row${i}`
      let cell = []
      let colspanValue = 1;

      for (var idx = 0; idx < width; idx++){
        // Cellmerging to get the desired Szamlazz.hu sample view
        if(i === 0){
            if( idx === 2){
                colspanValue = 3
            }
            else if(idx === 7){
                colspanValue = 4
            }
            else{
                colspanValue = 1
            }
        }

        let cellID = `cell${i}-${idx}`
        cell.push(<td key={cellID} id={cellID} colSpan={colspanValue}>{headerRow[i][idx]}</td>)
      }
      rows.push(<tr key={i} id={rowID}>{cell}</tr>)
    }

    return rows;
}

const dataProcess = (rawDatas) => {
    // Data
    let datas = [];

    datas = rawDatas.map((rawElement, index) => {
        let cell = [];
        let clientDataRow = [];

        let cellID = `cell${index}-`;
        let rowID = `row${index}`;
        
        // Format: 
        // rownum, c.name, postcode, city, address, "átutlás",..., [15] emailAddress, [19] taxNumber
        cell.push(<td key={cellID + '0'}>{index + 1}</td>);
        cell.push(<td key={cellID + '1'}>{rawElement.client.name}</td>);
        cell.push(<td key={cellID + '2'}>{rawElement.client.postcode}</td>);
        cell.push(<td key={cellID + '3'}>{rawElement.client.city}</td>);
        cell.push(<td key={cellID + '4'}>{rawElement.client.address.toString()}</td>);
        cell.push(<td key={cellID + '5'}>átutalás</td>);
        for(let i = 6; i < 15; i++){
            cell.push(<td key={cellID + i}></td>)
        }
        cell.push(<td key={cellID + '15'}>{rawElement.client.emailAddress}</td>);
        for(let i = 16; i < 19; i++){
            cell.push(<td key={cellID + i}></td>)
        }
        cell.push(<td key={cellID + '19'}>{rawElement.client.taxNumber}</td>);
        
        clientDataRow.push(<tr key={rowID} id={rowID}>{cell}</tr>);

        // Items processing
        rawElement.items.map((element, index) => {
            let row = [];
            let cellID = `cell${index}-`;
            
            // Format: 
            // -, name, quantity, "db", netUnitPrice, afaKay, - , - , -, comment
            row.push(<td key={cellID + '0'}></td>);
            row.push(<td key={cellID + '1'}>{element.name}</td>);
            row.push(<td key={cellID + '2'}>{element.quantity}</td>);
            row.push(<td key={cellID + '3'}>db</td>);
            row.push(<td key={cellID + '4'}>{element.netUnitPrice}</td>);
            row.push(<td key={cellID + '5'}>{element.afaKey}</td>);
            for(let i = 6; i < 9; i++){
                row.push(<td key={cellID + i}></td>)
            }
            row.push(<td key={cellID + '9'}>{element.comment}</td>);

            return row;
        }).forEach((element, index) => {
            let rowID = `row${index}`;

            clientDataRow.push(<tr key={rowID} id={rowID}>{element}</tr>);  
        })

        return clientDataRow;
    });

    return datas;
}

const ExcelExportTable = (props) => {

    let rows = [];
    let headerRows = headerProcess();
    let dataRows = dataProcess(props);

    rows.push(headerRows);
    rows.push(dataRows);

    return (
        <table id="mytable">
            <tbody>
                {rows}
            </tbody>
        </table>
    );
  };

export default ExcelExportTable;
