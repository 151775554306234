export const OPEN_CLIENT = 'OPEN_CLIENT';
export const CLOSE_CLIENT = 'CLOSE_CLIENT';

export const openClient = (id) => ({
  type: OPEN_CLIENT,
  clientId: id
});

export const closeClient = () => ({
  type: CLOSE_CLIENT
});
