import React, {Component} from 'react';
import {Button, Col, Form, Input, Row} from "reactstrap";
import InputGroup from "reactstrap/es/InputGroup";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import InputGroupText from "reactstrap/es/InputGroupText";
import FormGroup from "reactstrap/es/FormGroup";

class CommissionRangeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date_gte:'',
      date_lte:'',
    };
  }


  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => {
      const newState = {...prevState};
      newState[name] = value;
      return newState;
    });
  };

  render() {

    const noDateSelected = this.state.date_gte === ''

    return (
      <div>
        <Form onSubmit={e => {
          e.preventDefault();
        }}>
          <FormGroup>
            <Row>
              <Col className="col-8 justify-content-md-start text-left ">
              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right">Dátum</InputGroupText>
                </InputGroupAddon>
                {this.props.onlyStartDate ?
                (
                  <div style = {{ width: 147 }}>
                      <Input  onChange={this.handleChange} value={this.state.date_gte}  type="date"  name="date_gte"
                        id="date_gte" min="2000-01-01" max="2025-12-31" placeholder="Dátum nagyobb mint"/>
                  </div>
                ) :
                (
                  <Input  onChange={this.handleChange} value={this.state.date_gte}  type="date"  name="date_gte"
                  id="date_gte" min="2000-01-01" max="2025-12-31" placeholder="Dátum nagyobb mint"/>
                )
                }
                {/* The onlyStartDate prop controls the number of date inputs in this shared component. */}
                {this.props.onlyStartDate === undefined && (
                  <Input style = {{ marginLeft: 5 }} onChange={this.handleChange} value={this.state.date_lte} type="date" name="date_lte"
                        id="date_lte" min="2000-01-01" max="2025-12-31" placeholder="Dátum ig"/>
                )}
              </InputGroup>
              </Col>
              <Button color="danger" disabled = {noDateSelected} onClick={() => {
                if(this.props.onlyStartDate) {
                  this.props.handleSubmit(this.state.date_gte)
                }
                else {
                  this.props.handleSubmit(this.state.date_gte, this.state.date_lte)
                }
              }}>Export</Button>
            </Row>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

export default CommissionRangeForm