import { Table } from "reactstrap";
import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { connect } from "react-redux";
import { getRequest } from "../actions/api";
import FilterFormSearch from "../FilterForm/FilterFormSearch";


class SearchTaxNumberPage extends Component {

  // Component-specific states
  state = {
    entry: [],
    status: '',
    regex: '',
    getResult: ''
  };

  // String generating 
  getTaxNumber = (object) => {
    const regex = /^([0-9]{11}|[0-9]{8}-[0-9]{1}-[0-9]{2})/gi;
    const result = regex.test(object.tax_number);
    
    // We have tax_number
    if (object.tax_number !== "" && result === true){
        this.props.checkTaxNumberDetails(object.tax_number).then(response => {
            response.status !== 200 ? (
               // Have to set regex to false, because of the logic, tho it matches the format criteria  
              this.setState({ status: response.status,
                              getResult: false,
                              regex: result })
            ) : (
              this.setState({ entry: response.data.entry, 
                              status: response.status,
                              getResult: true,
                              regex: result })
            )
        })      
    } else {
      // regex === false
      this.setState({ regex: result })
    }
  }

  render() {
    const entry = this.state.entry
    let entryName

    if(entry.entry_type === "self_employed" ){
        const lowerCase =  entry.name.toLowerCase();
        const firstnameOK = lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
        const secondnameOK = firstnameOK.split(' ')[0] + ' ' + firstnameOK.split(' ')[1].charAt(0).toUpperCase() + firstnameOK.split(' ')[1].slice(1); 

        
        entryName = secondnameOK;
    }

    return (
      <div style={{ margin: "0 auto", display: "flex", alignItems: "center",   flexDirection: "column" }}>
        <h5 style={{ textAlign: "left" }}>Adószám ellenőrzés</h5>
        <FilterFormSearch handleSubmit={this.getTaxNumber}/>
        { this.state.regex === false ? 
          (
            <div>
              <h2 style={{ color: "red" }}>Nem megfelelő az adószám formátuma!</h2>
            </div>
          ) : (
            this.state.status === 200 ? (
              <div>
                <div>
                  <h5>Találat</h5>
                </div>
                <Table striped bordered hover responsive style={{ marginLeft: "auto", marginRight: "auto" }} >
                 <tbody>
                  {
                    entry.entry_type === "companies" ? (
                        <tr key='1'>
                          <td>Cégnév</td>
                          <td>{entry.short_name}</td>
                        </tr>
                    ) : ( 
                        <tr key='1'>
                          <td>Név</td>
                          <td>{entryName}</td>
                        </tr>
                    )
                  }
                  {
                    entry.entry_type === "companies" ? (
                        <tr key='2'>
                          <td>Székhely</td>
                          <td>{entry.address}</td>
                        </tr>
                    ) : ( 
                      <tr key='2'>
                        <td>Székhely</td>
                        <td>{entry.address}</td>
                      </tr>
                    )
                  } 
                  {
                    entry.entry_type === "companies" ? (
                        <tr key='3'>
                          <td>Elérhetőség</td>
                          <td>{entry.email}</td>
                        </tr>
                    ) : null
                  }
                  {
                    entry.entry_type === "companies" ? (
                        <tr key='4'>
                          <td>Adószám</td>
                        { 
                          entry.tax_number_status !== "érvényes adószám" ? 
                          (
                            <td>{entry.tax_number_status} <FontAwesomeIcon icon={faTimesCircle} color="red"/></td>
                          ) : (
                            <td>{entry.tax_number_status} <FontAwesomeIcon icon={faCheckCircle } color="green"/></td>
                          )
                        }
                        </tr>
                    ) : null
                  }     
                 </tbody>
               </Table>
              </div>
            ) : (
              this.state.getResult === false ? (
                <h2>Nincs találat!</h2>
              ) : null
            )
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const editClientId = state.client.clientId;
  return {
    editClientId
  }
};

const mapDispatchToProps = dispatch => ({
 checkTaxNumberDetails: tax_number => {
     return dispatch(getRequest(`/kisokostest/tax-number/${tax_number}/details`)) 
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchTaxNumberPage);

