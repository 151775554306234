import React, { Component } from "react";
import { Container, Row, Col, Spinner, Table, TabContent, TabPane, Nav, NavItem, NavLink, Button } from "reactstrap"
import Select from "react-select";
import { connect } from "react-redux";
import { getRequest } from "../actions/api";
import moment from 'moment';
import {Alert} from 'reactstrap';
import 'moment/locale/hu';
import ReportsTable from '../components/ReportsTable'
import { formatNumberToGroupOfThousands } from '../components/ReportsTable'
import {saveFile} from "../Util";

const salespersonNames = [
  'Mindenki',
  'Fejes Zoltán',
  'Pálfi Veronika',
  'Sötét József',
  'Pataki László',
  'Bulyáki Ildikó',
  'Siposné Margó'
]

const salespersonNameOptions = salespersonNames.map((label, value) => { return { value: value, label: label }})

// Enum for reports types
const ReportsType = {
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3,
  CASH: 4,
  SALESBYPRODUCTS: 5,
  SALESBYPRODUCTSNEW: 6
};

// Enum for payment types
const PaymentMethodType = {
  TRANSFER: 1,
  CASH: 2
};

var monthNumbers = [];
for (var i = 0; i < 12; i++) {
  const monthLabel = moment().month(i).locale('hu').format("MMMM")
  monthNumbers.push({ value: i+1, label: monthLabel.charAt(0).toUpperCase() + monthLabel.slice(1) });
}

const infoVariant = {
  color: '#0c5460',
  backgroundColor: '#d1ecf1',
  position: 'relative',
  padding: '.75rem 1.25rem',
  marginBottom: '1rem',
  border: '1px solid #bee5eb',
  borderRadius: '.25rem',
}

const warningVariant = {
  color: '#856404',
  backgroundColor: '#fff3cd',
  padding: ' .75rem 1.25rem',
  marginBottom: '1rem',
  border: '1px solid #ffeeba',
  borderRadius: '.25rem'
}

const marginTop10 = {
  marginTop: 10
}

const marginTop20 = {
  marginTop: 20
}

// Minor Alignmenting value
const buttonMarginTop = {
  marginTop: 28
}

const valueMarginTop = {
  marginTop: 29
}

const CashPaymentsTableHeader = ["Üzletkötő", "Számlaszám", "Hiv.számlaszám", "Vevő", "Vevő adószám", "Irsz.", "Település", "Cím", "Email", "Kelt", "Nettó", "Áfa", "Bruttó", "Megjegyzés", "Vevő azonosító"]


const download = (filename, text) => {
  var element = document.createElement('a');
  var universalBOM = "\uFEFF";
  element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(universalBOM + text));
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}


const exportData = (data) => {
  console.log('exportData');
  console.log(data);
  const headerNotCash = ['üzletkötő',	'szerződés', 'kiadványok', 'összeg (nettó, huf)', 'összeg (bruttó, huf']
  let csvArray = [headerNotCash.join(';')];
  let totalSumForSalespersons = 0

  data.forEach(salesperson => {
    console.log('object.details', salesperson.details);
    // Name of salesperson
    csvArray.push(
      salesperson.salesperson.name +
      ';' +
      salesperson.summary.numberOfContracts +
      ';' +
      salesperson.summary.totalSumForAllContracts
    )
    // Add totalSumForAllContracts to totalSumForSalespersons
    totalSumForSalespersons += salesperson.summary.totalSumForAllContracts

    salesperson.details.forEach(detail => {
      let products = detail.products.map(product => { return product.product }).join()
      csvArray.push(
        detail.contract +
        ';' +
        products +
        ';' +
        detail.summary.numberOfProducts +
        ';' +
        detail.summary.totalSumOfProducts
      )
    })
  });

  // Print global sums to CSV
  const formattedNet = totalSumForSalespersons
  const formattedTax = Math.round(totalSumForSalespersons * 0.27)
  const formattedGro = Math.round(totalSumForSalespersons * 1.27)
  csvArray.push(`Minden üzletkötőre;Összes nettó: ${formattedNet};Összes ÁFA: ${formattedTax};Összes bruttó: ${formattedGro}`)

  console.log(csvArray);
  download('export.csv', csvArray.join('\n'));
};

const exportDataCashPayments = (data) => {
  console.log('exportDataCashPayments');
  console.log(data);
  const headerNotCash = ['Üzletkötő','Számlaszám','Hiv.számlaszám','Vevő','Vevő adószám','Irsz.','Település','Cím','Email','Kelt','Nettó','Áfa','Bruttó','Megjegyzés','Vevő azonosító']
  let csvArray = [headerNotCash.join(';')];
  let totalSumForSalespersons = 0

  data.results.forEach(salesperson => {
    salesperson.payments.forEach(payment => {
      console.log('payment', payment);
      totalSumForSalespersons += parseInt(payment[9])
      let formattedPayment = [...payment]
      formattedPayment[9] = parseInt(formattedPayment[9])
      formattedPayment[10] = parseInt(formattedPayment[10])
      formattedPayment[11] = parseInt(formattedPayment[11])
      csvArray.push(salesperson.salesperson.name + ';' + formattedPayment.join(';'))
    })
  });

  // Print global sums to CSV
  const formattedNet = totalSumForSalespersons
  const formattedTax = Math.round(totalSumForSalespersons * 0.27)
  const formattedGro = Math.round(totalSumForSalespersons * 1.27)
  csvArray.push(`Minden üzletkötőre;Összes nettó: ${formattedNet};Összes ÁFA: ${formattedTax};Összes bruttó: ${formattedGro}`)

  console.log(csvArray);
  download('export.csv', csvArray.join('\n'));
};


class CashPaymentsTable extends Component {
  render() {
    const salespersons = this.props.data.results
    let totalSumNet = 0
    let totalSumTax = 0
    let totalSumGross = 0
    return (
      <Table bordered hover style={{ marginTop: '0px' }} size = "sm">
        <thead>
          <tr>
            {CashPaymentsTableHeader.map((headerColumn, index) => {
              return <td key = {`header-${index}`}>{headerColumn}</td>
            })}
          </tr>
        </thead>
        <tbody>
          {salespersons.map((salesperson) => {
            if(salesperson.payments.length === 0) {
              return (
                <tr key = {`no-payments-${salesperson.salesperson.email}`}>
                  <td>{salesperson.salesperson.name}</td><td colSpan = {CashPaymentsTableHeader.length - 1}>Nincs készpénzes számla.</td>
                </tr>
              )
            }
            return salesperson.payments.map((payment, index) => {
              return (
                <tr key = {`${salesperson.salesperson.email}-data-row-${index}`}>
                  <td>{salesperson.salesperson.name}</td>
                  {payment.map((column, i) => {
                    // eslint-disable-next-line default-case
                    switch(i) {
                      case 9: totalSumNet += parseInt(column); break;
                      case 10: totalSumTax += parseInt(column); break;
                      case 11: totalSumGross += parseInt(column); break;
                    }
                    if(i >= 9 && i <= 11) {
                      return <td style={{ whiteSpace: 'nowrap' }}>{formatNumberToGroupOfThousands(column)}</td>
                    }
                    else {
                      return <td>{column}</td>
                    }
                  })}
                </tr>
              )
            })
          })}
          <tr>
            <td colSpan = {10}></td>
            <td>Összes <b>nettó</b>: {formatNumberToGroupOfThousands(totalSumNet)} Ft</td>
            <td>Összes <b>ÁFA</b>: {formatNumberToGroupOfThousands(totalSumTax)} Ft</td>
            <td>Összes <b>bruttó</b>: {formatNumberToGroupOfThousands(totalSumGross)} Ft</td>
            <td colSpan = {2}></td>
          </tr>
        </tbody>
      </Table>
    )
  }
}

class ReportsPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      resultsFromAPI: null,
      selectedTab: "4",
      selectedDay: moment().format('YYYY-MM-DD'),
      selectedWeek: 48,
      selectedMonth: { value: 11, label: 'November' },
      reportsType: ReportsType.CASH,
      isFetching: false,
      isFetched: false,
      userEmailAddress: '',
      userFullName: '',
      isReportsAvailable: false,
      selectedSalesperson: salespersonNameOptions[0],
      semester: '222'
    };

    this.changeSelectedWeek = this.changeSelectedWeek.bind(this);
    this.changeSelectedMonth = this.changeSelectedMonth.bind(this);
    this.getReportsByType = this.getReportsByType.bind(this);
    this.getCashPaymentReports = this.getCashPaymentReports.bind(this);
    this.exportSalesByProducts = this.exportSalesByProducts.bind(this);
    this.exportSalesByProductsNew = this.exportSalesByProductsNew.bind(this);
  }

  componentDidMount() {
    this.getUserProfile()
  }
  
  getUserProfile = () => {    
    this.props.getUserProfile().then(response => {
      this.setState({
        isReportsAvailable: response.data.hasAccessToReports,
        userEmailAddress: response.data.emailAddress,
        userFullName: response.data.fullName
      })
    })
  }

  getReportsEndpoint = (parameterizedUrlEndig) => {
    this.props.getReportsEndpoint(parameterizedUrlEndig).then(response => {
      console.log('getReportsEndpoint', response.data);
      this.setState({ resultsFromAPI: response.data.data, isFetched: true, isFetching: false })
    })
  }

  getCashPaymentReportsEndpoint = (parameterizedUrlEndig) => {
    this.props.getCashPaymentReportsEndpoint(parameterizedUrlEndig).then(response => {
      console.log('getCashPaymentReportsEndpoint', response.data);
      this.setState({ resultsFromAPI: response.data, isFetched: true, isFetching: false })
    })
  }

  changeSelectedDay = event => {
    this.setState({
      selectedDay: event.target.value,
      resultsFromAPI: null,
      isFetching: false,
      isFetched: false
    });
  }

  changeSelectedWeek(event) {
    this.setState({
      selectedWeek: event.target.value,
      resultsFromAPI: null,
      isFetching: false,
      isFetched: false
    });
  }

  changeSelectedMonth(event) {
    this.setState({
      selectedMonth: { 
        value: event.value, 
        label: event.label
      },
      resultsFromAPI: null,
      isFetching: false,
      isFetched: false
    });
  }

  getCashPaymentReports = (sendEmail) => {
    let urlEnding = `type=${PaymentMethodType.CASH}&date=${this.state.selectedDay}&userEmailAddress=${this.state.userEmailAddress}&userFullName=${this.state.userFullName}`
    if(sendEmail) {
      urlEnding += '&sendEmail=true'
    }
    this.getCashPaymentReportsEndpoint(urlEnding)
  }

  getReportsByType = (sendEmail) => {
    this.setState({ isFetching: true })
    const type = this.state.reportsType
    let urlEnding
    switch(type) {
      case ReportsType.DAILY: {
        // TODO: parsing day from JavaScript Date Object
        urlEnding = `type=daily-salespersons&day=${this.state.selectedDay}`
        break;
      }
      case ReportsType.WEEKLY: {
        // this.state.selectedWeek is Number
        urlEnding = `type=weekly&day=${this.state.selectedDay}`
        break;
      }
      case ReportsType.MONTHLY: {
        urlEnding = `type=monthly&day=${this.state.selectedDay}`
        break;
      }
      default: {
        // Default is monthly report
        urlEnding = `type=monthly&day=${this.state.selectedDay}`
        break;
      }
    }
    if(sendEmail){
      urlEnding += `&sendEmail=true&userEmailAddress=${this.state.userEmailAddress}&userFullName=${this.state.userFullName}`
    }
    this.getReportsEndpoint(urlEnding)
  }

  exportSalesByProducts = (products) => {
    this.props.exportSalesByProducts(products).then(response => {
      const csvArray = [];
      const rawArray = response.data;

      csvArray.push('Szerződésszám;Szerződés kelte;Rögzítés dátuma;Ügyfél;Irányítószám;Város;Cím;Kapcsolattartó;Telefonszám;E-mail cím;Levelezési név;Levelezési cím;;;Adószám;Kiadvány;Méret;Címszó;Nettó ár;Listaár;Kedvezmény;Üzletkötő;Megjegyzés');

      rawArray.forEach((row, idx) => {
        let formattedRow = [...row]
        // ap.net_price, ap.list_price, ap.discount,
        const size = row[16]
        // Handle "Törölve" items
        let pairs = 0
        let pairIndex = -1
        if(size === 'Törölve') {
          var { 0: contract, 3: clientName, 15: product, 17: keyword, 18: net, 21: salesperson } = row;
          rawArray.forEach((other, index) => {
            if(other[0] === contract &&
              other[3] === clientName &&
              other[15] === product &&
              other[17] === keyword &&
              other[16] !== "Törölve" &&
              Math.abs(other[18]) === Math.abs(net) &&
              other[21] === salesperson
              ) {
                pairs++
                pairIndex = index
              }
          })
          if(pairs === 1) {
            if (pairIndex > -1) {
              const copiedPair = [...rawArray[pairIndex]]
              copiedPair[19] = row[18] // -1*?
              let disC = parseInt(copiedPair[20]) + '%'
              const netP = copiedPair[18]
              const lisT = copiedPair[19]
              if(netP !== 0 && lisT !== 0) {
                disC = parseInt(((Math.abs(lisT) - Math.abs(netP)) / Math.abs(lisT)) * 100) + '%'
              }
              copiedPair[20] = disC
              copiedPair.push(`Törölve tétel párja: ${contract} ${clientName} ${product} ${keyword}`)
              rawArray.splice(pairIndex, 0, copiedPair);
              rawArray.splice(pairIndex + 1, 1)
            }
          }
          else {
            if(pairs === 0) {
              rawArray.forEach(other => {
                if(other[0] === contract &&
                  other[3] === clientName &&
                  other[15] === product &&
                  other[17] === keyword &&
                  other[16] !== "Törölve" &&
                  other[21] === salesperson
                  ) {
                    // TODO: ?
                  }
              })
            }
          }
        }

        const netPrice = parseInt(row[18])
        // Update list price based on Törölve size
        let listPrice = size === 'Törölve' ? (-1 * netPrice) : parseInt(row[19])
        const discount = parseInt(row[20])
        if((discount < 0 || discount > 100) && size === 'Törölve') {
          listPrice = -1 * netPrice
        }

        let realDiscount = (discount < 0 || discount > 100) ? '0%' : parseInt(row[20]) + '%'
        if(netPrice !== 0 && listPrice !== 0) {
          realDiscount = parseInt(((Math.abs(listPrice) - Math.abs(netPrice)) / Math.abs(listPrice)) * 100) + '%'
          //console.log('Calc of discount:', realDiscount, parseInt(((Math.abs(listPrice) - Math.abs(netPrice)) / Math.abs(listPrice)) * 100) + '%');
        }
        if(listPrice === 0 && size === "Törölve") {
          realDiscount = parseInt(((Math.abs(listPrice) - Math.abs(netPrice)) / Math.abs(listPrice)) * 100) + '%'
          //console.log('Törölve discount', realDiscount, parseInt(((Math.abs(listPrice) - Math.abs(netPrice)) / Math.abs(listPrice)) * 100) + '%');
        }
        if(netPrice === 0 && listPrice === 0) {
          realDiscount = '0%'
        }
        // Törölve: net < 0, list = 0, dis > 0
        // Sima: net > 0, list = 0, dis < 0

        formattedRow[18] = netPrice
        formattedRow[19] = listPrice
        formattedRow[20] = realDiscount

        // Handle unique cases
        // Gift items
        if(netPrice === 0 && (size === 'K/16' || size === 'K/24')) {
          //console.log('Gift!', listPrice, '--->', netPrice);
          //console.log('Gift:', formattedRow, '\n\n');
          formattedRow.push(`AJÁNDÉK ${size}, listaár nullára állítva.`)
        }
        else if(netPrice === 0 && listPrice === 0) {
          //console.log('Full zeros!', formattedRow);
          formattedRow.push("HIBA! Csupa nulla.")
        }
        else if(listPrice === 0 && size !== "Törölve") {
          if (pairIndex > -1) {
            formattedRow.push("HIBA! Listaár nulla.")
          }
        }

        rawArray.splice(idx, 0, formattedRow);
        rawArray.splice(idx + 1, 1)
      });

      rawArray.forEach(row => {
        csvArray.push(row.join(';'));
      })

      saveFile(`Feleves-forgalom-${products}.csv`, csvArray.join('\n'));
    }).catch(function (error) {

    });
  };

  exportSalesByProductsNew = (semeter) => {
    this.props.exportSalesByProductsNew(semeter).then(response => {
      saveFile(`Feleves-forgalom-${semeter}.csv`, response.data);
    }).catch(function (error) {

    });
  };

  render() {
    let resultComponent = []
    const results = this.state.resultsFromAPI && this.state.resultsFromAPI
    const response = this.state.resultsFromAPI && (this.state.resultsFromAPI.emailResponse || [])
    // Render the proper component under the table
    if(this.state.isFetching) {
      // During fetching, the component should be a Spinner
      resultComponent = <Spinner animation="border" variant="primary" />
    }
    else {
      // After fetching
      if(this.state.isFetched) {
        if(this.state.resultsFromAPI.length === 0) {
          // Zero results ---> Inform the user
          resultComponent = <div style = {warningVariant} >Nincs találat a megadott időintervallumra.</div>
        }
        else {
          if(this.state.resultsFromAPI.hasOwnProperty('cashPayments')) {
            if(this.state.selectedSalesperson.label === 'Mindenki') {
              resultComponent = <CashPaymentsTable data = {results} />
            }
            else {
              const filteredResults = {
                'cashPayments': true,
                'results': results.results.filter(result => { return result.salesperson.name === this.state.selectedSalesperson.label })
              }
              resultComponent = <CashPaymentsTable data = {filteredResults} />
            }
          }
          else {
            // There are results ---> Render the results
            resultComponent = <ReportsTable data = {results} emailResponse = {response}/>
          }
        }
      }
      else {
        // No data fetching yet
        resultComponent = <div style = {infoVariant} >Nem történt még adatlekérés. Válassz a fenti lehetőségek közül!</div>
      }
    }

    let sendEmail = false

    const authorizedContent = (
      <>
      <Row>
      <Col>
        <Nav tabs>
          <NavItem active = {this.state.selectedTab === '1'}>
            <NavLink
              onClick={() => { this.setState({ 
                selectedTab: '1', 
                reportsType: ReportsType.DAILY, 
                resultsFromAPI: [], 
                isFetched: false,
                isFetching: false,
                isThereTableAlready: false
              }); }} 
            >
              Napi szerződéses riport
            </NavLink>
          </NavItem>

          <NavItem active = {this.state.selectedTab === '2'}>
            <NavLink
              onClick={() => { this.setState({ 
                selectedTab: '2', 
                reportsType: ReportsType.WEEKLY, 
                resultsFromAPI: [], 
                isFetched: false, 
                isFetching: false,
                isThereTableAlready: false
              }); }}
            >
              Heti szerződéses riport
            </NavLink>
          </NavItem>

          <NavItem active = {this.state.selectedTab === '3'}>
            <NavLink
              onClick={() => {this.setState({
                selectedTab: '3',
                reportsType:
                ReportsType.MONTHLY,
                resultsFromAPI: [],
                isFetched: false,
                isFetching: false,
                isThereTableAlready: false
              }); }} 
            >
              Havi szerződéses riport
            </NavLink>
          </NavItem>

          <NavItem active = {this.state.selectedTab === '4'}>
            <NavLink
              onClick={() => { this.setState({ 
                selectedTab: '4', 
                reportsType: ReportsType.CASH, 
                resultsFromAPI: [], 
                isFetched: false, 
                isFetching: false,
                isThereTableAlready: false
              }); }}
            >
              Heti készpénzes számlák 
            </NavLink>
          </NavItem>

          <NavItem active = {this.state.selectedTab === '5'}>
            <NavLink
              onClick={() => { this.setState({ 
                selectedTab: '5', 
                reportsType: ReportsType.SALESBYPRODUCTS, 
                resultsFromAPI: [], 
                isFetched: false, 
                isFetching: false,
                isThereTableAlready: false
              }); }}
            >
              Féléves törzslista
            </NavLink>
          </NavItem>

          <NavItem active = {this.state.selectedTab === '6'}>
            <NavLink
              onClick={() => { this.setState({ 
                selectedTab: '6', 
                reportsType: ReportsType.SALESBYPRODUCTSNEW, 
                resultsFromAPI: [], 
                isFetched: false, 
                isFetching: false,
                isThereTableAlready: false
              }); }}
            >
              Féléves forgalom
            </NavLink>
          </NavItem>
        </Nav>
        </Col>
    </Row>

    <Row>
      <Col>
        <TabContent activeTab={this.state.selectedTab}>
          <TabPane tabId="1">
            <Row style = {marginTop20}>
              <Col>Válassz napot!</Col>
            </Row>
            <Row style = {valueMarginTop}>
              <Col>
                <input
                  type="date" 
                  value = {this.state.selectedDay} 
                  style= {{ width: "10em", paddingLeft: "5px" }}
                  onChange = {this.changeSelectedDay} 
                  id = "report_date" 
                  name = "report_date" 
                  min="2000-01-01" 
                  max="2025-12-31"
                />
              </Col>
            </Row>
            <Row style = {buttonMarginTop}>
              <Col>
              <Button outline color="primary" onClick = {() => this.getReportsByType(sendEmail)}>Lekérdezés</Button>
              </Col>
            </Row>
            <Row style = {marginTop10}>
              <Col>
              <span>Excel exportálás csak a <b>Lekérdezés</b> gomb használata <b>után</b> lehetséges.</span>
              </Col>
            </Row>
            <Row style = {marginTop10}>
              <Col>
              <Button outline color="primary" onClick = {() => exportData(this.state.resultsFromAPI)} disabled = {this.state.resultsFromAPI === null || this.state.resultsFromAPI.length === 0}>Excel export</Button>
              </Col>
            </Row>
            <Row style = {marginTop10}>
              <Col>
              <span>
                A bejelentkezett felhasználónak küldjük ki <b>tesztelés</b> céljából a riportok emailt: 
                {', '}
                {this.state.userFullName && <b>{this.state.userFullName}</b>}
                {', '}
                {this.state.userEmailAddress && <b>{this.state.userEmailAddress}</b>}
              </span>
              </Col>
            </Row>
            <Row style = {marginTop10}>
              <Col>
              <Button outline color="secondary" onClick = {() => this.getReportsByType(sendEmail = true)}>Email küldése</Button>
              </Col>
            </Row>
            <Row style = {marginTop20}>
              <Col>
              {resultComponent}
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="2">
            <Row style = {marginTop20}>
              <Col>Válassz egy napot a kívánt hétről!</Col>
            </Row>
            <Row style = {valueMarginTop}>
              <Col>
               <input
                  type="date" 
                  value = {this.state.selectedDay} 
                  style= {{ width: "10em", paddingLeft: "5px" }}
                  onChange = {this.changeSelectedDay} 
                  id = "report_date" 
                  name = "report_date" 
                  min="2000-01-01" 
                  max="2025-12-31"
                />
              </Col>
            </Row>
            <Row style = {buttonMarginTop}>
              <Col>
              <Button outline color="primary" onClick = {() => this.getReportsByType(sendEmail)}>Lekérdezés</Button>
              </Col>
            </Row>
            <Row style = {marginTop10}>
              <Col>
              <span>Excel exportálás csak a <b>Lekérdezés</b> gomb használata <b>után</b> lehetséges.</span>
              </Col>
            </Row>
            <Row style = {marginTop10}>
              <Col>
              <Button outline color="primary" onClick = {() => exportData(this.state.resultsFromAPI)} disabled = {this.state.resultsFromAPI === null || this.state.resultsFromAPI.length === 0}>Excel export</Button>
              </Col>
            </Row>
            <Row style = {marginTop10}>
              <Col>
              <span>
                A bejelentkezett felhasználónak küldjük ki <b>tesztelés</b> céljából a riportok emailt: 
                {', '}
                {this.state.userFullName && <b>{this.state.userFullName}</b>}
                {', '}
                {this.state.userEmailAddress && <b>{this.state.userEmailAddress}</b>}
              </span>
              </Col>
            </Row>
            <Row style = {marginTop10}>
              <Col>
              <Button outline color="secondary" onClick = {() => this.getReportsByType(sendEmail = true)}>Email küldése</Button>
              </Col>
            </Row>
            <Row style = {marginTop20}>
              <Col>
              {resultComponent}
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="3">
          <Row style = {marginTop20}>
              <Col>Válassz egy napot a kívánt hónapból!</Col>
            </Row>
            <Row style = {valueMarginTop}>
              <Col>
               <input
                  type="date" 
                  value = {this.state.selectedDay} 
                  style= {{ width: "10em", paddingLeft: "5px" }}
                  onChange = {this.changeSelectedDay} 
                  id = "report_date" 
                  name = "report_date" 
                  min="2000-01-01" 
                  max="2025-12-31"
                />
              </Col>
            </Row>
            <Row style = {buttonMarginTop}>
              <Col>
              <Button outline color="primary" onClick = {() => this.getReportsByType(sendEmail)}>Lekérdezés</Button>
              </Col>
            </Row>
            <Row style = {marginTop10}>
              <Col>
              <span>Excel exportálás csak a <b>Lekérdezés</b> gomb használata <b>után</b> lehetséges.</span>
              </Col>
            </Row>
            <Row style = {marginTop10}>
              <Col>
              <Button outline color="primary" onClick = {() => exportData(this.state.resultsFromAPI)} disabled = {this.state.resultsFromAPI === null || this.state.resultsFromAPI.length === 0}>Excel export</Button>
              </Col>
            </Row>
            <Row style = {marginTop10}>
              <Col>
              <span>
                A bejelentkezett felhasználónak küldjük ki <b>tesztelés</b> céljából a riportok emailt: 
                {', '}
                {this.state.userFullName && <b>{this.state.userFullName}</b>}
                {', '}
                {this.state.userEmailAddress && <b>{this.state.userEmailAddress}</b>}
              </span>
              </Col>
            </Row>
            <Row style = {marginTop10}>
              <Col>
              <Button outline color="secondary" onClick = {() => this.getReportsByType(sendEmail = true)}>Email küldése</Button>
              </Col>
            </Row>
            <Row style = {marginTop20}>
              <Col>
              {resultComponent}
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="4">
            <Row style = {marginTop20}>
              <Col>Válassz egy napot a kívánt hétről!</Col>
            </Row>
            <Row style = {valueMarginTop}>
              <Col>
                <input
                    type="date" 
                    value = {this.state.selectedDay} 
                    style= {{ width: "10em", paddingLeft: "5px" }}
                    onChange = {this.changeSelectedDay} 
                    id = "report_date" 
                    name = "report_date" 
                    min="2000-01-01" 
                    max="2025-12-31"
                  />
              </Col>
            </Row>
            <Row style = {buttonMarginTop}>
              <Col>
              <Button outline color="primary" onClick = {() => this.getCashPaymentReports(false)}>Lekérdezés</Button>
              </Col>
            </Row>
            <Row style = {marginTop10}>
              <Col>
              <span>Excel exportálás csak a <b>Lekérdezés</b> gomb használata <b>után</b> lehetséges.</span>
              </Col>
            </Row>
            <Row style = {marginTop10}>
              <Col>
              <Button
                outline
                color="primary"
                onClick = {() =>  {
                  console.log('this.state.resultsFromAPI', this.state.resultsFromAPI);
                  let dataToExport
                  if(this.state.selectedSalesperson.label === 'Mindenki') {
                    dataToExport = this.state.resultsFromAPI
                  }
                  else {
                    dataToExport = {
                      results: this.state.resultsFromAPI.results.filter(result => {
                        return result.salesperson.name === this.state.selectedSalesperson.label
                      })
                    }
                  }
                  exportDataCashPayments(dataToExport)
                }}
                disabled = {this.state.resultsFromAPI === null || this.state.resultsFromAPI.length === 0}>
                  Excel export
                </Button>
              </Col>
            </Row>
            <Row style = {marginTop10}>
              <Col style = {{ display: 'flex', justifyContent: 'center', textAlign: 'center', margin: '0 auto', marginTop: 20 }} >
              <Select
                className = "App-select-reports"
                options={salespersonNameOptions}
                defaultValue={salespersonNameOptions[0]}
                onChange={(event) => {
                  this.setState({
                    selectedSalesperson: { 
                      value: event.value, 
                      label: event.label
                    }
                })}}
              />
              </Col>
            </Row>
            <Row style = {marginTop20}>
              <Col>
              {resultComponent}
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="5">
            <Row style = {marginTop20}>
              <Col>Legutóbbi félév: 2020/2</Col>
            </Row>
            <Row style = {marginTop20}>
              <Col>
                <input type = "text" value = {this.state.semester} onChange = {(event) => this.setState({ semester: event.target.value })} />
              </Col>
            </Row>
            <Row style = {buttonMarginTop}>
              <Col>
              <Button outline color="primary" onClick = {() => this.exportSalesByProducts(this.state.semester)}>Törzslista letöltése</Button>
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="6">
            <Row style = {marginTop20}>
              <Col>Legutóbbi félév: 222</Col>
            </Row>
            <Row style = {marginTop20}>
              <Col>
                <input type = "text" value = {this.state.semester} onChange = {(event) => this.setState({ semester: event.target.value })} />
              </Col>
            </Row>
            <Row style = {buttonMarginTop}>
              <Col>
              <Button outline color="primary" onClick = {() => this.exportSalesByProductsNew(this.state.semester)}>Törzslista letöltése</Button>
              </Col>
            </Row>
          </TabPane>

        </TabContent>
      </Col>
    </Row>
    </>
    )

    const unauthorizedContent = (
      <Row>
        <Col>
        <Alert className="reportspage" color="info">
          Ehhez az oldalhoz nincs megfelelő jogosultságod.
        </Alert>
        </Col>
      </Row>
    )

    return (
      <div>
        <Container>
          <Row>
            <Col>
              <h1>Riportok</h1>
            </Col>
          </Row>
          { this.state.isReportsAvailable ? authorizedContent : unauthorizedContent }
        </Container>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getUserProfile: () => {
    return dispatch(getRequest('/kisokostest/user/profile/'));
  },
  getReportsEndpoint: (parameterizedUrlEndig) => {
    return dispatch(getRequest(`/kisokostest/reports?${parameterizedUrlEndig}`));
  },
  getCashPaymentReportsEndpoint: (parameterizedUrlEndig) => {
    return dispatch(getRequest(`/kisokostest/reports/payment?${parameterizedUrlEndig}`));
  },
  exportSalesByProducts: (id) =>{
    return dispatch(getRequest('/kisokostest/exportSalesByProductLatestHalfYear?products=' + id));
  },
  exportSalesByProductsNew: (id) =>{
    return dispatch(getRequest('/kisokostest/export-sales-group-by-product-code?productCode=' + id));
  },
});
export default connect(null,mapDispatchToProps)(ReportsPage)
