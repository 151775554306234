

export const vatkey = 1.27;

const getProperty = (object, property) => property.split('.').reduce((prevObject, index) => prevObject && prevObject.hasOwnProperty(index) ? prevObject[index] : undefined, object);

export const getPropertyOrEmptyString = (object, property) => {
  const value = getProperty(object, property);

  return value ? value : '';
};

export const getPropertyOrUndefined = (object, property) => {
  return getProperty(object, property);
};

export const getPropertyOrZero = (object, property) => {
  const value = getProperty(object, property);

  return value ? value : 0;
};

export const saveFile = (filename, text) => {
  var element = document.createElement('a');
  var universalBOM = "\uFEFF";
  element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(universalBOM + text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};
