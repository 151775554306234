export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const LOGOUT = 'LOGOUT';

export const successAuth = (name, token) => ({
  type: AUTH_SUCCESS,
  name: name,
  token: token
});

export const logout = () => ({
  type: LOGOUT
});
