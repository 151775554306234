import Modal from "../components/Modal";
import React, {Component} from "react";
import {connect} from "react-redux";
import QueryPage from "../components/QueryPage";
import {Button, Col, Label} from "reactstrap";
import {getRequest, putRequest} from "../actions/api";
import {addQuery, invalidateQuery} from "../actions/query";
import {addModal, toggleModal} from "../actions/modal";
import {saveFile} from "../Util";
import FilterFormColleague from "../FilterForm/FilterFormColleague";
import {closeColleague, openColleague} from "../actions/colleague";
import CommissionRangeForm from "../DetailedForm/CommissionRangeForm";
import ColleagueForm from "../DetailedForm/ColleagueForm";
import edit from "../icons/edit.png";
import ReactTooltip from "react-tooltip";
import exporticon from "../icons/export.png";

class ColleaguePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editModalOpen: false,
      editId: undefined,
      comissionModalOpen: false
    };

    this.queryPage = React.createRef();
  }

  toggleEditModal = (editObject = undefined) => {
    if(editObject!== undefined){
      this.props.openColleague(editObject.id)
    }
    else{
      this.props.openColleague()
    }
    this.props.toggleModal('create-edit-colleague');
  };

  toggleCommissionModal = () => {
    this.props.toggleModal('commission-export');
  };

  updateActive= (editObject = undefined) =>{
    if(editObject !== undefined){
      editObject.active = !editObject.active;
      this.props.updateColleague(editObject);
    }
  };

  generateComissionList = (startDate, endDate) => {
    this.props.getCommission(startDate,endDate)
      .then(response => {
        const csvArray = [];
        const rawArray = response.data;
        const colleagues = new Set(rawArray.map(row => row[16]));

        csvArray.push('Szerződésszám;Ügyfél;Számlázási cím;Fizetési mód;Számlaszám;Nettó;;Üzletkötő;Kiadvány;Befizetés ideje;Számlázás ideje;+45 nap;Elmaradás;Előleg határidő;Előleg jutalék;Vég jutalék');

        colleagues.forEach(method => {
          const colleagueArray = rawArray.filter(row => row[16] === method);
          let sum = 0;
          let comissionAdvanceSum = 0;
          let comissionSum = 0;
          colleagueArray.forEach(row => {
            sum += row[5];
            comissionAdvanceSum += Number(row[14]);
            comissionSum += Number(row[15]);
            row.splice(-1);
            csvArray.push(row.join(';'));
          });

          csvArray.push(['Összesen: ' + sum, 'Előlegből: ' + comissionAdvanceSum,
            'Végbefizetésből: ' + comissionSum, 'Jutalék: ' + (comissionSum + comissionAdvanceSum)].join(';'))
        });

        saveFile('Jutalek.csv', csvArray.join('\n'));
      });
  };

  salespersonDebtList = (id) =>{
    this.props.getSalespersonDebt(id).then(response =>{
      const csvArray = [];
      const rawArray = response.data;

      csvArray.push('Szerződés kelte;Szerződésszám;Ügyfél;Irányítószám;Város;Cím;Fizetési mód;Kapcsolattartó;Telefonszám;E-mail;Adószám;Nettó tartozás;Bruttó tartozás;Kiadvány;Számlázás ideje;Fiz. határidő;+45 nap;Végszámlaszám;Üzletkötő');
      rawArray.forEach(row => {
        csvArray.push(row.join(';'));
      });

      saveFile('uzletkoto-tartozas.csv', csvArray.join('\n'));
    });
  };


  render() {
    return (
      <div>
        <QueryPage
          queryTableID='colleagues'
          filterForm={<FilterFormColleague/>}
          pageURL="/kisokostest/colleagues"
          optionalElements={
            [
              <Col className="col-1 justify-content-md-start text-left App-page-label">
                <Label className="App-label App-label-title">Üzletkötők</Label>
              </Col>,
              <Col className="col-2 justify-content-md-start text-left">
                <Button color="primary" onClick={() => this.toggleEditModal()}>Új</Button>
              </Col>,
              <Col className="col-1 justify-content-md-end text-right  ">
                <Button color="primary" onClick={() => this.toggleCommissionModal()}>Jutalék</Button>
              </Col>

            ]
          }
          headers={
            [
              {
                name: 'Üzletkötő',
                processor: object => object.name,
                className: "Table-invoice_name",
                orderName: "name"
              }, {
              name: 'Telefonszám',
              processor: object => object.phone_number,
              className: "Table-phone",
              orderName: "phone_number"
            }, {
              name: 'E-mail cím',
              processor: object => object.email_address,
              className: "Table-email",
              orderName: "email_address"
            }, {
              name: 'Iroda',
              processor: object => object.office.name,
              className: "Table-email",
              orderName: "office__name"
            }, {
              name: 'Aktív',
              processor: object => <input type="checkbox" onChange={() => this.updateActive(object) }  defaultChecked={object.active}/>,
              className: "Table-chkbox",
              ignoreExport: true
            },{
              name: '',
              processor: object => <Button className="App-icon-button" size={'sm'} onClick={() => this.toggleEditModal(object)}><img className="App-icon-button" src={edit}  alt={"Szerk."} data-tip="Szerkesztés"/><ReactTooltip /></Button>,
              className: "Table-btn",
              ignoreExport: true

            }, {
              name: '',
              processor: object => <Button className="App-icon-button"  outline onClick={() => this.salespersonDebtList(object.id)}><img className="App-icon-button" src={exporticon}  alt={"Tartozás"} data-tip="Tartozás"/><ReactTooltip /></Button>,
              className: "Table-btn",
              ignoreExport: true
            },{
              name: '',
              processor: object=> "",
              className: "Table-filler",
              ignoreExport: true
            }
            ]
          }
        />
        <Modal className="modal-dialog_mid" id='create-edit-colleague' headerName="Üzletkötő adatlap">
          <ColleagueForm/>
        </Modal>

        <Modal className="modal-dialog_mid" id='commission-export' headerName="Jutalék (Befizetés Dátuma)">
          <CommissionRangeForm handleSubmit={this.generateComissionList}/>
        </Modal>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getCommission: (startDate, endDate)=>{
    return dispatch(getRequest('/kisokostest/comissionlist?start_date=' + startDate + '&end_date=' + endDate))
  },
  getSalespersonDebt: (id)=>{
    return dispatch(getRequest('/kisokostest/salespersondebtlist?salesperson_id=' + id))
  },
  updateColleague: (colleague) =>{
    dispatch(putRequest('/kisokostest/colleagues/' +colleague.id,colleague))
  },
  closeColleague: () =>{
    dispatch(closeColleague())
  },
  openColleague: (id) =>{
    dispatch(openColleague(id));
  },
  addQuery: (id, relativeURL) => {
    dispatch(addQuery(id, relativeURL));
  },
  refreshQuery: id => {
    dispatch(invalidateQuery(id));
  },
  addModal: id => {
    dispatch(addModal(id));
  },
  toggleModal: id => {
    dispatch(toggleModal(id));
  }
});

export default connect(null,mapDispatchToProps)(ColleaguePage);

