import React, {Component} from 'react';
import {Button, Col, Container, Form, FormGroup, Input, Row,} from "reactstrap";
import InputGroup from "reactstrap/es/InputGroup";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import InputGroupText from "reactstrap/es/InputGroupText";
import {connect} from "react-redux";
import {postRequest} from "../actions/api";
import {toggleModal} from "../actions/modal";


class CompanyDirectoryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company_directory: '',
    };

  }

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => {
      const newState = {...prevState};
      newState[name] = value;
      return newState;
    });
  };

  render() {
    return (
      this.renderForm()
    );
  }

  stateToObject = () => {
    return ({
      company_directory: this.state.company_directory,
      client: {
        id: this.props.editId,
        billing_address: {},
        shipping_address: {},
        client_type: {},
        client_file: []
      },
    });
  };

  createEntry = () => {
    this.props.createEntry(this.stateToObject()).then(() => {
      this.props.requeryCD()
    });
    this.props.toggleModal(this.props.modalId);
  };


  renderForm() {
    return (
      < Container>
        <Row>
          <Col>
            <Form>
              <FormGroup>
                <Row >
                  <Col xs="12" className="pl-5">
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon" color="primary" addonType="prepend"><InputGroupText
                        className="input-group-text float-right">Cégmutató</InputGroupText></InputGroupAddon>
                      <Input type="text" name="company_directory" id="company_directory" onChange={this.handleChange}
                             value={this.state.company_directory}>
                      </Input>
                    </InputGroup>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row className="justify-content-end">
                  <Col className="text-right">
                    <Button color="primary" onClick={this.createEntry}>Mentés</Button>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}


const mapStateToProps = (state) => {
  const editId = state.client.clientId;
  return{
    editId
  }
};

const mapDispatchToProps = dispatch => ({
  createEntry: (data) => {
    return dispatch(postRequest('/kisokostest/companydirectories/', data))
  },
  toggleModal: id => {
    dispatch(toggleModal(id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDirectoryForm);