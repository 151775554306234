import {Button, Col, Form, Input, Row,} from "reactstrap"
import React from "react";
import InputGroup from "reactstrap/es/InputGroup";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import InputGroupText from "reactstrap/es/InputGroupText";

export default class FilterFormColleague extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      city: '',
      active: '',
    };
  }

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => {
      const newState = {...prevState};
      newState[name] = value;
      return newState;
    });
  };

  onRadioBtnClick(active) {
    this.setState({ active });
  }

  render() {
    return (
      <div>
        <Form onSubmit={e => {
          e.preventDefault();
          this.props.handleSubmit(this.state);
        }}>
          <Row style={{marginRight: 0}} className="mb-3">
            <Col md={6}>
              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend"><InputGroupText
                  className="input-group-text float-right">Név</InputGroupText></InputGroupAddon>
                <Input onChange={this.handleChange} value={this.state.client_name} type="text" name="name" id="name"
                       placeholder="Név"/>
              </InputGroup>

              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend"><InputGroupText
                  className="input-group-text float-right">Város</InputGroupText></InputGroupAddon>
                <Input onChange={this.handleChange} value={this.state.contact_name} type="text" name="city" id="city"
                       placeholder="Budapest"/>
              </InputGroup>



              <InputGroup className="App-searchLine" >
                <InputGroupAddon color="primary" addonType="prepend"><InputGroupText
                  className="input-group-text float-right"></InputGroupText></InputGroupAddon>
                <Col  className="p-0 App-Option-Select" >
                  <Button className="App-btn-group btn "  onClick={() => this.onRadioBtnClick('True')} active={this.state.active === 'True'} >Aktív</Button>
                </Col>
                <Col className="p-0 App-Option-separator">
                  |
                </Col>

                <Col  className="p-0 App-Option-Select">
                  <Button className="App-btn-group btn "  onClick={() => this.onRadioBtnClick('False')} active={this.state.active === 'False'} >Inaktív</Button>
                </Col>
                <Col className="p-0 App-Option-separator">
                  |
                </Col>
                <Col  className="p-0 App-Option-Select">
                  <Button className="App-btn-group btn " onClick={() => this.onRadioBtnClick('')} active={this.state.active === ''}>Mind</Button>
                </Col>

                <Col  className="p-0">
                  <div className="text-right">
                    <Button outline color="primary">Keresés</Button>
                  </div>
                </Col>

              </InputGroup>
            </Col>

          </Row>
        </Form>
      </div>
    );
  }
}