import {CLOSE_CLIENT, OPEN_CLIENT} from "../actions/client";

const client = (state= {
  clientId: undefined,
}, action) =>{
  switch (action.type) {
    case OPEN_CLIENT:
      return{
        ...state,
        clientId: action.clientId,
      };
    case CLOSE_CLIENT:
      return{
        ...state,
        clientId: undefined,
      };
    default:
      return state;
  }
};

export default client;
