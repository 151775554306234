import React from "react";
import {addQuery, invalidateQuery} from "../actions/query";
import {Col, Row} from "reactstrap";
import {connect} from "react-redux";
import QueryTable from "./QueryTable";

class QueryPage extends React.Component{
  
  convertURL = (url, fields) =>{
    let names = Object.values(fields);
    let new_url = "?";
    Object.keys(fields).forEach((key, index) => {
      if(key === 'client_name') {
        if(names[index].toString().includes('+')) {
          names[index] = names[index].replace('+', '%2B')
        }
        if(names[index].toString().includes('&')) {
          names[index] = names[index].replace('&', '%26')
        }
      }
      if(names[index] !== "")
        new_url = new_url + key + "=" + names[index] + "&";
    });
    return url + new_url;
  };

  handleFilterSubmit = fields => {
    this.props.addQuery(this.props.queryTableID, this.convertURL(this.props.pageURL, fields));
  };

  requery = () => {
    this.props.refreshQuery(this.props.queryTableID);
  };

  render() {

    let centering = this.props.queryTableID === "colleagues" || this.props.queryTableID === "products" ? 
                            "col-7 offset-3" : "col-7";

    return (
      <div>
        <div className="container col-12 my-2">
          <Row style={{ display:"flex", justifyContent: "center"}} >
            {
              this.props.optionalElements ?
                <Col className={centering}>
                  <Row className="pb-2">
                      {this.props.optionalElements.map((item, index) => React.cloneElement(item, {key: index}))}
                  </Row>
                </Col> :
                ''
            }
          </Row>
          <Row style={{ display:"flex", justifyContent: "center"}}>
            {
              this.props.filterForm ?
                <Col className={centering}>
                    {React.cloneElement(this.props.filterForm, {
                      handleSubmit: this.handleFilterSubmit
                    })}
                </Col> :
                ''
            }
          </Row>
          <Row>
            <Col style={{ display:"flex", justifyContent: "center", padding:"30px 0 0"}}>
              <QueryTable handleBackendResponse={this.props.handleBackendResponse} 
                          backend={true} 
                          id={this.props.queryTableID} 
                          headers={this.props.headers} 
                          defaultURL={this.props.pageURL}/>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}


const mapDispatchToProps = dispatch => ({
  addQuery: (id, relativeURL) => {
    dispatch(addQuery(id, relativeURL));
  },
  refreshQuery: id => {
    dispatch(invalidateQuery(id));
  }
});

export default connect(null, mapDispatchToProps)(QueryPage);
