export const OPEN_PRODUCT = 'OPEN_PRODUCT';
export const CLOSE_PRODUCT = 'CLOSE_PRODUCT';

export const openProduct = (id) => ({
  type: OPEN_PRODUCT,
  productId: id
});

export const closeProduct = () => ({
  type: CLOSE_PRODUCT
});
