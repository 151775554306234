import React, {Component} from 'react';
import {Button, Col, Form, FormGroup, Input, Row} from "reactstrap";
import '../Modal.css';
import InputGroup from "reactstrap/es/InputGroup";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import InputGroupText from "reactstrap/es/InputGroupText";
import {deleteRequest, getRequest, postRequest, putRequest} from "../actions/api";
import {connect} from "react-redux";
import {getPropertyOrEmptyString, getPropertyOrUndefined} from "../Util";
import {openClient} from "../actions/client";
import {invalidateQuery} from '../actions/query.js';
import QueryTable from "../components/QueryTable";
import {addQuery, removeQuery} from "../actions/query";
import {addModal, toggleModal} from "../actions/modal";
import Modal from "../components/Modal";
import {closeContract, openContract} from "../actions/contract";
import ContractForm from "./ContractForm";

import edit from "../icons/edit.png";
import copy from "../icons/copy.png";
import ReactTooltip from "react-tooltip";

class ClientForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client_name: '',
      tax_number: '',
      contact_name: '',
      phone_number1: '',
      email_address: '',
      mailing_name: '',
      billing_address_address: '',
      billing_address_postcode: '',
      billing_address_city: '',
      shipping_address_address: '',
      shipping_address_postcode: '',
      shipping_address_city: '',
      client_type_type: '',
      types: [],
      colleagues: [],
      salesperson_name: '',
      keywords: '',
      spent_so_far: '',
      salesperson_id: undefined,
      money_owed:'',
      money_billed: '',
      money_paid: '',
      inactive: undefined,
      client_file: [],
      szamlakuldesiemail: ''
    };

  }

  getClientObject(id) {
    this.props.getClient(id).then(response => {
      console.log(`getClientObject:`, response.data);
      this.setState({
        client_name: getPropertyOrEmptyString(response.data[0], 'client_name'),
        tax_number: getPropertyOrEmptyString(response.data[0], 'tax_number'),
        contact_name: getPropertyOrEmptyString(response.data[0], 'contact_name'),
        phone_number1: getPropertyOrEmptyString(response.data[0], 'phone_number1'),
        email_address: getPropertyOrEmptyString(response.data[0], 'email_address'),
        mailing_name: getPropertyOrEmptyString(response.data[0], 'mailing_name'),
        billing_address_address: getPropertyOrEmptyString(response.data[0], 'billing_address.address'),
        billing_address_postcode: getPropertyOrEmptyString(response.data[0], 'billing_address.postcode'),
        billing_address_city: getPropertyOrEmptyString(response.data[0], 'billing_address.city'),
        shipping_address_address: getPropertyOrEmptyString(response.data[0], 'shipping_address.address'),
        shipping_address_postcode: getPropertyOrEmptyString(response.data[0], 'shipping_address.postcode'),
        shipping_address_city: getPropertyOrEmptyString(response.data[0], 'shipping_address.city'),
        client_type_type: getPropertyOrEmptyString(response.data[0], 'client_type.type'),
        spent_so_far: getPropertyOrEmptyString(response.data[0], 'money_spent').toLocaleString("hu-HU"),
        money_owed: getPropertyOrEmptyString(response.data[0], 'money_owed').toLocaleString("hu-HU"),
        money_billed: getPropertyOrEmptyString(response.data[0], 'money_billed').toLocaleString("hu-HU"),
        money_paid: getPropertyOrEmptyString(response.data[0], 'money_paid').toLocaleString("hu-HU"),
        salesperson_id: getPropertyOrUndefined(response.data[0], 'salesperson.id'),
        inactive: getPropertyOrUndefined(response.data[0], 'inactive'),
        szamlakuldesiemail: getPropertyOrEmptyString(response.data[0], 'szamlakuldesiemail'),
        client_file: []
      });

      if (this.state.salesperson_id && this.state.colleagues.filter(colleague => (colleague.id === this.state.salesperson_id)).length === 0) {
        this.props.getAColleague(this.state.salesperson_id).then((response) => {
          let temp = this.state.colleagues.push(response.data.results[0]);
          this.setState(prevState => ({
            colleagues: [...prevState.colleagues, temp]
          }));
        }).catch(function (error) {

        });
      }
    }).catch(function (error) {

    });
  }

  componentDidMount() {
    this.props.getActiveColleagues().then((response) => {
      let colleagues = [];
      response.data.results.map((i) => colleagues = [...colleagues, i]);
      this.setState({
        colleagues: colleagues,
        salesperson_id: this.state.salesperson_id ? this.state.salesperson_id : colleagues[0].id
      });
      if (this.props.editId !== undefined) {
        this.getClientObject(this.props.editId);
        this.props.getKeywords(this.props.editId).then((response) => {
          let keywords = [];
          let tempKeywordsString = "";
          response.data.map((i) => keywords = [...keywords, i.keyword]);
          for (let i = 0; i < keywords.length; i++) {
            tempKeywordsString = tempKeywordsString.concat(keywords.pop() + "\n");
          }
          this.setState({keywords: tempKeywordsString});
        });
      }
    }).catch(function (error) {

    });
    // This function gets all the types from DB for the select in edit modal
    this.props.getType().then((response) => {
      let types = [];
      response.data.map((i) => types = [...types, i.type]);
      this.setState({types: types});
    }).catch(function (error) {

    });
    console.log(this.state.client_type_type)


  }

  toggleCheckbox = e => {
    const {name} = e.target;
    this.setState(prevState => ({
      ...prevState,
      [name]: !prevState.inactive
    }));

  };


  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => {
      const newState = {...prevState};
      newState[name] = value;
      return newState;
    });
  };

  handleSalesperson = e => {
    this.handleChange(e);
    this.setState({
      salesperson_id: e.target.value
    });
  };

  stateToObject = () => {

    let taxNumberWithoutHyphens = this.state.tax_number
    while(taxNumberWithoutHyphens.includes('-')) {
      taxNumberWithoutHyphens = taxNumberWithoutHyphens.replace('-', '')
    }
    
    return ({
      client_name: this.state.client_name,
      tax_number: taxNumberWithoutHyphens,
      contact_name: this.state.contact_name,
      phone_number1: this.state.phone_number1,
      email_address: this.state.email_address,
      mailing_name: this.state.mailing_name,
      inactive: this.state.inactive ? this.state.inactive : false,
      billing_address: {
        address: this.state.billing_address_address,
        postcode: this.state.billing_address_postcode,
        city: this.state.billing_address_city,
      },
      shipping_address: {
        address: this.state.shipping_address_address,
        postcode: this.state.shipping_address_postcode,
        city: this.state.shipping_address_city,
      },
      client_type: {
        type: this.state.client_type_type
      },
      client_file: [],
      salesperson: {
        id: this.state.salesperson_id,
        office: 5,
      },
      szamlakuldesiemail: this.state.szamlakuldesiemail,
    });
  };

  createOrUpdate = () => {
    if (this.props.editId !== undefined) {
      this.props.saveEntry(this.props.editId, this.stateToObject()).then((response) => {
        this.props.requery();
        this.props.toggleModal(this.props.modalId);
      });
    }
    else
      this.props.createEntry(this.stateToObject()).then((response) => {
        this.props.setEdited(response.data.id);
        this.props.addQuery('client-contracts', '/kisokostest/contractscalc/?client_id=' + response.data.id);
        this.props.requery();
        this.props.toggleModal(this.props.modalId);
      }).catch(function (error) {

      });
  };

  deleteEntry = () => {
    if (this.props.editId !== undefined) {
      this.props.deleteEntry(this.props.editId).then(() => {
        this.props.requery();
      });
    }
    this.props.toggleModal(this.props.modalId)
  };

  copyAddress = e => {
    e.preventDefault();
    this.setState({
      shipping_address_address: this.state.billing_address_address,
      shipping_address_postcode: this.state.billing_address_postcode,
      shipping_address_city: this.state.billing_address_city,
      mailing_name: this.state.client_name

    })
  };

  toggleContractModal = (editId = undefined) => {
    if (editId !== undefined) {
      this.props.openContract(editId);
    } else {
      this.props.closeContract();
    }
    this.props.toggleModal('create-edit-contract');
  };

  refreshClient = () =>{
    this.getClientObject(this.props.editId);
  };

  render() {
    return (

            <Form >

              <FormGroup>
                {/*Személyes adatok*/}
                <Row >
                  <Col md={6} className={"pl-5 "}>
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon" color="primary" addonType="prepend"><InputGroupText
                        className="input-group-text float-right font-weight-bold">Név*</InputGroupText></InputGroupAddon>
                      <Input type="text" name="client_name" id="client_name" onChange={this.handleChange}
                             value={this.state.client_name}/>
                      <Input addon className="mt-1 ml-2" type="checkbox" name="inactive" id="inactive" onChange={this.toggleCheckbox}
                             checked={!this.state.inactive} data-tip="Aktív"/><ReactTooltip />

                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon" addonType="prepend"><InputGroupText
                        className="input-group-text font-weight-bold">Típus*</InputGroupText></InputGroupAddon>
                      <Input type="select" name="client_type_type" id="client_type_type" onChange={this.handleChange}
                             value={this.state.client_type_type}>
                        {this.state.types.map(i => <option key={i}>{i}</option>)}
                      </Input>
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon" addonType="prepend"><InputGroupText
                        className="input-group-text">Adószám</InputGroupText></InputGroupAddon>
                      <Input type="text" name="tax_number" id="tax_number" onChange={this.handleChange}
                             value={this.state.tax_number}/>
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon" addonType="prepend"><InputGroupText
                        className="input-group-text bg-light text-right">Kapcs.</InputGroupText></InputGroupAddon>
                      <Input type="text" name="contact_name" id="contact_name" onChange={this.handleChange}
                             value={this.state.contact_name}/>
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon" addonType="prepend"><InputGroupText
                        className="input-group-text">Telefon</InputGroupText></InputGroupAddon>
                      <Input type="phone" name="phone_number1" id="phone_number1" onChange={this.handleChange}
                             value={this.state.phone_number1}/>
                    </InputGroup>

                    <InputGroup>
                      <InputGroupAddon className="input-group-addon" addonType="prepend"><InputGroupText
                        className="input-group-text">E-mail</InputGroupText></InputGroupAddon>
                      <Input type="email" name="email_address" id="email_address" onChange={this.handleChange}
                             value={this.state.email_address}/>
                    </InputGroup>

                    <InputGroup>
                      <InputGroupAddon className="input-group-addon" addonType="prepend"><InputGroupText
                        className="input-group-text">Üzletkötő</InputGroupText></InputGroupAddon>
                      <Input type="select" name="salesperson_id" id="salesperson_id" onChange={this.handleSalesperson}
                             value={this.state.salesperson_id}>
                        {this.state.colleagues.map((i, index) => <option key={index} value={i.id}>{i.name}</option>)}
                      </Input>
                    </InputGroup>


                  </Col>

                  <Col md="6" className="App-fill-width pl-5 ">

                        <InputGroup>
                          <InputGroupAddon className="input-group-addon_postcode" addonType="prepend"><InputGroupText
                            className="input-group-text font-weight-bold">Számlázási cím*</InputGroupText></InputGroupAddon>
                          <Input className="Table-postcode mr-1" type="text" name="billing_address_postcode"
                                 id="billing_address_postcode"
                                 onChange={this.handleChange} value={this.state.billing_address_postcode}/>
                          <Input type="text" name="billing_address_city" id="billing_address_city"
                                 onChange={this.handleChange} value={this.state.billing_address_city}/>
                          <Button  color="warning" className="float-right App-icon-button ml-2" size="sm" id="same_address" name="same_address"
                                   onClick={this.copyAddress}><img  className="App-icon-button"  src={copy}  alt={"Másolás"} data-tip="Cím másolása"/><ReactTooltip /></Button>

                        </InputGroup>


                        <InputGroup>
                          <InputGroupAddon className="input-group-addon text-right" addonType="prepend"><InputGroupText
                            className="input-group-text font-weight-bold">

                          </InputGroupText></InputGroupAddon>
                          <Input type="text" name="billing_address_address"
                                 id="billing_address_address"
                                 onChange={this.handleChange} value={this.state.billing_address_address}/>
                        </InputGroup>

                    <InputGroup>
                      <InputGroupAddon className="input-group-addon" addonType="prepend"><InputGroupText
                        className="input-group-text">Lev. név</InputGroupText></InputGroupAddon>
                      <Input type="text" name="mailing_name" id="mailing_name"
                             onChange={this.handleChange} value={this.state.mailing_name}/>
                    </InputGroup>

                    <InputGroup>
                      <InputGroupAddon className="input-group-addon_postcode" addonType="prepend"><InputGroupText
                        className="input-group-text">Levelezési cím</InputGroupText></InputGroupAddon>
                      <Input className="Table-postcode mr-1"  type="text" name="shipping_address_postcode" id="shipping_address_postcode"
                             onChange={this.handleChange} value={this.state.shipping_address_postcode}/>
                      <Input type="text" name="shipping_address_city" id="shipping_address_city"
                             onChange={this.handleChange} value={this.state.shipping_address_city}/>
                    </InputGroup>

                    <InputGroup>
                      <InputGroupAddon className="input-group-addon" addonType="prepend"><InputGroupText className="input-group-text"/></InputGroupAddon>
                      <Input type="text" name="shipping_address_address" id="shipping_address_address"
                             onChange={this.handleChange} value={this.state.shipping_address_address}/>
                    </InputGroup>

                    <InputGroup>
                      <InputGroupAddon className="input-group-addon" addonType="prepend">
                        <InputGroupText className="input-group-text">Számlaküldési email cím</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="email"
                        name="szamlakuldesiemail"
                        id="szamlakuldesiemail"
                        onChange={this.handleChange}
                        value={this.state.szamlakuldesiemail}
                      />
                    </InputGroup>


                    <InputGroup className="input-group_textarea">
                      <InputGroupAddon className="input-group-addon align-items-baseline"  addonType="prepend"><InputGroupText
                        className="input-group-text">Címszavak</InputGroupText></InputGroupAddon>
                      <Input className="textarea" readOnly type="textarea" name="keywords" id="keywords"
                             value={this.state.keywords.toString()}/>
                    </InputGroup>
                  </Col>





                </Row>
              </FormGroup>
                <FormGroup>
                  <Row className="mt-3" >
                    <Col md="8" >
                    <InputGroup className="input-group_textarea">
                      <InputGroupAddon className="input-group-addon align-items-baseline App-label App-label-title pr-4 font-weight-bolder "  addonType="prepend"><InputGroupText
                        className="input-group-text">Szerződések</InputGroupText></InputGroupAddon>


                    {this.props.editId ? <QueryTable   id='contracts' headers={
                      [
                        {
                          name: 'Sorszám',
                          processor: object => object.contract_number || "Piszkozat",
                          className: "Table-ID",
                          orderName: "contract_number"
                        }, {
                        name: 'Kelte',
                        processor: object => object.contract_date,
                        className: "Table-date",
                        orderName: "contract_date"
                      }, {
                        name: 'Kötés',
                        processor: object => object.net_calc ? object.net_calc.toLocaleString("hu-HU") : 0,
                        exportProcessor: object => object.net_calc ? object.net_calc : 0,
                        className: "Table-summ",
                      }, {
                        name: 'Számlázott',
                        processor: object => object.billed ? object.billed.toLocaleString("hu-HU") : 0,
                        exportProcessor: object => object.billed ? object.billed : 0,
                        className: "Table-summ",
                      }, {
                        name: 'Befizetett',
                        processor: object => object.paid ? object.paid.toLocaleString("hu-HU") : 0,
                        exportProcessor: object => object.paid ? object.paid : 0,
                        className: "Table-summ",
                      }, {
                        name: 'Tartozás',
                        processor: object => object.billed ? (object.debt ? object.debt.toLocaleString("hu-HU") : 0) : 0,
                        exportProcessor: object => object.billed ? (object.debt ? object.debt : 0): 0,
                        className: "Table-summ",
                      }, {
                        name: '',
                        processor: object => <Button  className="App-icon-button" size={'sm'}
                                                      onClick={() => this.toggleContractModal(object.id)}><img className="App-icon-button" src={edit}  alt={"Szerk."} data-tip="Szerkesztés"/><ReactTooltip /></Button>,
                        className: "Table-btn",
                        ignoreExport: true,
                      }
                      ]
                    }
                                                     defaultURL={'/kisokostest/contractscalc/?client_id=' + this.props.editId}
                    /> : ""}
                    <Modal className="modal-dialog_mid" id='create-edit-contract' headerName="Szerződés">
                      <ContractForm isParentContractPage={false} refreshClient={this.refreshClient}/>
                    </Modal>
                    </InputGroup>
                  </Col>




                  <Col md="3" className="offset-1">
                    <Row className="mb-2">
                      <Col md={4} className="text-left">
                      {this.props.editId ?
                          <Button color="primary" onClick={() => this.toggleContractModal()}>Tételek</Button> : ""}
                    </Col>
                      <Col md={8} className="text-right">
                    {this.props.editId ?
                      <Button color="primary" onClick={() => this.toggleContractModal()}>Új szerződés</Button> : ""}
                      </Col>
                    </Row>
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon-50" addonType="prepend"><InputGroupText
                        className="input-group-text">Eddig kötött</InputGroupText></InputGroupAddon>
                      <Input type="text" disabled className="input-currency " readOnly name="spent_so_far"
                             value={this.state.spent_so_far} id="shippingAddress"/>
                    </InputGroup>

                    <InputGroup>
                      <InputGroupAddon className="input-group-addon-50" addonType="prepend"><InputGroupText
                        className="input-group-text">Számlázott</InputGroupText></InputGroupAddon>
                      <Input type="text" disabled  className="input-currency " readOnly name="billed" id="billed"
                             value={this.state.money_billed}/>
                    </InputGroup>

                    <InputGroup>
                      <InputGroupAddon className="input-group-addon-50" addonType="prepend"><InputGroupText
                        className="input-group-text">Tartozás</InputGroupText></InputGroupAddon>
                      <Input type="text" disabled  className="input-currency " readOnly value={this.state.money_owed} name="debt"
                             id="debt"/>
                    </InputGroup>

                    <InputGroup>
                      <InputGroupAddon className="input-group-addon-50" addonType="prepend"><InputGroupText
                          className="input-group-text">Befizetett</InputGroupText></InputGroupAddon>
                      <Input type="text" disabled  className="input-currency " readOnly value={this.state.money_paid} name="paid"
                             id="paid"/>
                    </InputGroup>

                  </Col>


                    <Col className="col-7 justify-content-md-s text-right">

                    </Col>
                  </Row>
              </FormGroup>
              <FormGroup>
                <Row className="justify-content-end">
                  <Col className="text-right">
                    <Button color="primary" onClick={this.createOrUpdate}>Mentés</Button>
                  </Col>
                </Row>
              </FormGroup>

            </Form>



    );
  }
}

const mapStateToProps = (state) => {
  const editId = state.client.clientId;
  return{
    editId
  }
};

const mapDispatchToProps = dispatch => ({
  openContract: (id) =>{
    dispatch(openContract(id));
  },
  closeContract: () =>{
    dispatch(closeContract());
  },
  getClient: (id) =>{
    return dispatch(getRequest('/kisokostest/clientscalc/?id=' +id))
  },
  getActiveColleagues: () =>{
    return dispatch(getRequest('/kisokostest/colleagues/?get_all=true&active=True'))
  },
  getAColleague: (id) =>{
    return dispatch(getRequest('/kisokostest/colleagues/?id=' + id))
  },
  getKeywords: (id) =>{
    return dispatch(getRequest('/kisokostest/clientkeywords/?id=' + id))
  },
  getType: ()  =>{
    return dispatch(getRequest('/kisokostest/clienttypes/'))
  },
  createEntry: (data) => {
    return dispatch(postRequest('/kisokostest/clientscalc/',data))
  },
  deleteEntry: (id) =>{
    return dispatch(deleteRequest('/kisokostest/clientscalc/' + id))
  },
  saveEntry: (id,data) =>{
    return dispatch(putRequest('/kisokostest/clientscalc/' +id,data))
  },
  setEdited: (id) =>{
    dispatch(openClient(id))
  },
  addQuery: (id,relativeURL) =>{
    dispatch(addQuery(id, relativeURL))
  },
  removeQuery: (id) => {
    dispatch(removeQuery(id));
  },
  requery: () =>{
    dispatch(invalidateQuery('clients'))
  },
  addModal: id => {
    dispatch(addModal(id));
  },
  toggleModal: id => {
    dispatch(toggleModal(id));
  }
});

export default connect(mapStateToProps,mapDispatchToProps)(ClientForm);
