import {CLOSE_CONTRACT_ITEM, OPEN_CONTRACT_ITEM} from "../actions/contractItem";

const contractItem = (state= {
  contractItemId: undefined,
}, action) =>{
  switch (action.type) {
    case OPEN_CONTRACT_ITEM:
      return{
        ...state,
        contractItemId: action.contractItemId,
      };
    case CLOSE_CONTRACT_ITEM:
      return{
        ...state,
        contractItemId: undefined,
      };
    default:
      return state;
  }
};

export default contractItem;
