import {Button, Col, Form, Input, Row,} from "reactstrap"
import React from "react";
import InputGroup from "reactstrap/es/InputGroup";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import InputGroupText from "reactstrap/es/InputGroupText";

export default class FilterFormContractTracking extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        attachmentFileUploaded: '',
        tordelo: '',
        kefe_sent: '',
        kefe_confirmation: '',
        comment1:'',
        comment2: '',
        contractNumber: '',
        companyName: ''
    };
  }

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => {
      const newState = {...prevState};
      newState[name] = value;
      return newState;
    });
  };

  render() {
    return (
      <div>
        <Form onSubmit={e => {
            e.preventDefault();
            this.props.handleSubmit(this.state);
        }}>
          <Row className="mb-3">
            <Col md={6}>
                <InputGroup>
                    <InputGroupAddon color="primary" addonType="prepend">
                        <InputGroupText className="input-group-text float-right">
                            Tördelő
                        </InputGroupText>
                    </InputGroupAddon>
                    <div className="aligning_space" style={{ padding: "0 26px 0 0" }}/>
                    <Input
                        className="input-search" 
                        onChange={this.handleChange}
                        value={this.state.tordelo}
                        type="text"
                        name="tordelo"
                        id="tordelo"
                        placeholder="Tördelő"/>
                </InputGroup>
                <InputGroup>
                    <InputGroupAddon color="primary" addonType="prepend">
                        <InputGroupText className="input-group-text float-right">
                            Kiküldött kefe
                        </InputGroupText>
                    </InputGroupAddon>
                    <div className="aligning_space" style={{ padding: "0 26px 0 0" }}/>
                    <Input
                        className="input-search" 
                        onChange={this.handleChange}
                        value={this.state.kefe_sent}
                        type="text"
                        name="kefe_sent"
                        id="kefe_sent"
                        placeholder="Kiküldött kefe"/>
                </InputGroup>
                <InputGroup>
                    <InputGroupAddon color="primary" addonType="prepend">
                        <InputGroupText className="input-group-text float-right">
                            Kefe visszaigazolás
                        </InputGroupText>
                    </InputGroupAddon>
                    <div className="aligning_space" style={{ padding: "0 19px 0 0" }}/>
                    <Input
                        className="input-search" 
                        onChange={this.handleChange}
                        value={this.state.kefe_confirmation}
                        type="text"
                        name="kefe_confirmation"
                        id="kefe_confirmation"
                        placeholder="Kefe visszaigazolás"/>
                </InputGroup>
                <InputGroup>
                    <InputGroupAddon color="primary" addonType="prepend">
                        <InputGroupText className="input-group-text float-right">
                            Dátum
                        </InputGroupText>
                    </InputGroupAddon>
                    <div className="aligning_space" style={{ padding: "0 26px 0 0" }}/>
                    <Input  
                        onChange={this.handleChange} 
                        value={this.state.attachmentFileUploaded}  
                        type="date"
                        min="2000-01-01"
                        max="2025-12-31"
                        name="attachmentFileUploaded"
                        id="attachmentFileUploaded"/>
                </InputGroup>
            </Col>
            
            <Col md={6}>
                <InputGroup>
                    <InputGroup>
                        <InputGroupAddon color="primary" addonType="prepend">
                            <InputGroupText className="input-group-text float-right">
                                Komment - 1 
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            className="input-search" 
                            onChange={this.handleChange}
                            value={this.state.comment1}
                            type="text"
                            name="comment1"
                            id="comment1"
                            placeholder="Komment - 1"/>
                    </InputGroup>
                    <InputGroup>
                        <InputGroupAddon color="primary" addonType="prepend">
                            <InputGroupText className="input-group-text float-right">
                                Komment - 2
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            className="input-search" 
                            onChange={this.handleChange}
                            value={this.state.comment2}
                            type="text"
                            name="comment2"
                            id="comment2"
                            placeholder="Komment - 2"/>
                    </InputGroup>
                    <InputGroup>
                        <InputGroupAddon color="primary" addonType="prepend">
                        <InputGroupText className="input-group-text float-right">
                            Szerződésszám
                        </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            className="input-search" 
                            onChange={this.handleChange}
                            value={this.state.contractNumber}
                            type="text"
                            name="contractNumber"
                            id="contractNumber"
                            placeholder="Szerződésszám"/>
                    </InputGroup>
                    <InputGroup>
                        <InputGroupAddon color="primary" addonType="prepend">
                            <InputGroupText className="input-group-text float-right">
                                Cégnév
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            className="input-search" 
                            onChange={this.handleChange}
                            value={this.state.companyName}
                            type="text"
                            name="companyName"
                            id="companyName"
                            placeholder="Cégnév"/>
                    </InputGroup>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText className="Search-button-aligner">
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                    <InputGroup className="App-searchLine" >
                        <Col className={"p-0"}>
                            <div className="text-right">
                                <Button outline color="primary">Keresés</Button>
                            </div>
                        </Col>
                    </InputGroup>
                </InputGroup>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}