import {CLOSE_APPEARANCE, OPEN_APPEARANCE} from "../actions/appearance";

const appearance = (state= {
  appearanceId: undefined,
}, action) =>{
  switch (action.type) {
    case OPEN_APPEARANCE:
      return{
        ...state,
        appearanceId: action.appearanceId,
      };
    case CLOSE_APPEARANCE:
      return{
        ...state,
        appearanceId: undefined,
      };
    default:
      return state;
  }
};

export default appearance;
