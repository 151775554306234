import React, {Component} from 'react';
import {Button, Col, Form, FormGroup, Input, Row} from "reactstrap";
import '../Modal.css';
import InputGroup from "reactstrap/es/InputGroup";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import InputGroupText from "reactstrap/es/InputGroupText";
import {getRequest, putRequest} from "../actions/api";
import {connect} from "react-redux";
import {invalidateQuery} from '../actions/query.js';
import {addQuery} from "../actions/query";
import {addModal, toggleModal} from "../actions/modal";

class ClientForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tordelo: '',
      kefe_sent: '',
      kefe_confirmation: '',
      comment1:'',
      comment2:'',
      contractNumber:''
    };
  }

  getContractTrackingObject(id) {
    this.props.contractTrackingListGet(id).then(response => {
      this.setState({
        tordelo: response.data.tordelo,
        kefe_sent: response.data.kefe_sent,
        kefe_confirmation: response.data.kefe_confirmation,
        comment1:response.data.comment1,
        comment2: response.data.comment2,
        contractNumber: response.data.contractNumber
      });
    }).catch(function (error) {

    });
  }

  componentDidMount() {
    if (this.props.editId !== undefined) {
      this.getContractTrackingObject(this.props.editId);
    }
  }

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => {
      const newState = {...prevState};
      newState[name] = value;
      return newState;
    });
  };

  stateToObject = () => {
    return ({
      tordelo: this.state.tordelo,
      kefe_sent: this.state.kefe_sent,
      kefe_confirmation: this.state.kefe_confirmation,
      comment1: this.state.comment1,
      comment2: this.state.comment2
    });
  };

  createOrUpdate = () => {
    if (this.props.editId !== undefined) {
      this.props.contractTrackingListPut(this.props.editId, this.stateToObject()).then((response) => {
        this.props.requery();
        this.props.toggleModal(this.props.modalId);
      });
    }
  };

  render() {
    return (
      <Form >
        <FormGroup>
          <Row >
            <Col md={6} className={"pl-5 "}>
            <InputGroup>
                <InputGroupAddon className="input-group-addon" color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right">
                    Szerződésszám
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                    className="form-control"
                    value={this.state.contractNumber}
                    type="text"
                    name="contractNumber"
                    id="contractNumber"
                    style={{ fontWeight: "bold" }}
                    disabled/>
              </InputGroup>
              <InputGroup>
                <InputGroupAddon className="input-group-addon" color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right">
                    Tördelő
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                    style={{ margin: "0 0 0 1px" }}
                    className="form-control" 
                    onChange={this.handleChange}
                    value={this.state.tordelo}
                    type="text"
                    name="tordelo"
                    id="tordelo"/>
              </InputGroup>
              <InputGroup>
                <InputGroupAddon className="input-group-addon" color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right">
                    Kiküldött kefe
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                    style={{ margin: "0 0 0 1px" }}
                    className="form-control" 
                    onChange={this.handleChange}
                    value={this.state.kefe_sent}
                    type="text"
                    name="kefe_sent"
                    id="kefe_sent"/>
              </InputGroup>
            </Col>

            <Col md="6" className="App-fill-width pl-5 ">
            <InputGroup>
                <InputGroupAddon className="input-group-addon" color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right">
                    Kefe visszaigazolás
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                    style={{ margin: "0 0 0 25px" }}
                    className="form-control" 
                    onChange={this.handleChange}
                    value={this.state.kefe_confirmation}
                    type="text"
                    name="kefe_confirmation"
                    id="kefe_confirmation"/>
              </InputGroup>
              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend">
                    <InputGroupText className="input-group-text float-right">
                        Komment - 1 
                    </InputGroupText>
                </InputGroupAddon>
                <Input
                    style={{ margin: "0 0 0 32px" }}
                    className="form-control" 
                    onChange={this.handleChange}
                    value={this.state.comment1}
                    type="text"
                    name="comment1"
                    id="comment1"/>
              </InputGroup>
              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right">
                    Komment - 2
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                    style={{ margin: "0 0 0 32px" }}
                    className="form-control" 
                    onChange={this.handleChange}
                    value={this.state.comment2}
                    type="text"
                    name="comment2"
                    id="comment2"/>
              </InputGroup>
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row className="justify-content-end">
            <Col className="text-right">
              <Button color="primary" onClick={this.createOrUpdate}>Mentés</Button>
            </Col>
          </Row>
        </FormGroup>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  const editId = state.contractTracking.contractTrackingId;
  return{
    editId
  }
};

const mapDispatchToProps = dispatch => ({
  addModal: id => {
    dispatch(addModal(id));
  },
  addQuery: (id,relativeURL) =>{
    dispatch(addQuery(id, relativeURL))
  },
  contractTrackingListGet: (tracking_id) => {
    return dispatch(getRequest(`/kisokostest/contracts/tracking/${tracking_id}/edit`))
  },
  contractTrackingListPut: (tracking_id, tracking_item) => {
    return dispatch(putRequest(`/kisokostest/contracts/tracking/${tracking_id}/edit`, tracking_item))
  },
  requery: () =>{
    dispatch(invalidateQuery('contracttracking'))
  },
  toggleModal: id => {
    dispatch(toggleModal(id));
  }
});

export default connect(mapStateToProps,mapDispatchToProps)(ClientForm);
