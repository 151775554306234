import {Button, Col, Form, Input, Row,} from "reactstrap"
import React from "react";
import InputGroup from "reactstrap/es/InputGroup";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import InputGroupText from "reactstrap/es/InputGroupText";
import Select from 'react-select';

export default class FilterFormFile extends React.Component {
  constructor(props) {
    super(props);
    this.options = {
      isClearable: true,
      isSearchable: true,
      formatType: [
        { value: 'Sz', label: 'Számla' },
        { value: 'M', label: 'Melléklet' },
      ],
      fileType: [
        { value: 'pdf', label: 'Dokumentum (.pdf)' },
        { value: 'png', label: 'Kép (.png)' },
      ]
    }

    this.state = {
      contractNumber: '',
      clientName: '',
      salespersonName: '',
      dateAfter: '',
      dateBefore:'',
      type: '',
      fileType: ''
    };
  }

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => {
      const newState = {...prevState};
      newState[name] = value;
      return newState;
    });
  };

  onChangeFormatType = (inputValue, actionMeta) => {
    let value = '';
    switch (actionMeta.action) {
      case 'select-option':
        value = inputValue.value;
        this.setState({
          type: value
        });
        return;
      case 'remove-value':
        this.setState({
          type: value
        });
        return;
      case 'clear':
        this.setState({
          type: value
        });
        return;
      default:
        return
    }
  };

  onChangeFile = (inputValue, actionMeta) => {
    let value = '';
    switch (actionMeta.action) {
      case 'select-option':
        value = inputValue.value;
        this.setState({
          fileType: value
        });
        return;
      case 'remove-value':
        this.setState({
          fileType: value
        });
        return;
      case 'clear':
        this.setState({
          fileType: value
        });
        return;
      default:
        return
    }
  };

  render() {
    return (
      <div>
        <Form onSubmit={e => {
          e.preventDefault();

          let value
          if (this.state.dateBefore === "" && this.state.dateAfter !== ""){
            let date = new Date().getDate();        // Current Date
            let month = new Date().getMonth() + 1;  // Current Month
            let year = new Date().getFullYear();    // Current Year
            if(month < 10){
              month = '0' + month;
            }
            value = year + '-' + month + '-' + date;
            this.setState({dateBefore: value.toString()});
          }

          this.props.handleSubmit(this.state);
        }}>
          <Row className="mb-3">
            <Col md={5}>
              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right">
                    Szerződésszám
                  </InputGroupText>
                </InputGroupAddon>
                <div className="aligning_space" style={{ padding: "0 1px 0 0" }}/>
                <Input
                  className="input-search" 
                  onChange={this.handleChange}
                  value={this.state.contractNumber}
                  type="text"
                  name="contractNumber"
                  id="contractNumber"
                  placeholder="Szerződésszám"/>
              </InputGroup>
              <InputGroup style={{ padding:"15px 0 25px" }}>
                <InputGroupAddon color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right">
                    Ügyfél neve
                  </InputGroupText>
                </InputGroupAddon>
                <div className="aligning_space" style={{ padding: "0 1px 0 0" }}/>
                <Input
                  className="input-search"
                  onChange={this.handleChange}
                  value={this.state.client_name}
                  type="text"
                  name="clientName"
                  id="clientName" placeholder="Ügyfél neve"/>
              </InputGroup>
              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend">
                    <InputGroupText className="input-group-text float-right align-items-baseline" style={{ padding:"10px 0 9px" }}>
                      Formátum
                    </InputGroupText>
                    <Select name="selected_products" 
                            isClearable={this.options.isClearable}
                            isSearchable={this.options.isSearchable}
                            className='App-select-file-format'
                            onChange={this.onChangeFormatType}
                            options={this.options.formatType}/>
                  </InputGroupAddon>
              </InputGroup>
            </Col>
            
            <Col md={7}>
              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right">
                    Dátum
                  </InputGroupText>
                </InputGroupAddon>
                <div className="aligning_space" style={{ padding: "0 16px 0 0" }}/>
                <Input  
                  onChange={this.handleChange} 
                  value={this.state.dateAfter}  
                  type="date"
                  min="2000-01-01"
                  max="2025-12-31"
                  name="dateAfter"
                  id="dateAfter"
                  placeholder="Dátum nagyobb mint"/>
                -
                <Input 
                  onChange={this.handleChange}
                  value={this.state.dateBefore}
                  type="date"
                  min="2000-01-01"
                  max="2025-12-31"
                  name="dateBefore"
                  id="dateBefore"
                  placeholder="Dátum ig"/>
              </InputGroup>
              <InputGroup style={{ padding:"15px 0 25px" }}>
                <InputGroupAddon color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right">
                    Üzletkötő
                  </InputGroupText>
                </InputGroupAddon>
                <div className="aligning_space" style={{ padding: "0 16px 0 0" }}/>
                <Input
                  className="input-search"
                  onChange={this.handleChange}
                  value={this.state.salespersonName}
                  type="text"
                  name="salespersonName"
                  id="salespersonName"
                  placeholder="Üzletkötő"/>
              </InputGroup>
              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend">
                    <InputGroupText className="input-group-text float-right align-items-baseline" style={{ padding:"10px 0 9px" }}>
                    Dokumentum típusa
                    </InputGroupText>
                    <div style={{ width: "6px" }}></div>
                    <Select name="selected_fil" 
                            isClearable={this.options.isClearable}
                            isSearchable={this.options.isSearchable}
                            className='App-select-file'
                            onChange={this.onChangeFile}
                            options={this.options.fileType}/>
                </InputGroupAddon>
              </InputGroup>
              <InputGroup className="App-searchLine" >
                <Col className={"p-0"}>
                  <div className="text-right">
                    <Button outline color="primary">Keresés</Button>
                  </div>
                </Col>
              </InputGroup>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}