export const QUERY_ADD = 'QUERY_ADD';
export const QUERY_VALIDATE = 'QUERY_VALIDATE';
export const QUERY_INVALIDATE = 'QUERY_INVALIDATE';
export const QUERY_REMOVE = 'QUERY_REMOVE';
export const QUERY_SET_DATA = 'QUERY_SET_DATA';

export const addQuery = (id, relativeURL, data) => ({
  type: QUERY_ADD,
  id: id,
  relativeURL: relativeURL,
  data: data
});

export const validateQuery = id => ({
  type: QUERY_VALIDATE,
  id: id
});

export const invalidateQuery = id => ({
  type: QUERY_INVALIDATE,
  id: id
});

export const removeQuery = id => ({
  type: QUERY_REMOVE,
  id: id
});

export const setData = (id, data) => ({
  type: QUERY_SET_DATA,
  id: id,
  data: data
});
