import {Button, Col, Form, FormGroup, Input, Row,} from "reactstrap"
import React, {Component} from "react";
import InputGroup from "reactstrap/es/InputGroup";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import InputGroupText from "reactstrap/es/InputGroupText";
import {toggleModal} from "../actions/modal";
import {connect} from "react-redux";
import {getRequest} from "../actions/api";

class FilterFormProduct extends Component{
  constructor(props) {
    super(props);
    this.state = {
      description: '',
      office_name: '',
      office_id: '',
      offices: [],
      date_lte: '',
      date_gte: '',
      code: '',
    };
  }

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => {
      const newState = {...prevState};
      newState[name] = value;
      return newState;
    });
  };

  handleOffice = e => {
    this.handleChange(e);
  };


  componentDidMount() {
    this.props.getOffices().then((response) => {
      let offices = [];
      response.data.map((i) => offices = [...offices, i.name]);
      this.setState({offices: offices});
      this.setState({office_name: offices[4]});
    }).catch(function (error) {

    });
  }

  stateToObject = () => {
    return ({
      office_name: this.state.office_name,
      advance_payment_date__lte: this.state.date_lte,
      advance_payment_date__gte: this.state.date_gte,
      code: this.state.code,
    });
  };

  render() {
    return (
      <div>
        <Form onSubmit={e => {
          e.preventDefault();
          this.props.handleSubmit(this.stateToObject());
        }}>
          <FormGroup>
            <Row style={{marginRight: 0}} >
              <Col md={6}>
                <InputGroup>
                  <InputGroupAddon color="primary" addonType="prepend"><InputGroupText
                    className="input-group-text float-right">Iroda</InputGroupText></InputGroupAddon>
                  <Input type="select" name="office_name" id="office_name" onLoad={this.handleOffice}
                         onChange={this.handleOffice} value={this.state.office_name}>
                    {this.state.offices.map((i, index) => <option key={index}>{i}</option>)}
                  </Input>
                </InputGroup>

                <InputGroup>
                  <InputGroupAddon color="primary" addonType="prepend"><InputGroupText
                    className="input-group-text float-right">Kód</InputGroupText></InputGroupAddon>
                  <Input onChange={this.handleChange} value={this.state.code} type="text" name="code"
                         id="code" placeholder="Kód"/>
                </InputGroup>

                <InputGroup >
                  <InputGroupAddon color="primary" addonType="prepend"><InputGroupText
                    className="input-group-text float-right">Előleg határidő</InputGroupText></InputGroupAddon>
                  <Input onChange={this.handleChange} value={this.state.date_gte} type="date" name="date_gte"
                         id="date_gte" min="2000-01-01" max="2025-12-31" placeholder="Dátum nagyobb mint"/>
                         -
                  <Input onChange={this.handleChange} value={this.state.date_lte} type="date" name="date_lte"
                         id="date_lte" min="2000-01-01" max="2025-12-31" placeholder="Dátum ig"/>
                </InputGroup>


                <InputGroup  className="App-searchLine">
                  <InputGroupAddon color="primary" addonType="prepend"><InputGroupText
                    className="input-group-text float-right"></InputGroupText></InputGroupAddon>
                  <Col className={"p-0"}>
                  <div className="text-right">
                    <Button outline color="primary">Keresés</Button>
                  </div>

                  </Col>
                </InputGroup>
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const productEditId = state.product.productId;
  return{
    productEditId
  }
};

const mapDispatchToProps = dispatch => ({
  getOffices: () =>{
    return dispatch(getRequest('/kisokostest/offices/'))
  },
  toggleModal: id => {
    dispatch(toggleModal(id));
  }
});

export default connect(mapStateToProps,mapDispatchToProps)(FilterFormProduct);
