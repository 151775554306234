import React, { Component } from "react";
import { connect } from "react-redux";
import FilterFormEmail from "../FilterForm/FilterFormEmail";
import { Col, Label } from "reactstrap"
import QueryPage from "../components/QueryPage";

class EmailLogPage extends Component {

  render() {
    
    return (
      <div>
        <QueryPage
          queryTableID='emaillog'
          filterForm={<FilterFormEmail/>}
          pageURL="/kisokostest/email/log"
          optionalElements={
            [
              <Col className="col-1 justify-content-md-start text-left App-page-label">
                <Label className="App-label App-label-title">Emailek</Label>
              </Col>
            ]
          }
          headers={
            [
              {
                name: 'Címzettek',
                processor: object => object.recipient,
                className: "Table-recipient",
                orderName: "recipient"
              },{
                name: 'Munkatárs',
                processor: object => object.colleague,
                className: "Table-colleague",
                orderName: "colleague"
              },{
                name: 'Típus',
                processor: object => object.type,
                className: "Table-type",
                orderName: "type"
              },{
                name: 'További információ',
                processor: object => object.parameter,
                className: "Table-parameter",
                orderName: "parameter"
              },{
                name: 'Időbélyeg',
                processor: object => object.timestamp.toString().slice(0,10).replace(/-/gi, '.') + '.',
                className: "Table-timestamp",
                orderName: "timestamp"
              }
            ]
          }
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EmailLogPage);

