import React, { Component } from "react";
import { connect } from "react-redux";
import { getRequest } from "../actions/api";
import { Container, TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, Spinner, CardHeader, CardFooter, CardBody, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import classnames from 'classnames';

class Dashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1",
      isLoading: true,
      dashboard: null,
    };
  }

  componentDidMount() {
    console.log('componentDidMount');
    this.props.getDashboardData().then(response => {
      console.log('getDashboardData.then', response.data);
      this.setState({ dashboard: response.data, isLoading: false })
    })
  }

  setActiveTab = tab => {
    if(this.state.activeTab !== tab) this.setState({ activeTab: tab });
  }

  render() {

    console.log('Dashboard: ', this.state.dashboard);

    const cardGroupOfContracts =
    (
      this.state.dashboard && this.state.dashboard.contracts.map(contract => {
        return (
          <Card style = {{ flex: '1 0 21%', margin: 10, backgroundColor: contract.isDraft ? '#333' : '#fff' }} inverse = {contract.isDraft} >
            <CardHeader tag="h3">{contract.contractNumber ? contract.contractNumber : "Piszkozat"}</CardHeader>
            <CardBody>
              <CardText>
                <ListGroup>
                  <ListGroupItem color="info">Salesperson: <b>{contract.salesperson}</b></ListGroupItem>
                  <ListGroupItem color="info">Client: <b>{contract.client}</b></ListGroupItem>
                </ListGroup>
              </CardText>
              <CardText>
              {contract.files.length > 0 && <h4>Files</h4>}
                <ListGroup>
                  {contract.files.map(file => {
                    let fileName = ''
                    if(file.name.includes('_M.pdf'))
                    {
                      fileName = 'Szerződés melléklet'
                    }
                    else if(file.name.includes('_SZ.pdf'))
                    {
                      fileName = 'Szerződés'
                    }
                    else if(file.name.includes('signature'))
                    {
                      fileName = 'Szerződés aláírása'
                    }
                    else {
                      fileName = file.name
                    }
                    return (
                      <ListGroupItem color="info" tag="a" href={`http://localhost:8000/kisokostest/files/${file.id}`} key = {`listGroupItemFile${file.id}`}>{fileName} <Badge color="secondary" pill>{file.timestamp.substring(0, 10)}</Badge></ListGroupItem>
                    )
                  })}
                </ListGroup>
              </CardText>
            </CardBody>
            <CardFooter className="text-muted">{contract.timestamp ? contract.timestamp : "Piszkozat"}</CardFooter>
          </Card>
        )}
      )
    )
    
    
    return (
      <Container className="themed-container" fluid={true}>
        <Row>
          <Col>
          {this.state.isLoading === true ?
          (
            <Spinner color="primary" />
          )
          :
          (
            <div>
              <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => { this.setActiveTab('1'); }}
                    >
                      Contracts
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '2' })}
                      onClick={() => { this.setActiveTab('2'); }}
                    >
                      More Tabs
                    </NavLink>
                  </NavItem>
                </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <Row>
                        <Col>
                          <h1>Contracts</h1>
                          <div style = {{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'space-evenly',
                            alignItems: 'strech',
                            alignContent: 'strech',
                            height: '100%',
                            width: '100%',
                          }}>
                            {cardGroupOfContracts}
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="6">
                          <Card body>
                            <CardTitle>Special Title Treatment</CardTitle>
                            <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                            <Button>Go somewhere</Button>
                          </Card>
                        </Col>
                        <Col sm="6">
                          <Card body>
                            <CardTitle>Special Title Treatment</CardTitle>
                            <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                            <Button>Go somewhere</Button>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
          )}
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  const editId = state.client.clientId;
  return{
    editId
  }
};

const mapDispatchToProps = dispatch => ({
  getDashboardData: () => {
    return dispatch(getRequest('/kisokostest/application/dashboard'))
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

