import React, {Component} from 'react';
import {connect} from 'react-redux';
import NavBar from "./components/NavBar";
import Login from "./components/Login";
import AlertApiError from "./components/Alert";


class App extends Component {
  render() {
    return(
      <div className="App">
        <AlertApiError className="App-Alert"/>
        {this.props.isAuthenticated ? <NavBar/> :<Login/>}

      </div>
    )
  }
}

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  return {
    isAuthenticated
  };
};

export default connect(mapStateToProps)(App);
