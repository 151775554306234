export const OPEN_PAYMENT = 'OPEN_PAYMENT';
export const CLOSE_PAYMENT = 'CLOSE_PAYMENT';

export const openPayment = (id) => ({
  type: OPEN_PAYMENT,
  paymentId: id
});

export const closePayment = () => ({
  type: CLOSE_PAYMENT
});
