import React, {Component} from 'react';
import {openContractItem} from "../actions/contractItem";
import {deleteRequest, getRequest, postRequest, putRequest} from "../actions/api";
import {invalidateQuery} from "../actions/query";
import {toggleModal} from "../actions/modal";
import {connect} from "react-redux";
import Modal from "../components/Modal";
import {Button, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row} from "reactstrap";
import QueryTable from "../components/QueryTable";
import {getPropertyOrEmptyString, getPropertyOrUndefined, getPropertyOrZero} from "../Util";
import {closePayment, openPayment} from "../actions/payment";
import {closeAppearance, openAppearance} from "../actions/appearance";
import AppearanceFormSingle from "./AppearanceFormSingle";
import PaymentForm from "./PaymentForm";
import edit from "../icons/edit.png";
import ReactTooltip from "react-tooltip";

class ContractItemFormSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contract_item_id: 0,
      product_id: 0,
      product_code: '',
      product_spread_date: '',
      product_advance_payment_date: '',
      contract_id: 0,
      contract_contract_number: '',
      contract_contract_date: '',
      products: [],
      final_bill_number: '',
      comment: '',
    };
  }

  getContractItemObject(id) {
    this.props.getContractItem(id).then(response => {
      this.setState({
        contract_item_id: getPropertyOrZero(response.data[0], ''),
        product_id: getPropertyOrZero(response.data[0], 'product.id'),
        product_code: getPropertyOrEmptyString(response.data[0], 'product.code'),
        product_spread_date: getPropertyOrEmptyString(response.data[0], 'product.spread_date'),
        product_advance_payment_date: getPropertyOrEmptyString(response.data[0], 'product.advance_payment_date'),
        contract_id: getPropertyOrZero(response.data[0], 'contract.id'),
        contract_contract_number: getPropertyOrEmptyString(response.data[0], 'contract.contract_number'),
        contract_contract_date: getPropertyOrEmptyString(response.data[0], 'contract.contract_date'),
        final_bill_number: getPropertyOrEmptyString(response.data[0], 'final_bill_number'),
        comment: getPropertyOrEmptyString(response.data[0], 'comment')
      });
    }).catch(function (error) {

    });
  }

  getContractObject(id) {
    this.props.getContract(id).then(response => {
      this.setState({
        contract_item_id: undefined,
        contract_id: getPropertyOrZero(response.data.results[0], 'id'),
        contract_contract_number: getPropertyOrEmptyString(response.data.results[0],  'contract_number'),
        contract_contract_date: getPropertyOrUndefined(response.data.results[0], 'contract_date')
      });
    }).catch(function (error) {

    });
  }

  componentDidMount() {
    if(this.props.contractItemEditId === undefined) {
      this.props.getProducts().then((response) => {
        let products = [];
        response.data.results.map((i) => products = [...products, i]);
        if (products.length > 0) {
          this.setState({
            products: products,
            product_id: this.state.product_id ? this.state.product_id : products[0].id,
            product_code: this.state.product_code ? this.state.product_code : products[0].code
          });
        }
      }).catch(function (error) {

      });
    }
    else {
      this.props.getProductsAll().then((response) => {
        let products = [];
        response.data.map((i) => products = [...products, i]);
        this.setState({
          products: products,
          product_id: this.state.product_id ? this.state.product_id : products[0].id,
          product_code: this.state.product_code ? this.state.product_code : products[0].code
        });
      }).catch(function (error) {

      });
    }
    if (this.props.contractItemEditId !== undefined) {
      this.getContractItemObject(this.props.contractItemEditId);
    }
    if (this.props.contractEditId !== undefined) {
      this.getContractObject(this.props.contractEditId);
    }
  }


  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => {
      const newState = {...prevState};
      newState[name] = value;
      return newState;
    });
  };

  handleProduct = e => {
    this.handleChange(e);
    this.props.getSingleProduct(e.target.value).then((response) => {
      let product = [];
      response.data.results.map((i) => product = [...product, i]);
      this.setState({
        product_id: product[0].id,
        product_code: product[0].code,
        product_spread_date: product[0].spread_date,
        product_advance_payment_date: product[0].advance_payment_date,
      });
    }).catch(function (error) {

    });

  };

  stateToObject = () => {
    return ({
      id: this.state.contract_item_id,
      product: {
        id: this.state.product_id,
        code: this.state.product_code,
        advance_payment_date: this.state.product_advance_payment_date ? this.state.product_advance_payment_date : undefined,
        spread_date: this.state.spread_date,
        office: {
          name: '',
        }
      },
      contract: {
        id: this.state.contract_id,
        contract_number: this.state.contract_contract_number,
        contract_date: this.state.contract_contract_date,
        payment_method: {
          id: 0,
        },
        client: {
          name: this.state.client_name,
          id: 0,
          billing_address: {},
          shipping_address: {},
          client_type: {},
          client_file: []
        },
        salesperson: {
          id: 0,
          office: 5
        },
        processor: {
          id: 0,
          office: 5
        }
      },
      final_bill_number: this.state.final_bill_number,
      comment: this.state.comment
    });
  };

  createOrUpdate = () => {
    if (this.props.contractItemEditId !== undefined)
      this.props.saveEntry(this.props.contractItemEditId, this.stateToObject()).then(() => {
        this.props.requery();
        this.props.refreshContract();
        this.props.toggleModal(this.props.modalId);
      });
    else
      this.props.createEntry(this.stateToObject()).then(() => {
        this.props.requery();
        this.props.refreshContract();
        this.props.toggleModal(this.props.modalId);
      });
  };

  deleteEntry = () => {
    if (this.props.contractItemEditId !== undefined) {
      this.props.deleteEntry(this.props.contractItemEditId).then(() => {
        this.props.requery();
      });
      this.props.toggleModal(this.props.modalId);
    }
  };

  toggleAppearanceModal = (editId = undefined) => {
    if (editId !== undefined) {
      this.props.openAppearance(editId);
    } else {
      this.props.closeAppearance();
    }
    this.props.toggleModal('create-edit-appearance-single');
  };

  togglePaymentModal = (editId = undefined) => {
    if (editId !== undefined) {
      this.props.openPayment(editId);
    } else {
      this.props.closePayment();
    }
    this.props.toggleModal('create-edit-payment');
  };

  render() {
    return (

            <Form>


              <FormGroup>
                <Row className="mb-3">
                  <Col md="3" className="pl-5">
                    <InputGroup>
                      <InputGroupAddon color="primary" addonType="prepend"><InputGroupText
                        className="input-group-text float-right font-weight-bold input-group-short">Kiadvány*</InputGroupText></InputGroupAddon>
                      <Input type="select" name="product_code" id="product_code" onChange={this.handleProduct}
                             value={this.state.product_id}>
                        {this.state.products.map(i => <option key={i.id} value={i.id}>{i.code}</option>)}
                      </Input>
                    </InputGroup>

                  </Col>

                  <Col md="5" className="pl-5">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend"><InputGroupText
                        className="input-group-text bg-light text-right font-weight-bold">Szerződés
                        száma*</InputGroupText></InputGroupAddon>
                      <Input disabled type="text" readOnly name="contract_contract_number" id="contract_contract_number"
                             onChange={this.handleChange}
                             value={this.state.contract_contract_number}/>
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend"><InputGroupText
                        className="input-group-text">Végszámlaszám</InputGroupText></InputGroupAddon>
                      <Input type="text" name="final_bill_number" id="final_bill_number" onChange={this.handleChange}
                             value={this.state.final_bill_number}/>
                    </InputGroup>

                  </Col>
                  <Col md="4" className="pl-5">
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon" addonType="prepend"><InputGroupText
                        className="input-group-text">Terjesztés</InputGroupText></InputGroupAddon>
                      <Input disabled type="date" min="2000-01-01" max="2025-12-31" name="product_spread_date" id="product_spread_date"
                             onChange={this.handleChange}
                             value={this.state.product_spread_date}/>
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon" addonType="prepend"><InputGroupText
                        className="input-group-text">Előleg</InputGroupText></InputGroupAddon>
                      <Input disabled type="date" min="2000-01-01" max="2025-12-31" name="product_advance_payment_date" id="product_advance_payment_date"
                             onChange={this.handleChange}
                             value={this.state.product_advance_payment_date}/>
                    </InputGroup>
                    
                    <InputGroup className="input-group_textarea">
                      <InputGroupAddon className="input-group-addon" addonType="prepend"><InputGroupText
                        className="input-group-text">Komment</InputGroupText></InputGroupAddon>
                      <Input className="textarea"  type="textarea" name="comment" id="comment" onChange={this.handleChange}
                             value={this.state.comment}/>
                    </InputGroup>
                  </Col>
                </Row>


              </FormGroup>
              <FormGroup>

                <Row className="mt-5" >
                  <Col md="5" >
                    <Row >
                      <Col md={12} className="text-right">
                        {this.props.contractItemEditId ?
                          <Button color="primary" onClick={() => this.toggleAppearanceModal()}>Új Felület</Button> : ''}
                      </Col>
                    </Row>
                    <Row >
                      <Col md={12} className="text-right">
                    <InputGroup className="input-group_textarea">
                      <InputGroupAddon className="input-group-addon align-items-baseline App-label App-label-title pr-4 font-weight-bolder "  addonType="prepend"><InputGroupText
                        className="input-group-text">Felületek</InputGroupText></InputGroupAddon>

                      <QueryTable id={'appearance-single'} headers={
                    [
                      {
                        name: 'Kód',
                        processor: object => object.code,
                        className: "Table-ID",
                        orderName: "code"
                      }, {
                      name: 'Címszó',
                      processor: object => object.keyword.keyword,
                      className: "Table-invoice",
                      orderName: "keyword__keyword"
                    }, {
                      name: 'Nettó Ár',
                      processor: object => object.net_price ? object.net_price.toLocaleString("hu-HU") : 0,
                      className: "Table-summ",
                      orderName: "net_price"
                    }, {
                      name: '',
                      processor: object => <Button className="App-icon-button" size={"sm"}
                                                   onClick={() => this.toggleAppearanceModal(object.id)}><img className="App-icon-button" src={edit}  alt={"Szerk."} data-tip="Szerkesztés"/><ReactTooltip /></Button>,
                      className: "Table-btn",
                    }
                    ]
                  }
                  defaultURL={'/kisokostest/appearances/?contract_item=' + this.props.contractItemEditId}
                  />

                  <Modal className="modal-dialog_wide" id='create-edit-appearance-single' headerName="Felület">
                    <AppearanceFormSingle/>
                  </Modal>



                    </InputGroup>
                      </Col>
                    </Row>
                  </Col>

                  <Col md="7" >
                    <Row>
                      <Col md={12} className="text-right">
                    {this.props.contractItemEditId ? <Button color="primary"
                                                             onClick={() => this.togglePaymentModal()}>Új Befizetés</Button> : ''}
                      </Col>
                    </Row>
                    <Row >
                      <Col md={12} className="text-right">
                    <InputGroup className="input-group_textarea align-content-end">
                      <InputGroupAddon className="input-group-addon align-items-baseline App-label App-label-title pr-4 font-weight-bolder "  addonType="prepend"><InputGroupText
                        className="input-group-text">Befizetések</InputGroupText></InputGroupAddon>

                      <QueryTable className="tbody-200" id={'payments'} headers={
                    [
                    {
                      name: 'Dátum',
                      processor: object => object.payment_date,
                      className: "Table-date",
                      orderName: "payment_date"
                    }, {
                      name: 'Mód',
                      processor: object => object.payment_method.type,
                      className: "Table-method",
                      orderName: "payment_method__type"
                    }, {
                      name: 'Számlaszám',
                      processor: object => object.identifier,
                      className: "Table-invoice",
                      orderName: "identifier"
                    }, {
                      name: 'Pénzt. biz',
                      processor: object => object.certificate,
                      className: "Table-invoice",
                      orderName: "certificate"
                    }, {
                      name: 'Nettó',
                      processor: object => object.sum ? object.sum.toLocaleString("hu-HU") : 0,
                      exportProcessor: object => object.sum ? object.sum : 0,
                      className: "Table-summ",
                      orderName: "sum"
                    }, {
                      name: '',
                      processor: object => <Button className="App-icon-button" size={"sm"}
                                                   onClick={() => this.togglePaymentModal(object.id)}><img className="App-icon-button" src={edit}  alt={"Szerk."} data-tip="Szerkesztés"/><ReactTooltip /></Button>,
                      className: "Table-btn",
                    }
                    ]
                  }
                  defaultURL={'/kisokostest/payments/?contract_item=' + this.props.contractItemEditId}
                  />
                       <Modal className="modal-dialog_mid" id='create-edit-payment' headerName="Befizetés">
                    <PaymentForm/>
                  </Modal>
                    </InputGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row className="justify-content-end">
                  <Col className="text-right">
                    <Button color="primary" onClick={this.createOrUpdate}>Mentés</Button>
                  </Col>
                </Row>
              </FormGroup>

            </Form>

    );
  }
}

const mapStateToProps = (state) => {
  const contractEditId = state.contract.contractId;
  const contractItemEditId = state.contractItem.contractItemId;
  return {
    contractItemEditId,
    contractEditId
  }
};

const mapDispatchToProps = dispatch => ({
  openAppearance: (id) => {
    dispatch(openAppearance(id));
  },
  closeAppearance: () => {
    dispatch(closeAppearance());
  },
  openPayment: (id) => {
    dispatch(openPayment(id));
  },
  closePayment: () => {
    dispatch(closePayment());
  },
  getContractItem: (id) => {
    return dispatch(getRequest('/kisokostest/contractitems/?id=' + id))
  },
  getContract: (id) => {
    return dispatch(getRequest('/kisokostest/contractscalc/?id=' + id))
  },
  getAppearances: (id) => {
    return dispatch(getRequest('/kisokostest/appearances/?contract_item=' + id))
  },
  getPayments: (id) => {
    return dispatch(getRequest('/kisokostest/payments/?contract_item=' + id))
  },
  getProducts: () => {
    let today = new Date();
    return dispatch(getRequest("/kisokostest/productsall/?press_done__gte=" + today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate()))
  },
  getProductsAll: () => {
    return dispatch(getRequest('/kisokostest/productsall/'))
  },
  getSingleProduct: (id) => {
    return dispatch(getRequest('/kisokostest/products/?id=' + id))
  },
  createEntry: (data) => {
    return dispatch(postRequest('/kisokostest/contractitems/', data))
  },
  deleteEntry: (id) => {
    return dispatch(deleteRequest('/kisokostest/contractitems/' + id))
  },
  saveEntry: (id, data) => {
    return dispatch(putRequest('/kisokostest/contractitems/' + id, data))
  },
  setEdited: (id) => {
    dispatch(openContractItem(id))
  },
  requery: () => {
    dispatch(invalidateQuery('contract-items'));
  },
  toggleModal: id => {
    dispatch(toggleModal(id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractItemFormSingle)
