export const ALERT_TOGGLE = 'ALERT_TOGGLE';
export const SET_ALERT = 'SET_ALERT';


export const toggleAlert = () => ({
  type: ALERT_TOGGLE,
});

export const setAlert = (text,color) => ({
  type: SET_ALERT,
  text: text,
  color: color
});


