import {CLOSE_CONTRACT_TRACKING, OPEN_CONTRACT_TRACKING} from "../actions/contractTracking";

const contractTracking = (state= {
  contractTrackingId: undefined,
}, action) =>{
  switch (action.type) {
    case OPEN_CONTRACT_TRACKING:
      return{
        ...state,
        contractTrackingId: action.contractTrackingId,
      };
    case CLOSE_CONTRACT_TRACKING:
      return{
        ...state,
        contractTrackingId: undefined,
      };
    default:
      return state;
  }
};

export default contractTracking;
