export const OPEN_APPEARANCE = 'OPEN_APPEARANCE';
export const CLOSE_APPEARANCE = 'CLOSE_APPEARANCE';

export const openAppearance = (id) => ({
  type: OPEN_APPEARANCE,
  appearanceId: id
});

export const closeAppearance = () => ({
  type: CLOSE_APPEARANCE
});
