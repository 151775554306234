import { Button} from "reactstrap";
import React, { Component } from "react";
//import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar } from "recharts";
import { addQuery, invalidateQuery } from "../actions/query";
import { connect } from "react-redux";
import { getRequest, getRequestBlob, putRequest, postRequest, deleteRequest } from "../actions/api";
import { closeClient, openClient } from "../actions/client";
import { addModal, toggleModal } from '../actions/modal.js';
import { saveFile } from "../Util";
import moment from 'moment';

const API_ROOT_URL = "/kisokostest"

const PaymentType = {
  TRANSFER: 1,
  CASH: 2
};

class TestPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      apiResponse: null,
      // Initially, no file is selected 
      selectedFile: null,
      files: [],
      statistics: null,
      contractNumber: '',
      contractId: '',
      invoiceNumber: 'KMKP-2021-1',
      selectedDay: moment().format('YYYY-MM-DD')
    };
  }

  getReportsByCashPayment = () => {
    this.setState({ isFetching: true })
    
    let weekNumber = 21
    let urlEnding = `type=${PaymentType.CASH}&week=${weekNumber}`

    this.props.getReportsByCashPayment(urlEnding).then(response => {
      console.log('getReportsByWeek', response.data);
    })
  }

  getSendReportInEmail = () => {    
    this.props.getSendReportInEmail().then(response => {
      console.log('getSendReportInEmail', response.data);
    })
  }

  getCashInvoice = () => {    
    this.props.getCashInvoice(this.state.invoiceNumber).then(response => {
      console.log(response.data.details.invoiceNumber);
      response.data.details.items.forEach(item => {
        console.log(item.contractAndProduct, item.netPrice);
      })
    })
  }

  updateActive = (editObject = undefined) => {
    if (editObject !== undefined) {
      editObject.inactive = !editObject.inactive;
      this.props.updateClient(editObject);
    }
  };

  handleApiResponse = (data) => {
    console.log('handleApiResponse', data);
    this.setState({ apiResponse: data })
  }

  toggleEditModal = (object = undefined) => {
    if (object !== undefined) {
      this.props.openClient(object.id)
    }
    else {
      this.props.openClient()
    }

    this.props.toggleModal('client-edit');
  };

  toggleRangeModal = (object = undefined) => {
    if (object !== undefined) {
      this.props.openClient(object.id)
    }
    else {
      this.props.openClient()
    }

    this.props.toggleModal('payments-export');
  };

  isSame = (object = undefined) => {
    return (object.shipping_address.address === object.billing_address.address &&
      object.shipping_address.postcode === object.billing_address.postcode &&
      object.shipping_address.city === object.billing_address.city &&
      (object.mailing_name === null || object.mailing_name === ''))
  };

  queryPayments = (startDate, endDate) => {
    this.props.getPayment(this.props.editClientId, startDate, endDate)
      .then(response => {
        const csvArray = [];
        const rawArray = response.data;

        csvArray.push('Szerződésszám;Szerződés dátuma;Ügyfél;Kiadvány;Összeg;Befizetés dátuma;Számlaszám');
        rawArray.forEach(row => {
          csvArray.push(row.join(';'));
        });
        saveFile('Befizetesek.csv', csvArray.join('\n'));
      });
  };

  updateFile = () => {
    const objectToUpdate = {
      fileId: 10,
      destinationType: 'appearance',
      destinationId: 5173655
    }
    console.log('updateFile objectToUpdate', objectToUpdate);
    this.props.updateFile(objectToUpdate.fileId, objectToUpdate).then(response => {
      console.log('updateFile response', response);
    })
  }

  getFile = (id, fileName) => {
    console.log('getFile fileId = ', id, ' name = ', fileName);
    this.props.getFileById(id).then(response => {
      console.log(`response for ${id}:`, response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName + '.' + response.data.type);
      document.body.appendChild(link);
      link.click();
    })
  }

  deleteClientFile = () => {
    const objectToUpdate = {
      fileId: 6,
      destinationType: 'client',
      destinationId: 5012386
    }
    console.log('deleteClientFile objectToUpdate', objectToUpdate);
    this.props.deleteClientFile(objectToUpdate).then(response => {
      console.log('deleteClientFile response', response);
    })
  }

  searchClients = () => {
    const query = {
      client_name: 'Ablak',
      tax_number: '2534584',
      id: '305612'
    }
    console.log('searchClients query', query);
    this.props.searchClients(query.id).then(response => {
      console.log('searchClients response', response);
    })
  }

  getContractDetails = () => {
    const query = {
      // clientId: 5009528
      // contracId: 5038419
      id: '5012394'
    }
    console.log('getContractDetails query', query);
    this.props.getContractDetails(query.id).then(response => {
      console.log('getContractDetails response', response.data);
      console.log('products', response.data.contracts.length > 0 && response.data.contracts[0].products);
      response.data.contracts.forEach(contract => {
        if(contract.products.length > 0) {
          contract.products.forEach(product => {
            if(product.product.includes("TESZT")) {
              console.log('Tested contract', contract);
            }
          })
        }
        
      })
    })
  }


  getClientFiles = () => {
    console.log('getClientFiles');
    this.props.getClientFiles(305612).then(response => {
      console.log('getClientFiles response', response.data);
    })
  }

  getContractFiles = () => {
    console.log('getContractFiles');
    this.props.getContractFiles(5042155).then(response => {
      console.log('getContractFiles response', response.data);
    })
  }

  getAppearanceFiles = () => {
    console.log('getAppearanceFiles');
    this.props.getAppearanceFiles(5173655).then(response => {
      console.log('getAppearanceFiles response', response.data);
    })
  }

  createContractFromApplication = () => {
    const dataToSend = { // remote: 5012403 local: 5012394
      "clientId": 5012394,
      "contractDate": "2020.07.01",
      "processDate": "2020-07-01",
      "paymentMethodId": 2,
      "comment": "",
      "salesPerson": { id: 5000202 }, // remote: 5000214 local: 5000202
      "signatureId": 21, // remote: 11, local: 21
      "fileIds": [
        123,
        456,
        789
      ],
      "appearances": [
        {
          "company": "zuzu",
          "content": '<p><b>félkövér</b></p><p><font color="#ff0000">kék</font></p>',
          "keywordId": 300005,
          "listPrice": 10000,
          "netPrice": 7500,
          "discount": 0.25,
          "productId": 5000920,
          "surfaceId": 1,
          'kommentbox': 'kommentbox1'
        },
        {
          "company": "zuzu1",
          "content": "asd1",
          "keywordId": 300005,
          "listPrice": 5000,
          "netPrice": 3000,
          "discount": 0.5,
          "productId": 5000920,
          "surfaceId": 3,
          'kommentbox': 'kommentbox2'
        }
      ]
    }

    //const adaBug = {"clientId":5012401,"contractDate":"2020-08-27","processDate":"2020-08-27","paymentMethodId":6,"comment":"","salesPerson":{"id":5000111},"appearances":[{"productId":5000615,"surfaceId":5000039,"keywordId":302557,"netPrice":500,"content":"<p>adfsfg</p>","company":"EN-CO Software Kft.","listPrice":0,"discount":0.0,"kommentbox":""}],"signatureId":283}

    console.log('createContractFromApplication');
    this.props.createContractFromApplication(dataToSend).then(response => {
      console.log('createContractFromApplication response', response);
    })
  }


  postInvoice = () => {
    const dataToSend = {
      'appearances': [
        { id: 5209575, advance: 1000.3 },
        { id: 5209589, advance: 1000.7 },
        { id: 5209588, advance: 1000.5 }
      ],
      'type': 'advance'
    }

    console.log('postInvoice', dataToSend.type);
    dataToSend.appearances.forEach(a => {
      console.log(a);
    })
    this.props.postInvoice(dataToSend).then(response => {
      console.log('postInvoice response', response.data);
    })
  }

  filterInvoice = () => {
    console.log('filterInvoice');
    this.props.filterInvoice().then(response => {
      console.log('filterInvoice response', response.data);
    })
  }

  filterFiles = () => {
    console.log('filterFiles');
    this.props.filterFiles().then(response => {
      console.log('filterFiles response', response.data);
    })
  }

  searchClientByTaxNumber = () => {
    console.log('searchClientByTaxNumber');
    this.props.searchClientByTaxNumber('26394202-0-24').then(response => {
      console.log('searchClientByTaxNumber response', response);
    })
  }

  forgotPassword = () => {
    const dataToSend = {
      email: 'sebok.bence@puredev.hu'
    }

    console.log('forgotPassword');
    this.props.forgotPassword(dataToSend).then(response => {
      console.log('forgotPassword response', response);
    })
  }

  searchCegjelzo = () => {
    console.log('searchCegjelzo');
    // puredev: 27897021-1-42
    // thinkthings: 26396572141
    this.props.searchCegjelzo('69828270').then(response => {
      console.log('searchCegjelzo response', response.data.results[0]);
    })
  }

  checkTaxNumberStatus = () => {
    console.log('checkTaxNumberStatus');
    /*
    VALID:
    Pure Development Bt.: 27897021-1-42
    Think Things Bt.: 26396572141
    Müller György Óvoda és Általános Iskola  18156302142  
    Humánus Alapítványi Általános Iskola 18084733143
    Dr. Biczó András 50234165143
    Budapest Főváros III. kerület Óbuda-Békásmegyer Önkormányzat  15735667241
    INVALID:
    Bencze Gábor: 62746364142
    Stop-Építő Kft.: 26363606242
    EV: 69828270
    */
    this.props.checkTaxNumberStatus('25362523-2-43').then(response => {
      console.log('checkTaxNumberStatus response', response.data);
    })
  }

  queryNavApi = () => {
    console.log('queryNavApi');
    this.props.queryNavApi('15735667241').then(response => {
      console.log('queryNavApi response', response.data);
    })
  }

  getProductsAll = () => {
    this.props.getProductsAll().then(response => {
      console.log('getProductsAll ', response.data.length, response.data);
      response.data/*.filter(product => product.code.includes('211'))*/.forEach(product => {
        console.log('product: ', product.code);
      })
    })
  }

  newPassword = () => {
    const dataToSend = {
      email: 'sebok.bence@puredev.hu',
      password: 'passwordfortesting',
      secret_code: '37BLOHbc5JHQqUK1'
    }

    console.log('newPassword');
    this.props.newPassword(dataToSend).then(response => {
      console.log('newPassword response', response);
    })
  }

  createNewClientFromApplication = () => {
    const query = { "billing_address": { "address": "Valahol", "postcode": "5000", "city": "Szolnok" }, "shipping_address": { "address": "", "postcode": "", "city": "" }, "client_name": "DÁ", "contact_name": "SB", "phone_number1": "202219165", "phone_number2": "202219165", "tax_number": "123456789", "email_address": "95david.adam@gmail.com", "cegjegyzekszam": "123456789", "egyenvallnyilvszam": "", "szamlakuldesiemail": "95david.adam@gmail.com", "salesperson": { "id": 300201 } }
    console.log('getContractDetails query', query);
    this.props.createNewClientFromApplication(query).then(response => {
      console.log('getContractDetails response', response.data);
    })
  }

  getSzamlaAgentXMLFile = () => {
    //const query = { "billing_address": { "address": "Valahol", "postcode": "5000", "city": "Szolnok" }, "shipping_address": { "address": "", "postcode": "", "city": "" }, "client_name": "DÁ", "contact_name": "SB", "phone_number1": "202219165", "phone_number2": "", "tax_number": "123456789", "email_address": "95david.adam@gmail.com", "cegjegyzekszam": "123456789", "egyenvallnyilvszam": "", "szamlakuldesiemail": "", "salesperson": { "id": 300201 } }
    console.log('getSzamlaAgentXMLFile query');
    this.props.getSzamlaAgentXMLFile().then(response => {
      console.log('getContractDetails response', response.data);
    })
  }

  sendContractEmails = () => {
    const contractId = 5043047
    const emailList = [
      { name: 'Sebők Bence', email: 'sebok.bence@puredev.hu' },
    ]
    const sendContractToEmail = {
      'contractId': contractId,
      'emailAddresses': emailList
    }

    this.props.sendContractEmails(sendContractToEmail).then(response => {
      console.log('sendContractEmails', response);
    })
  }


  createContractFromApplicationWithDraft = () => {
    /*const adaError = {
      "clientId":5012401,"contractDate":"2020-08-17","processDate":"2020-08-17","paymentMethodId":1,"comment":"","salesPerson":{"id":1},"appearances":[{"productId":5000909,"surfaceId":5211200,"keywordId":5002557,"netPrice":1000,"content":"","company":"EN-CO Software Kft.","listPrice":null,"discount":null,"kommentbox":""}],"signatureId":224,"isDraft":false
    }*/
    const newContractOrDraft = {
      "isDraft": true, // isDraft is true or false
      "contractId": 5042813, // contractId is a valid id received from a previous draft save or it is not present, when it is a new draft or final contract
      "clientId": 5012394,
      "contractDate": "2020.07.01", // it is not present for a draft, we save this field only for finalized contracts
      "processDate": "2020-07-01", // it is not present for a draft, we save this field only for finalized contracts
      "paymentMethodId": 2,
      "comment": "",
      "salesPerson": { id: 5000202 },
      "signatureId": 21, // it is not present for a draft, we save this field only for finalized contracts
      "appearances": [
        {
          "appearanceId": 52125930,
          // appearance id is NOT present, then the backend saves it (it is a new appearance)
          "company": "MEGLEVO",
          "content": 'NEW APPEARANCE 5000920 NOT TO DELETE',
          "keywordId": 300005,
          "listPrice": 5000,
          "netPrice": 4500,
          "discount": 0.1,
          "productId": 5000920,
          "surface": {
            "id": 1,
            "code": 'K/8'
          },
          'kommentbox': 'nov29'
        },
        {
          // appearance id is NOT present, then the backend saves it (it is a new appearance)
          //"appearanceId": 52125931,
          "company": "UJ",
          "content": 'NEW APPEARANCE 5000927 IS NEW AFTER UPDATE',
          "keywordId": 300005,
          "listPrice": 10000,
          "netPrice": 666,
          "discount": 0.25,
          "productId": 5000927,
          "surface": {
            "id": 3,
            "code": 'K/4'
          },
          'kommentbox': 'nov29-2'
        },
        // {
        //   "appearanceId": 123456789, // appearance id is present, then it is already saved
        //   "company": "zuzu1",
        //   "content": "SAVED APPEARANCE 5000920",
        //   "keywordId": 300005,
        //   "listPrice": 5000,
        //   "netPrice": 3000,
        //   "discount": 0.5,
        //   "productId": 5000920,
        //   "surface": {
        //     "id": 5000039,
        //     "code": 'K/24'
        //   },
        //   'kommentbox': 'kommentbox2',
        //   'contract_item_id': 5180412
        // }
      ],
    }

    /*
    const adaBug = {
      "clientId": 5012402,
      "contractDate": "2020-08-19",
      "processDate": "2020-08-19",
      "paymentMethodId": 1,
      "comment": "megjegyzes",
      "salesPerson": {
        "id": 5000214
      },
      "appearances": [
        {
          "productId": 5000909,
          "surface": {
            id: 19,
            code: 'A-U'
          },
          "keywordId": 303766,
          "appearanceId": null,
          "contractItemId": null,
          "netPrice": 1999,
          "content": "<p>Mivan mar?!</p>",
          "company": "Supercharge Kft.",
          "listPrice": 5000,
          "discount": 0.5,
          "kommentbox": ""
        },
        {
          "productId": 5000799,
          "surface": {
            id: 19,
            code: 'A-U'
          },
          "keywordId": 303766,
          "appearanceId": null,
          "contractItemId": null,
          "netPrice": 1999,
          "content": "<p>Mivan mar?!</p>",
          "company": "Supercharge Kft.",
          "listPrice": 10000,
          "discount": 0.76,
          "kommentbox": ""
        }
      ],
      "signatureId": 240,
      "isDraft": false
    }
    */

    console.log('createContractFromApplicationWithDraft');
    this.props.createContractFromApplicationWithDraft(newContractOrDraft).then(response => {
      console.log('createContractFromApplicationWithDraft response', response.data);
    })
  }

  queryStatistics = () => {
    this.props.queryStatistics().then(response => {
      console.log('queryStatistics', response.data);
      this.setState({ statistics: response.data })
      console.log('queryStatistics', response.data );
      
    })
  }

  excelExportSzamlazzHu = () => {
    this.props.excelExportSzamlazzHu().then(response => {
      console.log('excelExportSzamlazzHu', response.data);
    })
  }

  contractTrackingList = () => {
    this.props.contractTrackingList().then(response => {
      console.log('contractTrackingList', response.data);
    })
  }

  contractTrackingListGet = () => {
    const tracking_id = 1
    this.props.contractTrackingListGet(tracking_id).then(response => {
      console.log(`contractTrackingListGet id = ${tracking_id}:`, response.data);
    })
  } 

  contractTrackingListPut = () => {
    const tracking_id = 1
    this.props.contractTrackingListGet(2).then(response => {
      console.log(`contractTrackingListGet id = ${tracking_id}:`, response.data);
      // Update the queried item
      const updatingThisItem = response.data
      updatingThisItem.tordelo = `MODIFIED_TORDELO_${updatingThisItem.id}`
      // Send the PUT request
      this.props.contractTrackingListPut(tracking_id, updatingThisItem).then(response => {
        console.log(`contractTrackingListPut id = ${tracking_id} with`, updatingThisItem, ' ', response.data);
      })
    })
  }

  emailLog = () => {
    this.props.emailLog().then(response => {
      console.log(`emailLog`, response.data);
      response.data.forEach(product => {
        if(product.code === 'B18-212' || product.id === 5000935 || product.code === "B10-212" || product.id === 5000933)
        {
          console.log('found', product);
          
        }
      })
    })
  } 

  testResportsEndpoint = () => {
    this.props.testResportsEndpoint().then(response => {
      console.log(`testResportsEndpoint`, response.data);
    })
  } 

  testUserProfileEndpoint = () => {
    this.props.testUserProfileEndpoint().then(response => {
      const user = response.data
      if(user.username.includes('sebok.bence') && user.isAdmin !== true) {
        console.error("sebok.bence@puredev.hu is NOT admin?!");
      }
      console.log(`testUserProfileEndpoint`, response.data);
    })
  }

  getContractByContractNumber = (cn) => {
    this.props.getContractByContractNumber(cn).then(response => {
      console.log(`getContractByContractNumber`, response.data);
      if(response.data.status === 'success') {
        console.log('response.data.contract.contract.id', response.data.contract.contract.id);
        this.setState({ contractId: response.data.contract.contract.id })
      }
    })
  }

  deleteContractById = (cn) => {
    this.props.deleteContractById(cn).then(response => {
      console.log(`deleteContractById`, response.data);
    })
  }

  generateDailyReportsForSalespersons = () => {
    const urlParamters = `type=daily-salespersons&day=${this.state.selectedDay}`
    this.props.generateDailyReportsForSalespersons(urlParamters).then(response => {
      this.setState({ resultsFromAPI: response.data, isFetched: true, isFetching: false })
    })
  }

  getInvoicePdf = () => {
    this.props.getInvoicePdf(this.state.invoiceNumber).then(response => {
      console.log('getInvoicePdf', response.data);
    })
  }

  getKeywords = () => {    
    this.props.getKeywords(false).then(response => {
      console.log('getKeywords', response.data);
    })
  }

  getRefactoredInvoices = () => {    
    this.props.getRefactoredInvoices(false).then(response => {
      console.log('getRefactoredInvoices', response.data);
    })
  }

  sendEmailWithSMTP = () => {
    const urlParameters = ''
    this.props.sendEmailWithSMTP(urlParameters).then(response => {
      console.log('sendEmailWithSMTP', response.data);
    })
  }

  render() {    
/*
    let stat = this.state.statistics ? (<div>
                  <h2>Weekly statistics</h2>
                  <BarChart
                    width={1600}
                    height={300}
                    data={this.state.statistics.weeklyContracts}
                    margin={{
                      top: 5, right: 30, left: 20, bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="week" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="contracts" fill="#8884d8" />
                  </BarChart>
                  <h2>Monthly statistics</h2>
                  <BarChart
                    width={500}
                    height={300}
                    data={this.state.statistics.monthlyContracts}
                    margin={{
                      top: 5, right: 30, left: 20, bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="contracts" fill="#8884d8" />
                  </BarChart>
                  </div>) : null
*/
    return (
      <div>
        <div style={{ marginBottom: 20 }}>
          <h1>Piszkozat</h1>
          <Button onClick={this.createContractFromApplicationWithDraft}>Piszkozat</Button>
        </div>
        <ul className="test-buttons">
          <li><Button onClick={this.sendEmailWithSMTP}>sendEmailWithSMTP</Button></li>
          <li><Button onClick={this.getKeywords}>getKeywords</Button></li>
          <li><Button onClick={this.getRefactoredInvoices}>getRefactoredInvoices</Button></li>
          <li><Button outline color="primary" onClick = {this.getCashInvoice}>Készpénzes számla lekérdezése</Button></li>
          <li><Button outline color="primary" onClick = {this.getSendReportInEmail}>Jelentés küldése emailben</Button></li>
          <li><Button outline color="primary" onClick = {this.getSendReportInEmail}>Jelentés küldése emailben</Button></li>
          <li>
            <input
              value = {this.state.contractNumber}
              placeholder = "Szerződés száma"
              onChange = {(event) => this.setState({ contractNumber: event.target.value })}
            />
            <Button outline color="primary" onClick = {() => this.getContractByContractNumber(this.state.contractNumber)}>Szerződés keresése</Button>
          </li>
          <li>
            <input
              value = {this.state.contractId}
              disabled
            />
            <Button outline color="primary" onClick = {() => this.deleteContractById(this.state.contractId)}>Szerződés törlése</Button>
          </li>
          <li>
            <input
              type="date" 
              value = {this.state.selectedDay} 
              style= {{ width: "10em", paddingLeft: "5px" }}
              onChange = {(event) => this.setState({ selectedDay: event.target.value })} 
              id = "report_date" 
              name = "report_date" 
              min="2000-01-01" 
              max="2025-12-31"
            />
            <Button outline color="danger" onClick = {this.generateDailyReportsForSalespersons}>PRODUCTION daily report for salespersons</Button>
          </li>
          <li>
            <input
              value = {this.state.invoiceNumber}
              placeholder = "Készpénzes számla száma"
              onChange = {(event) => this.setState({ invoiceNumber: event.target.value })}
            />
            <Button outline color="primary" onClick = {this.getCashInvoice}>Készpénzes számla lekérdezése</Button>
            <Button outline color="primary" onClick = {this.getInvoicePdf}>Számla PDF letöltése</Button>
          </li>
          <li><Button outline color="primary" onClick = {this.getSendReportInEmail}>Jelentés küldése emailben</Button></li>
          <li><Button outline color="primary" onClick = {this.getReportsByCashPayment}>Heti készpénzes jelentés</Button></li>
          <li><Button variant="danger" onClick={this.testResportsEndpoint}>testResportsEndpoint</Button></li>
          <li><Button variant="danger" onClick={this.emailLog}>emailLog</Button></li>
          <li><Button variant="danger" onClick={this.contractTrackingList}>contractTrackingList</Button></li>
          <li><Button variant="danger" onClick={this.contractTrackingListGet}>contractTrackingListGet</Button></li>
          <li><Button variant="danger" onClick={this.contractTrackingListPut}>contractTrackingListPut</Button></li>
          <li><Button variant="danger" onClick={this.excelExportSzamlazzHu}>excelExportSzamlazzHu</Button></li>
          <li><Button variant="danger" onClick={this.postInvoice}>postInvoice</Button></li>
          <li><button onClick={this.createContractFromApplication}>createContractFromApplication</button></li>
          <li><Button variant="danger" onClick={this.getProductsAll}>getProductsAll</Button></li>
          <li><Button variant="danger" onClick={this.queryNavApi}>queryNavApi</Button></li>
          <li><button onClick={this.checkTaxNumberStatus}>checkTaxNumberStatus</button></li>
          <li><button onClick={this.filterFiles}>filterFiles</button></li>
          <li><button onClick={this.filterInvoice}>filterInvoice</button></li>
          <li><button onClick={this.getFile}>getFile</button></li>
          <li><button onClick={this.updateFile}>updateFile</button></li>
          <li><button onClick={this.getClientFiles}>getClientFiles</button></li>
          <li><button onClick={this.getContractFiles}>getContractFiles</button></li>
          <li><button onClick={this.getAppearanceFiles}>getAppearanceFiles</button></li>
          <li><button onClick={this.searchClients}>searchClients</button></li>
          <li><button onClick={this.getContractDetails}>getContractDetails</button></li>
          <li><Button variant="danger" onClick={this.createNewClientFromApplication}>createNewClientFromApplication</Button></li>
          <li><Button variant="danger" onClick={this.getSzamlaAgentXMLFile}>getSzamlaAgentXMLFile</Button></li>
          <li><Button variant="danger" onClick={this.forgotPassword}>forgotPassword</Button></li>
          <li><Button variant="danger" onClick={this.newPassword}>newPassword</Button></li>
          <li><Button variant="danger" onClick={this.searchCegjelzo}>searchCegjelzo</Button></li>
          <li><Button variant="danger" onClick={this.deleteClientFile}>deleteClientFile</Button></li>
          <li><Button variant="danger" onClick={this.sendContractEmails}>sendContractEmails</Button></li>
          <li><Button variant="danger" onClick={this.searchClientByTaxNumber}>searchClientByTaxNumber</Button></li>
          <li><Button variant="danger" onClick={this.testUserProfileEndpoint}>testUserProfileEndpoint</Button></li>
        </ul>
        <div style = {{ marginBottom: 100 }}>
          <h1>Statistics</h1>
          <Button variant="danger" onClick={this.queryStatistics}>queryStatistics</Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const editClientId = state.client.clientId;
  return {
    editClientId
  }
};

const mapDispatchToProps = dispatch => ({
  updateClient: (client) => {
    dispatch(putRequest('/kisokostest/clients/' + client.id, client))
  },
  updateFile: (id, data) => {
    return dispatch(putRequest('/kisokostest/files/' + id + '/', data))
  },
  getFile: (id) => {
    return dispatch(getRequest('/kisokostest/files/' + id + '/'))
  },
  getClientFiles: (client_id) => {
    return dispatch(getRequest('/kisokostest/files/client/' + client_id))
  },
  searchClients: (data) => {
    return dispatch(getRequest('/kisokostest/clients/?get_all&id=' + data))
  },
  getContractFiles: (contract_id) => {
    return dispatch(getRequest('/kisokostest/files/contract/' + contract_id))
  },
  getAppearanceFiles: (appearance_id) => {
    return dispatch(getRequest('/kisokostest/files/appearance/' + appearance_id))
  },
  closeClient: () => {
    dispatch(closeClient())
  },
  openClient: (id) => {
    dispatch(openClient(id));
  },
  addQuery: (id, relativeURL) => {
    dispatch(addQuery(id, relativeURL));
  },
  refreshQuery: id => {
    dispatch(invalidateQuery(id));
  },
  addModal: id => {
    dispatch(addModal(id));
  },
  getPayment: (id, startDate, endDate) => {
    return dispatch(getRequest('/kisokostest/paymentsfromclientlist?client_id=' + id + '&start_date=' + startDate + '&end_date=' + endDate));
  },
  toggleModal: id => {
    dispatch(toggleModal(id));
  },
  getFileById: (fileId) => {
    return dispatch(getRequestBlob('/kisokostest/files/' + fileId))
  },
  getAllFiles: () => {
    return dispatch(getRequest('/kisokostest/files/all'))
  },
  getProfile: () => {
    return dispatch(getRequest('/kisokostest/user/profile/'))
  },
  sendContractEmails: data => {
    return dispatch(postRequest('/kisokostest/email/send/contract/', data))
  },
  activateClient: (client_id) => {
    return dispatch(postRequest('/kisokostest/clients/activate/' + client_id))
  },
  getContractDetails: (contract_id) => {
    return dispatch(getRequest('/kisokostest/contracts/details/' + contract_id))
  },
  createNewClientFromApplication: (data) => {
    return dispatch(postRequest('/kisokostest/clients/new/', data))
  },
  createContractFromApplication: (data) => {
    return dispatch(postRequest('/kisokostest/contracts/new/', data))
  },
  createContractFromApplicationWithDraft: (data) => {
    return dispatch(postRequest('/kisokostest/contracts/draft/', data))
  },
  getSzamlaAgentXMLFile: () => {
    return dispatch(getRequest('/kisokostest/szamlaagent/xml'))
  },
  filterInvoice: () => {
    return dispatch(getRequest('/kisokostest/invoice/?dateAfter=2020-01-01&dateBefore=2020-12-01&type=advance&contractId=5043037'))
  },
  filterFiles: () => {
    return dispatch(getRequest('/kisokostest/files/all?dateAfter=2020-07-05&dateBefore=2020-12-01&fileType=pdf&type=SZ&contractNumber=SB136754'))
  },
  postInvoice: (data) => {
    return dispatch(postRequest('/kisokostest/invoice/', data))
  },
  forgotPassword: (data) => {
    return dispatch(postRequest('/kisokostest/user/forgotpassword/', data))
  },
  newPassword: (data) => {
    return dispatch(postRequest('/kisokostest/user/password/new/', data))
  },
  searchCegjelzo: (tax_number) => {
    return dispatch(getRequest('/kisokostest/cegjelzo?adoszam=' + tax_number))
  },
  checkTaxNumberStatus: (tax_number) => {
    return dispatch(getRequest(`/kisokostest/tax-number/${tax_number}/check`))
  },
  deleteClientFile: (clientFile) => {
    return dispatch(deleteRequest('/kisokostest/files/' + clientFile.fileId + '/', clientFile))
  },
  searchClientByTaxNumber: (tax_number) => {
    return dispatch(getRequest('/kisokostest/clients?get_all=true&tax_number=' + tax_number))
  },
  queryNavApi: (tax_number) => {
    return dispatch(getRequest(`/kisokostest/nav/tax-number/${tax_number}`))
  },
  getProductsAll: () => {
    return dispatch(getRequest(`/kisokostest/productsall/?filter=open&code=211`))
  },
  queryStatistics: () => {
    return dispatch(getRequest(`/kisokostest/application/statistics`))
  },
  excelExportSzamlazzHu: () => {
    return dispatch(getRequest(`/kisokostest/export/szamlazz-hu?product_id=5000920`))
  },
  contractTrackingList: () => {
    return dispatch(postRequest(`/kisokostest/contracts/tracking`))
  },
  contractTrackingListGet: (tracking_id) => {
    return dispatch(getRequest(`/kisokostest/contracts/tracking/${tracking_id}/edit`))
  },
  contractTrackingListPut: (tracking_id, tracking_item) => {
    return dispatch(putRequest(`/kisokostest/contracts/tracking/${tracking_id}/edit`, tracking_item))
  },
  emailLog: () => {
    return dispatch(getRequest(`/kisokostest/productsall/?filter=open`))
  },
  testResportsEndpoint: () => {
    return dispatch(getRequest(`/kisokostest/reports?week=42`))
  },
  testUserProfileEndpoint: () => {
    return dispatch(getRequest(`${API_ROOT_URL}/user/profile/`))
  },
  getReportsByCashPayment: url => {
    return dispatch(getRequest(`/kisokostest/reports/payment?${url}`))
  },
  getSendReportInEmail: url => {
    return dispatch(getRequest(`/kisokostest/reports/send`))
  },
  getCashInvoice: invoiceNumber => {
    return dispatch(getRequest(`/kisokostest/invoice/cash?invoiceNumber=${invoiceNumber}`))
  },
  getInvoicePdf: invoiceNumber => {
    return dispatch(getRequest(`/kisokostest/invoice/pdf?invoiceNumber=${invoiceNumber}`))
  },
  getContractByContractNumber: (cn) => {
    return dispatch(getRequest(`/kisokostest/admin-contract?contractNumber=${cn}`))
  },
  deleteContractById: (id) => {
    return dispatch(deleteRequest(`/kisokostest/admin-contract/${id}`))
  },
  generateDailyReportsForSalespersons: (parameterizedUrlEndig) => {
    return dispatch(getRequest(`/kisokostest/reports?${parameterizedUrlEndig}`));
  },
  getKeywords: (active) => {
    const url = active ? '/kisokostest/keywords/?web=true' : '/kisokostest/keywords/'
    return dispatch(getRequest(url));
  },
  getRefactoredInvoices: () => {
    return dispatch(getRequest('/kisokostest/invoices-new'));
  },
  sendEmailWithSMTP: (urlParameters) => {
    return dispatch(getRequest('/kisokostest/email/smtp/send?'+urlParameters));
  }  
});

export default connect(mapStateToProps, mapDispatchToProps)(TestPage);

