import React, {Component} from 'react';
import {connect} from "react-redux";
import {deleteRequest, getRequest, postRequest, putRequest} from "../actions/api";
import {invalidateQuery} from "../actions/query";
import {toggleModal} from "../actions/modal";
import {openAppearance} from "../actions/appearance";
import {Button, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row} from "reactstrap";
import {getPropertyOrEmptyString, getPropertyOrZero, vatkey} from "../Util";

class PaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contract_item_id: 0,
      client_name: '',
      client_id: 0,
      contract_number: '',
      contract_date: '',
      product_code: '',
      spread_date: '',
      payment_method_id: 0,
      payment_method_type: '',
      payment_date: '',
      invoice_date: '',
      payment_deadline: '',
      identifier: '',
      certificate: '',
      sum: 0,
      gross: 0,
      diary_page_number: 0,
      vat: 0,
      comment: '',
      paymentMethods: [],
      net_price_calc: 0,
      paid: 0,
      remdebt: 0
    };
  }

  getPaymentObject(id) {
    this.props.getPayment(id).then(response => {
      this.setState({
        payment_method_id: getPropertyOrZero(response.data.results[0], 'payment_method.id'),
        payment_method_type: getPropertyOrEmptyString(response.data.results[0], 'payment_method.type'),
        payment_date: getPropertyOrEmptyString(response.data.results[0], 'payment_date'),
        invoice_date: getPropertyOrEmptyString(response.data.results[0], 'invoice_date'),
        payment_deadline: getPropertyOrEmptyString(response.data.results[0], 'payment_deadline'),
        identifier: getPropertyOrEmptyString(response.data.results[0], 'identifier'),
        certificate: getPropertyOrEmptyString(response.data.results[0], 'certificate'),
        sum: getPropertyOrZero(response.data.results[0], 'sum'),
        gross: Math.round(getPropertyOrZero(response.data.results[0], 'sum') * vatkey),
        diary_page_number: getPropertyOrZero(response.data.results[0], 'diary_page_number'),
        vat: Math.round(getPropertyOrZero(response.data.results[0], 'sum') * (vatkey - 1)),
        comment: getPropertyOrEmptyString(response.data.results[0], 'comment'),
      });
    }).catch(function (error) {

    });
  }

  getContractItemObject(id){
    this.props.getContractItem(id).then(response => {
      this.setState({
        contract_item_id: getPropertyOrZero(response.data.results[0], 'id'),
        client_name: getPropertyOrEmptyString(response.data.results[0], 'contract.client.client_name'),
        client_id: getPropertyOrZero(response.data.results[0], 'contract.client.id'),
        contract_number: getPropertyOrEmptyString(response.data.results[0], 'contract.contract_number'),
        contract_date: getPropertyOrEmptyString(response.data.results[0], 'contract.contract_date'),
        product_code: getPropertyOrEmptyString(response.data.results[0], 'product.code'),
        spread_date: getPropertyOrEmptyString(response.data.results[0], 'product.spread_date'),
        identifier: this.state.identifier ? this.state.identifier  : getPropertyOrEmptyString(response.data.results[0], 'final_bill_number'),
        net_price_calc: getPropertyOrZero(response.data.results[0], 'net_price'),
        paid: getPropertyOrZero(response.data.results[0], 'paid'),
        invoice_date: getPropertyOrEmptyString(response.data.results[0], 'product.invoice_date'),
        remdebt:  getPropertyOrZero(response.data.results[0], 'net_price') - getPropertyOrZero(response.data.results[0], 'paid')
      });
    }).catch(function (error) {

    });
  }
  componentDidMount() {
    this.props.getPaymentMethods().then((response) => {
      let paymentMethods = [];
      response.data.map((i) => paymentMethods = [...paymentMethods, i]);
      this.setState({
        paymentMethods: paymentMethods,
        payment_method_id: this.state.payment_method_id ? this.state.payment_method_id : paymentMethods[0].id
      });
    }).catch(function (error) {

    });
    if (this.props.paymentEditId !== undefined) {
      this.getPaymentObject(this.props.paymentEditId);
    }
    if(this.props.contractItemEditId !== undefined){
      this.getContractItemObject(this.props.contractItemEditId);
    }
  }

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => {
      const newState = {...prevState};
      newState[name] = value;
      return newState;
    });
  };

  handleNumbers = e => {
    const name = e.target.name;
    const value = e.target.value;
    if(name === 'sum') {
      if(value >= this.state.remdebt) {
        this.setState(prevState => {
          const newState = {...prevState};
          newState['sum'] = Number(value.toString().replace(/^[0]+/g,""));
          newState['vat'] = Math.round(value * (vatkey - 1));
          newState['gross'] = Math.round( value * vatkey);
          return newState;
        });
      }
    }
    if(name === 'vat') {
      this.setState(prevState => {
        const newState = {...prevState};
        newState['sum'] =Math.round( value / (vatkey -1));
        newState['vat'] = Number(value.toString().replace(/^[0]+/g,""));
        newState['gross'] =Math.round( value / (vatkey -1) * vatkey);
        return newState;
      });
    }
    if(name === 'gross') {
      this.setState(prevState => {
        const newState = {...prevState};
        newState['sum'] = Math.round(value/vatkey);
        newState['vat'] = Math.round(value/vatkey * (vatkey - 1));
        newState['gross'] = Number(value.toString().replace(/^[0]+/g,""));
        return newState;
      });
    }
  };

  handleNetChange = e => {
    const value = e.target.value;
    this.setState(prevState => {
      const newState = {...prevState};
      newState['sum'] = Number(value);
      newState['vat'] = Math.round(value * 0.27);
      newState['gross'] = Math.round( value * 1.27);
      return newState;
    });
  }

  handlePaymentMethod = e => {
    this.handleChange(e);
    this.setState({
      payment_method_id: e.target.value
    });
  };

  stateToObject = () => {
    return ({
      contract_item: {
        id: this.state.contract_item_id,
        contract: {
          contract_date: this.state.contract_date,
          payment_method: {
            id: 0,
          },
          client: {
            billing_address: {},
            shipping_address: {},
            client_type: {},
            client_file: [],
          },
          processor: {
            office: 5,
          },
          salesperson: {
            office: 5,
          },
        },
        product: {
          office: {
            name: '',
          }
        },
      },
      payment_method: {
        id: this.state.payment_method_id
      },
      payment_date: this.state.payment_date,
      invoice_date: this.state.invoice_date,
      payment_deadline: this.state.payment_deadline,
      identifier: this.state.identifier,
      certificate: this.state.certificate,
      sum: this.state.sum,
      diary_page_number: this.state.diary_page_number,
      vat: this.state.vat,
      comment: this.state.comment,
    });
  };

  createOrUpdate = () => {
    if (this.props.paymentEditId !== undefined) {
      this.props.saveEntry(this.props.paymentEditId, this.stateToObject()).then(() => {
        this.props.requery();
        this.props.toggleModal(this.props.modalId);
      });
    }
    else
      this.props.createEntry(this.stateToObject()).then((response) => {
        this.props.setEdited(response.data.id);
        this.props.requery();
        this.props.toggleModal(this.props.modalId);
      }).catch(function (error) {

      });
  };

  deleteEntry = () => {
    if (this.props.paymentEditId !== undefined) {
      this.props.deleteEntry(this.props.paymentEditId).then(() => {
        this.props.requery();
      });
      this.props.toggleModal(this.props.modalId);
    }
  };

  render() {

    // We cannot save the form with invalid data
    const invalidSumValue = (this.state.sum > this.state.remdebt || this.state.sum <= 0)
    const invalidPaymentDate = this.state.payment_date === ''
    const invalidFormData = ((invalidSumValue || invalidPaymentDate) && this.state.remdebt !== 0 )

    return (

      <Form>

        <FormGroup>
          <Row className="mb-3">
            <Col md="6" className="pl-5">

              <InputGroup>
                <InputGroupAddon className="input-group-addon-35" color="primary" addonType="prepend">
                  <InputGroupText
                    className="input-group-text float-right">
                    Ügyfél Azon.
                  </InputGroupText>
                </InputGroupAddon>
                <Input disabled type="text" name="client_id" id="client_id" onChange={this.handleChange}
                  value={this.state.client_id} />
              </InputGroup>

              <InputGroup>
                <InputGroupAddon className="input-group-addon" color="primary" addonType="prepend">
                  <InputGroupText
                    className="input-group-text float-right">
                    Ügyfél
                  </InputGroupText>
                </InputGroupAddon>
                <Input disabled type="text" name="client_name" id="client_name" onChange={this.handleChange}
                  value={this.state.client_name} />
              </InputGroup>

              <InputGroup>
                <InputGroupAddon className="input-group-addon-35" color="primary" addonType="prepend">
                  <InputGroupText
                    className="input-group-text float-right">
                    Szerződés
                  </InputGroupText>
                </InputGroupAddon>
                <Input disabled type="text" name="contract_number" id="contract_number" onChange={this.handleChange}
                  value={this.state.contract_number} />
              </InputGroup>

              <InputGroup>
                <InputGroupAddon className="input-group-addon" color="primary" addonType="prepend">
                  <InputGroupText
                    className="input-group-text float-right">
                    Összes tartozás
                  </InputGroupText>
                </InputGroupAddon>
                <Input disabled type="number" name="debtsum" id="debtsum" onChange={this.handleNumbers}
                  value={this.state.net_price_calc} />
              </InputGroup>
              
              <InputGroup>
                <InputGroupAddon className="input-group-addon" color="primary" addonType="prepend">
                  <InputGroupText
                    className="input-group-text float-right">
                    Ebből fizetve
                  </InputGroupText>
                </InputGroupAddon>
                <Input disabled type="number" name="debtpaid" id="debtpaid" onChange={this.handleNumbers}
                  value={this.state.paid} />
              </InputGroup>

              <InputGroup>
                <InputGroupAddon className="input-group-addon" color="primary" addonType="prepend">
                  <InputGroupText
                    className="input-group-text float-right">
                    Maradék
                  </InputGroupText>
                </InputGroupAddon>
                <Input disabled type="number" name="remdebt" id="remdebt" onChange={this.handleNumbers}
                  value={this.state.remdebt} />
              </InputGroup>

            </Col>

            <Col md="6" className="pl-5">

              <InputGroup>
                <InputGroupAddon className="input-group-addon" color="primary" addonType="prepend">
                  <InputGroupText
                    className="input-group-text float-right">
                    Kiadvány
                  </InputGroupText>
                </InputGroupAddon>
                <Input disabled type="text" name="product_code" id="product_code" onChange={this.handleChange}
                  value={this.state.product_code} />
              </InputGroup>

              <InputGroup className="input-group_textarea">
                <InputGroupAddon className="input-group-addon" addonType="prepend">
                  <InputGroupText
                  className="input-group-text">
                    Komment
                  </InputGroupText>
                </InputGroupAddon>
                <Input className="textarea" type="textarea" name="comment" id="comment" onChange={this.handleChange}
                  value={this.state.comment} />
              </InputGroup>

              <InputGroup>
                <InputGroupAddon className="input-group-addon" color="primary" addonType="prepend">
                  <InputGroupText
                    className="input-group-text float-right">
                    Számlázás dátuma
                  </InputGroupText>
                </InputGroupAddon>
                <Input type="date" min="2000-01-01" max="2025-12-31" name="invoce_date" id="invoce_date" onChange={this.handleChange}
                  value={this.state.invoice_date} />
              </InputGroup>

            </Col>
          </Row>
          <Row>
            <Col md="6" className="pl-5">

              <InputGroup>
                <InputGroupAddon className="input-group-addon" addonType="prepend"><InputGroupText
                  className="input-group-text font-weight-bold">Mód*</InputGroupText></InputGroupAddon>
                <Input type="select" name="payment_method_id" id="payment_method_id" onChange={this.handlePaymentMethod} value={this.state.payment_method_id}>
                  {this.state.paymentMethods.map(i => <option key={i.id} value={i.id}>{i.type}</option>)}
                </Input>
              </InputGroup>

              <InputGroup>
                <InputGroupAddon className="input-group-addon" color="primary" addonType="prepend">
                  <InputGroupText
                    className="input-group-text float-right font-weight-bold">
                    Nettó*
                  </InputGroupText>
                </InputGroupAddon>
                <Input type="tel" name="sum" id="sum" onChange={this.handleNetChange}
                  value={this.state.sum} min="0"
                  disabled = {this.state.remdebt === 0}
                />
              </InputGroup>

              {(invalidSumValue && this.state.remdebt !== 0) && (
                <InputGroup>
                  <span style={{
                    color: '#856404',
                    backgroundColor: '#fff3cd',
                    borderColor: '#ffeeba',
                    padding: ' .75rem 1.25rem',
                    marginBottom: '1rem',
                    border: '1px solid transparent',
                    borderRadius: '.25rem'
                  }}>
                    Érvénytelen adat! A nettó nem lehet negatív, nulla vagy nagyobb a maradéknál!
                  </span>
                </InputGroup>
              )}

              {(this.state.remdebt === 0) && (
                <InputGroup>
                  <span style={{
                    color: '#856404',
                    backgroundColor: '#fff3cd',
                    borderColor: '#ffeeba',
                    padding: ' .75rem 1.25rem',
                    marginBottom: '1rem',
                    border: '1px solid transparent',
                    borderRadius: '.25rem'
                  }}>
                    Nulla maradék esetén nem lehet befizetést rögzíteni!
                  </span>
                </InputGroup>
              )}

              <InputGroup>
                <InputGroupAddon className="input-group-addon" color="primary" addonType="prepend">
                  <InputGroupText
                    className="input-group-text float-right">
                    Áfa
                  </InputGroupText>
                </InputGroupAddon>
                <Input type="tel" name="vat" id="vat" value={this.state.vat} min="0" disabled />
              </InputGroup>

              <InputGroup>
                <InputGroupAddon className="input-group-addon" color="primary" addonType="prepend">
                  <InputGroupText
                    className="input-group-text float-right">
                    Bruttó
                  </InputGroupText>
                </InputGroupAddon>
                <Input type="tel" name="gross" id="gross" value={this.state.gross} min="0" disabled />
              </InputGroup>

            </Col>
            <Col md="6" className="pl-5">

              <InputGroup>
                <InputGroupAddon className="input-group-addon-50" color="primary" addonType="prepend">
                  <InputGroupText
                    className="input-group-text float-right font-weight-bold">
                    Befizetés dátuma*
                  </InputGroupText>
                </InputGroupAddon>
                <Input type="date"
                  name="payment_date"
                  id="payment_date"
                  onChange={this.handleChange}
                  min="2000-01-01"
                  max="2025-12-31"
                  value={this.state.payment_date} />
                </InputGroup>

                {(invalidPaymentDate) && (
                <InputGroup>
                  <span style={{
                    color: '#856404',
                    backgroundColor: '#fff3cd',
                    borderColor: '#ffeeba',
                    padding: ' .75rem 1.25rem',
                    marginBottom: '1rem',
                    border: '1px solid transparent',
                    borderRadius: '.25rem'
                  }}>
                    A befizetés dátumának kitöltése kötelező!
                  </span>
                </InputGroup>
              )}

              <InputGroup>
                <InputGroupAddon className="input-group-addon-35" addonType="prepend">
                  <InputGroupText
                  className="input-group-text">
                    Számlaszám
                  </InputGroupText>
                </InputGroupAddon>
                <Input type="text" name="identifier" id="identifier" onChange={this.handleChange}
                  value={this.state.identifier} />
              </InputGroup>

              <InputGroup>
                <InputGroupAddon className="input-group-addon-35" addonType="prepend">
                  <InputGroupText
                  className="input-group-text">
                    Bef. azonosító
                  </InputGroupText>
                </InputGroupAddon>
                <Input type="text" name="certificate" id="certificate" onChange={this.handleChange}
                  value={this.state.certificate} />
              </InputGroup>

              <InputGroup>
                <InputGroupAddon className="input-group-addon-35" addonType="prepend">
                  <InputGroupText
                  className="input-group-text">
                     sorszám.
                  </InputGroupText>
                </InputGroupAddon>
                <Input type="text" name="diary_page_number" id="diary_page_number" onChange={this.handleChange}
                  value={this.state.diary_page_number} />
              </InputGroup>

            </Col>
          </Row>

          </FormGroup>
          <FormGroup>
            <Row className="justify-content-end">
              <Col className="text-right">
                <Button color="primary" onClick={this.createOrUpdate} disabled={invalidFormData}>Mentés</Button>
              </Col>
            </Row>
          </FormGroup>

        </Form>
    );
  }

}

const mapStateToProps = (state) => {
  const contractEditId = state.contract.contractId;
  const contractItemEditId = state.contractItem.contractItemId;
  const paymentEditId = state.payment.paymentId;
  const clientEditId = state.client.clientId;

  return {
    paymentEditId,
    contractItemEditId,
    contractEditId,
    clientEditId,
  }
};

const mapDispatchToProps = dispatch => ({
  getContractItem: (id) => {
    return dispatch(getRequest('/kisokostest/contractitemscalc/?id=' + id))
  },
  getPayment: (id) => {
    return dispatch(getRequest('/kisokostest/payments/?id=' + id))
  },
  getPaymentMethods: () => {
    return dispatch(getRequest('/kisokostest/paymentmethods/'))
  },
  createEntry: (data) => {
    return dispatch(postRequest('/kisokostest/payments/', data))
  },
  deleteEntry: (id) => {
    return dispatch(deleteRequest('/kisokostest/payments/' + id))
  },
  saveEntry: (id, data) => {
    return dispatch(putRequest('/kisokostest/payments/' + id, data))
  },
  setEdited: (id) => {
    dispatch(openAppearance(id))
  },
  requery: () => {
    dispatch(invalidateQuery('payments'));
  },
  toggleModal: id => {
    dispatch(toggleModal(id));
  }
});

export default connect(mapStateToProps,mapDispatchToProps)(PaymentForm);
