export const OPEN_CONTRACT_ITEM = 'OPEN_CONTRACT_ITEM';
export const CLOSE_CONTRACT_ITEM = 'CLOSE_CONTRACT_ITEM';

export const openContractItem = (id) => ({
  type: OPEN_CONTRACT_ITEM,
  contractItemId: id
});

export const closeContractItem = () => ({
  type: CLOSE_CONTRACT_ITEM
});
