import React, {Component} from 'react';
import {Button, Col, Form, FormGroup, Input, Row} from "reactstrap";
import InputGroup from "reactstrap/es/InputGroup";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import InputGroupText from "reactstrap/es/InputGroupText";
import {deleteRequest, getRequest, postRequest, putRequest} from "../actions/api";
import {openColleague} from "../actions/colleague";
import {invalidateQuery} from "../actions/query";
import {toggleModal} from "../actions/modal";
import {connect} from "react-redux";
import {getPropertyOrEmptyString, getPropertyOrUndefined, getPropertyOrZero, saveFile} from "../Util";
import CommissionRangeForm from "./CommissionRangeForm";
import Modal from "../components/Modal";
import ReactTooltip from "react-tooltip";


class ColleagueForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      phone_number: '',
      email_address: '',
      city: '',
      active: false,
      identification_number: 0,
      debt: 0,
      total_sales: 0,
      comment: '',
      office_name: '',
      office_id: '',
      offices: [],
    };
  }

  getColleagueObject(id){
    this.props.getColleague(id).then(response =>{
      this.setState({
        name: getPropertyOrEmptyString(response.data.results[0], 'name'),
        phone_number: getPropertyOrEmptyString(response.data.results[0], 'phone_number'),
        email_address: getPropertyOrEmptyString(response.data.results[0], 'email_address'),
        city: getPropertyOrEmptyString(response.data.results[0], 'city'),
        active: getPropertyOrZero(response.data.results[0], 'active'),
        identification_number: getPropertyOrZero(response.data.results[0], 'identification_number'),
        debt: getPropertyOrZero(response.data.results[0], 'money_owed').toLocaleString("hu-HU"),
        total_sales: getPropertyOrZero(response.data.results[0], 'net_price').toLocaleString("hu-HU"),
        comment: getPropertyOrEmptyString(response.data.results[0], 'comment'),
        office_id: getPropertyOrUndefined(response.data.results[0], 'office.id'),
        office_name: getPropertyOrUndefined(response.data.results[0], 'office.name'),
      });
    }).catch(function (error) {

    });
  }
  componentDidMount() {
    this.props.getOffices().then((response) => {
      let offices = [];
      response.data.map((i) => offices = [...offices, i]);
      this.setState({
        offices: offices,
        office_id: this.state.office_id ? this.state.office_id : offices[0].id,
        office_name: this.state.office_name ? this.state.office_name : offices[0].name,
      })
    }).catch(function (error) {

    });

    if (this.props.editId !== undefined) {
      this.getColleagueObject(this.props.editId);
    }
  }

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => {
      const newState = {...prevState};
      newState[name] = value;
      return newState;
    });
  };

  handleOffice = e => {
    this.handleChange(e);
    this.setState({
      office_id: e.target.value
    });
  };

  toggleCheckbox = e => {
    const {name} = e.target;
    this.setState(prevState => ({
      ...prevState,
      [name]: !prevState.active
    }));

  };

  stateToObject = () => {
    return ({
      name: this.state.name,
      phone_number: this.state.phone_number,
      email_address: this.state.email_address,
      city: this.state.city,
      active: this.state.active,
      identification_number: this.state.identification_number,
      debt: this.state.debt,
      total_sales: this.state.total_sales,
      comment: this.state.comment,
      office: this.state.office_id
    });
  };

  createOrUpdate = () => {
    if (this.props.editId !== undefined)
      this.props.saveEntry(this.props.editId, this.stateToObject()).then(() => {
        this.props.requery();
        this.props.toggleModal(this.props.modalId);
      });
    else
      this.props.createEntry(this.stateToObject()).then((response) => {
        this.props.setEdited(response.data.id);
        this.props.requery();
        this.props.toggleModal(this.props.modalId);
      }).catch(function (error) {

      });
  };

  deleteEntry = () => {
    if (this.props.editId !== undefined) {
      this.props.deleteEntry(this.props.editId).then(() => {
        this.props.requery();
      });
    }
    this.props.toggleModal(this.props.modalId)
  };

  toggleTrafficModal = () => {
    this.props.toggleModal('traffic-export-colleague');
  };

  toggleSalesModal = () => {
    this.props.toggleModal('sales-export');
  };

  generatePaymentsGroupedByMethodList = startDate => {
    this.props.getTraffic(this.props.editId ? this.props.editId : undefined,startDate).then(response => {
        const csvArray = [];
        const rawArray = response.data;
        const methods = new Set(rawArray.map(row => row[9]));

      csvArray.push('Szerződésszám;Ügyfél;Befizetés dátuma;Kiadvány;Számlaszám;Bizonylatszám;Naplósorszám;Nettó;Bruttó');

        methods.forEach(method => {
          const methodArray = rawArray.filter(row => row[9] === method);
          const methodName = methodArray[0][10];
          csvArray.push(['Fizetési mód', methodName].join(';'));
          let sum = 0;
          methodArray.forEach(row => {
            sum += row[8];
            row.splice(-2);
            csvArray.push(row.join(';'));
          });
          csvArray.push(['Fizetés módja: ' + methodName, sum, sum].join(';'));
          csvArray.push(['db: ' + methodArray.length].join(';'));
        });
        saveFile('BefizetesekModSzerint.csv', csvArray.join('\n'));
      });
  };


  exportSalesByColleague = (start_date) => {
    this.props.exportSalesByColleague(this.props.editId, start_date).then(response => {
      const csvArray = [];
      const rawArray = response.data;

      csvArray.push('Szerződésszám;Rögzítés dátuma;Kelte;Ügyfél;Kapcsolattartó neve;Irányítószám;Város;Cím;Telefonszám;E-mail cím;Kiadvány;Méret;Címszó;Nettó ár;Üzletkötő');

      rawArray.forEach(row => {
        csvArray.push(row.join(';'));
      });

      saveFile('Forgalom.csv', csvArray.join('\n'));
    }).catch(function (error) {

    });
  };


  render() {
    return(
            <Form>



              <FormGroup>
                <Row>
                  <Col md={6} className={"pl-5 "}>
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon" color="primary" addonType="prepend">
                        <InputGroupText className="input-group-text float-right font-weight-bold">
                          Név*
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="text" name="name" id="name" onChange={this.handleChange} value={this.state.name}/>

                          <Input addon className="mt-1 ml-2" type="checkbox" name="active" id="active" onChange={this.toggleCheckbox}
                                 checked={this.state.active} data-tip="Aktív"/><ReactTooltip />

                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon_city" addonType="prepend"><InputGroupText
                        className="input-group-text">Város</InputGroupText></InputGroupAddon>
                      <Input type="text" name="city" id="city" onChange={this.handleChange} value={this.state.city}/>
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon_id" addonType="prepend"><InputGroupText
                        className="input-group-text ">Azonosító</InputGroupText></InputGroupAddon>
                      <Input type="number" name="identification_number" id="identification_number"
                             onChange={this.handleChange} value={this.state.identification_number}/>
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon" addonType="prepend"><InputGroupText
                        className="input-group-text">Telefon</InputGroupText></InputGroupAddon>
                      <Input type="phone" name="phone_number" id="phone_number" onChange={this.handleChange}
                             value={this.state.phone_number}/>
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon" addonType="prepend"><InputGroupText
                        className="input-group-text">E-mail</InputGroupText></InputGroupAddon>
                      <Input type="email" name="email_address" id="email_address" onChange={this.handleChange}
                             value={this.state.email_address}/>
                    </InputGroup>
                  </Col>

                  <Col md="6" className="App-fill-width pl-5 ">
                    <InputGroup >
                      <InputGroupAddon className="input-group-addon" addonType="prepend"><InputGroupText
                        className="input-group-text font-weight-bold">Iroda*</InputGroupText></InputGroupAddon>
                      <Input type="select" name="office_id" id="office_id" onChange={this.handleOffice}
                             value={this.state.office_id}>
                        {this.state.offices.map(i => <option key={i.id} value={i.id}>{i.name}</option>)}
                      </Input>
                    </InputGroup>
                    <InputGroup >
                      <InputGroupAddon className="input-group-addon" addonType="prepend"><InputGroupText
                        className="input-group-text">Tartozás</InputGroupText></InputGroupAddon>
                      <Input disabled className="input-currency" type="text" name="debt" id="debt" onChange={this.handleChange} value={this.state.debt}/>
                    </InputGroup>
                    <InputGroup >
                      <InputGroupAddon className="input-group-addon" addonType="prepend"><InputGroupText
                        className="input-group-text">Sales</InputGroupText></InputGroupAddon>
                      <Input disabled className="input-currency" type="text" name="total_sales" id="total_sales" onChange={this.handleChange}
                             value={this.state.total_sales}/>
                    </InputGroup>
                    <InputGroup className="input-group_textarea">
                      <InputGroupAddon className="input-group-addon" addonType="prepend"><InputGroupText
                        className="input-group-text">Komment</InputGroupText></InputGroupAddon>
                      <Input className="textarea" type="textarea" name="comment" id="comment" onChange={this.handleChange}
                             value={this.state.comment}/>
                    </InputGroup>

                    <Modal className="modal-dialog_mid" id='traffic-export-colleague' headerName="Jutalék Export (Befizetés dátum)">
                        <CommissionRangeForm handleSubmit={this.generatePaymentsGroupedByMethodList}/>
                    </Modal>

                    <Modal  className="modal-dialog_mid" id='sales-export' headerName="Forgalom Export (Nyomdába adás)">
                      <CommissionRangeForm handleSubmit={this.exportSalesByColleague} onlyStartDate = {true} />
                    </Modal>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row className="justify-content-end">
                  <Col md="4">
                    <Button color="primary" onClick={this.toggleTrafficModal}>Befizetések</Button>
                  </Col>
                  <Col className="col-4 justify-content-md-center text-center">
                    {this.props.editId ? <Button  outline onClick={() => this.toggleSalesModal()}>Forgalom</Button> : ''}

                  </Col>


                  <Col className="col-4 justify-content-md-end text-right">
                    <Button color="primary" onClick={this.createOrUpdate}>Mentés</Button>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
    )
  }
}

const mapStateToProps = (state) => {
  const editId = state.colleague.colleagueId;
  return{
    editId
  }
};

const mapDispatchToProps = dispatch => ({
  getColleague: (id) =>{
    return dispatch(getRequest('/kisokostest/colleaguescalc/?id=' +id))
  },
  getOffices: () =>{
    return dispatch(getRequest('/kisokostest/offices/'))
  },
  getTraffic: (id,startDate)=>{
    return dispatch(getRequest('/kisokostest/paymentsgroupedbymethod?colleague_id=' + id
      + '&start_date=' + startDate))
  },
  createEntry: (data) => {
    return dispatch(postRequest('/kisokostest/colleagues/',data))
  },
  deleteEntry: (id) =>{
    return dispatch(deleteRequest('/kisokostest/colleagues/' + id))
  },
  saveEntry: (id,data) =>{
    return dispatch(putRequest('/kisokostest/colleagues/' +id,data))
  },
  setEdited: (id) =>{
    dispatch(openColleague(id))
  },
  requery: () =>{
    dispatch(invalidateQuery('colleagues'))
  },
  toggleModal: id => {
    dispatch(toggleModal(id));
  },
  exportSalesByColleague: (id,start_date) => {
    return dispatch(getRequest('/kisokostest/exportSalesByColleague?colleague_id=' + id + '&start_date='+start_date));
  }
});

export default connect(mapStateToProps,mapDispatchToProps)(ColleagueForm);

