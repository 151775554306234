import React from 'react';
import {Alert} from 'reactstrap';
import {toggleAlert} from "../actions/apiresponse";
import {connect} from "react-redux";

class AlertApiError extends React.Component {

  render() {
    return (
      <Alert color={this.props.color} isOpen={this.props.isVisible}>
        {this.props.text}
      </Alert>
    );
  }
}

const mapStateToProps = (state) => {
  const isVisible = state.apierror.isOpen;
  const text = state.apierror.text;
  const color = state.apierror.color;

  return {
    isVisible,
    text,
    color
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleAlert: () => {
    dispatch(toggleAlert());
  }
});

export default connect(mapStateToProps,mapDispatchToProps)(AlertApiError);