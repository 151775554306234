import {CLOSE_CONTRACT, OPEN_CONTRACT} from "../actions/contract";

const contract = (state= {
  contractId: undefined,
}, action) =>{
  switch (action.type) {
    case OPEN_CONTRACT:
      return{
        ...state,
        contractId: action.contractId,
      };
    case CLOSE_CONTRACT:
      return{
        ...state,
        contractId: undefined,
      };
    default:
      return state;
  }
};

export default contract;
