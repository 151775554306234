import { Container, Row, Col } from "reactstrap";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getRequest } from "../actions/api";
import SalespersonReports from '../adminDashboard/SalespersonReports'
import { API_ROOT_PATH } from '../actions/api.js'

class AdminDashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  testUserProfileEndpoint = () => {
    this.props.testUserProfileEndpoint().then(response => {
      const user = response.data
      if(user.username.includes('sebok.bence') && user.isAdmin !== true) {
        console.error("sebok.bence@puredev.hu is NOT admin?!");
      }
      console.log(`testUserProfileEndpoint`, response.data);
    })
  }

  render() {    
    return (
        <Container>
            <Row>
                <Col>
                    <h1>Admin Dashboard</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <SalespersonReports />
                </Col>
            </Row>
        </Container>
    );
  }
}

const mapStateToProps = (state) => { return state };

const mapDispatchToProps = dispatch => ({
    testUserProfileEndpoint: () => {
        return dispatch(getRequest(`${API_ROOT_PATH}/user/profile/`))
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);

