import { Button, Col, Form, Input, Row } from "reactstrap"
import React from "react";
import InputGroup from "reactstrap/es/InputGroup";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import InputGroupText from "reactstrap/es/InputGroupText";
import { getRequest } from "../actions/api";
import {connect} from "react-redux";

class FilterFormEmail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      colleague: '',
      recipient: '',
      types: [],
      type: '',
      parameter: '',
      dateBefore: '',
      dateAfter: '',
      status: false
    };
  }

  componentDidMount() {
    this.props.getEmailLogTypes().then((response) => {
      let types = [];
      response.data.map((i) => types = [...types, i.type]);
      this.setState({types: types});
      this.setState({type: types[0] });
    }).catch(function (error) {
      // error catching
    });
  }

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => {
      const newState = {...prevState};
      newState[name] = value;
      return newState;
    });
  };

  handleTypeMethod = e => {
    this.handleChange(e);
  };

  toggleCheckbox = e => {
    const {name} = e.target;
    this.setState(prevState => ({
      ...prevState,
      [name]: !prevState.status
    }));
  };

  render() {
    return (
      <div>
        <Form onSubmit={e => {
          e.preventDefault();

          let value
          if (this.state.dateBefore === "" && this.state.dateAfter !== ""){
            let date = new Date().getDate();        // Current Date
            let month = new Date().getMonth() + 1;  // Current Month
            let year = new Date().getFullYear();    // Current Year
            if(month < 10){
              month = '0' + month;
            }
            value = year + '-' + month + '-' + date;
            this.setState({dateBefore: value.toString()});
          }

          this.props.handleSubmit(this.state);
        }}>
          <Row className="mb-3">
            <Col md={5}>
              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right">
                    Email cím
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  className="input-search" 
                  onChange={this.handleChange}
                  value={this.state.colleague}
                  type="text"
                  name="colleague"
                  id="colleague"
                  placeholder="Munkatárs email címe"/>
              </InputGroup>

              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right">
                    Típus
                  </InputGroupText>
                </InputGroupAddon>
                <Input 
                  onLoad={this.handleTypeMethod}
                  onChange={this.handleTypeMethod}
                  type="select"
                  name="type"
                  id="email_log_type" 
                  value={this.state.type}>
                    {this.state.types.map((i, index) => <option key={index}>{i}</option>)}
                </Input>
              </InputGroup>

              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend">
                    <InputGroupText className="input-group-text float-right">Sikertelen emailek</InputGroupText>
                </InputGroupAddon>
                <Input 
                  type="checkbox" 
                  id="status" 
                  onChange={this.toggleCheckbox}
                  name="status" 
                  addon 
                  className="mt-1"
                  defaultChecked={this.state.status}
                  data-tip= {this.state.status ? "Sikertelen küldések" : "Sikeres küldések"}/>
              </InputGroup>
            </Col>
            
            <Col md={7}>
              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right">
                    Dátum
                  </InputGroupText>
                </InputGroupAddon>
                <Input  
                  onChange={this.handleChange} 
                  value={this.state.dateAfter}  
                  type="date"
                  min="2000-01-01"
                  max="2025-12-31"
                  name="dateAfter"
                  id="dateAfter"
                  placeholder="Dátum nagyobb mint"/>
                -
                <Input 
                  onChange={this.handleChange}
                  value={this.state.dateBefore}
                  type="date"
                  min="2000-01-01"
                  max="2025-12-31"
                  name="dateBefore"
                  id="dateBefore"
                  placeholder="Dátum ig"/>
              </InputGroup>

              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right">
                    Címzettek
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  className="input-search"
                  onChange={this.handleChange}
                  value={this.state.recipient}
                  type="text"
                  name="recipient"
                  id="recipient"
                  placeholder="Címzettek"/>
              </InputGroup>

              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right">
                    Paraméter
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  className="input-search"
                  onChange={this.handleChange}
                  value={this.state.parameter}
                  type="text"
                  name="parameter"
                  id="parameter"
                  placeholder="Paraméter"/>
              </InputGroup>
              <InputGroup className="App-searchLine" >
                <Col className={"p-0"}>
                  <div className="text-right">
                    <Button outline color="primary">Keresés</Button>
                  </div>
                </Col>
              </InputGroup>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = dispatch => ({
  getEmailLogTypes: () =>{
    return dispatch(getRequest('/kisokostest/email/log/types'))
  }
});

export default connect(mapStateToProps,mapDispatchToProps)(FilterFormEmail);