export const OPEN_CONTRACT = 'OPEN_CONTRACT';
export const CLOSE_CONTRACT = 'CLOSE_CONTRACT';

export const openContract = (id) => ({
  type: OPEN_CONTRACT,
  contractId: id
});

export const closeContract = () => ({
  type: CLOSE_CONTRACT
});
