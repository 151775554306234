import React, {Component} from 'react';
import Modal from "../components/Modal";
import {Button, Col, Label} from "reactstrap";
import QueryPage from "../components/QueryPage";
import FilterFormProduct from "../FilterForm/FilterFormProduct";
import {connect} from "react-redux";
import {toggleModal} from "../actions/modal";
import {closeProduct, openProduct} from "../actions/product";
import {getRequest, putRequest} from "../actions/api";
import {saveFile} from "../Util";
import CommissionRangeForm from "../DetailedForm/CommissionRangeForm";
import ProductForm from "../DetailedForm/ProductForm";

import edit from "../icons/edit.png";
import keywords from "../icons/keyword.png";
import comp_dir from "../icons/comp_dir.png";
import exporticon from "../icons/export.png";
import invoiced from "../icons/invoiced.png";
import sales from "../icons/sales.png";
import ReactTooltip from "react-tooltip";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import XLSX from 'xlsx';
import ExcelExportTable from "../components/ExcelExportTable";
import { renderToString } from 'react-dom/server'

class ProductPage extends Component {
  toggleEditModal = (editObject = undefined) => {
    if(editObject !== undefined){
      this.props.openProduct(editObject)
    }
    else{
      this.props.openProduct()
    }
    this.props.toggleModal('product-edit')
  };

  toggleDebtModal = () => {
    this.props.toggleModal('debt-export')
  };

  exportToCSV = object => {
    this.props.getExport(object.id).then(
      response => {
        let csvDataMap = new Map();

        response.data.forEach(element => {
          const key = element[0];
          if (!csvDataMap.has(key))
            csvDataMap.set(key, []);

          element.shift();
          csvDataMap.get(key).push(element);
        });

        csvDataMap.forEach((element, key) => {
          let sum = 0;
          element.forEach(innerElement => {
            sum += innerElement[14] * innerElement[15];
          });

          if (sum === 0 && element.length > 1)
            csvDataMap.delete(key);
        });

        this.props.getExport2(object.id).then(
          response => {
            response.data.forEach(element => {
              const key = element[0];
              if (!csvDataMap.has(key))
                csvDataMap.set(key, []);

              element.shift();
              csvDataMap.get(key).push(element);
            });

            const csvData = [];
            let index = 0;
            csvDataMap.forEach(element => {
              ++index;
              let sum = 0;
              element.forEach(innerElement => {
                sum += innerElement[14] * innerElement[15];
              });

              element.forEach(innerElement => {
                if (sum === 0)
                  innerElement[9] = 5;
                else
                  innerElement[9] = 2;
                innerElement.unshift(index);
                csvData.push(innerElement.join(';'))
              });
            });

            const csv = csvData.join('\n');
            saveFile('KulcsSoft' + object.code +'.csv', csv);
          }
        )
      }
    ).catch(function (error) {

    });
  };

  exportSzamlazzCSV = object => {
    let tableString = [];

    // Data processing
    this.props.excelExportSzamlazzHu(object.id).then(
      response => {
        // Sort items by client name
        const sortedArrayByClientName = [...response.data] // opy of the original array
        sortedArrayByClientName.sort((a, b) => { // Sort by the value of client.name
          return b.client.name < a.client.name ?  1 // if b should come earlier, push a to end
         : b.client.name > a.client.name ? -1 // if b should come later, push a to begin
         : 0; 
        })
        
        tableString = renderToString(ExcelExportTable(sortedArrayByClientName));   
        document.getElementById('container_mytable').innerHTML = tableString;

        // Get 'mytable' class element's content
        let elt = document.getElementById('mytable');

        // Get workBook without cell processing
        let wb = XLSX.utils.table_to_book(elt, { raw: true, sheet:"Számlázz.hu"});

        // Save it to desired .xlsx
        // TODO: name should be specified
        XLSX.writeFile(wb, 'exportExport_test.xlsx');
      }
    )
  };

  makeBilled = object => {
    let today = new Date();
    object.invoice_date = today.getFullYear() + "-" + (today.getMonth() +1) + "-" + today.getDate();
    this.props.makeBilled(object.id, object);
  };

  exportKeywordList = object => {
    this.props.exportKeyword(object.id).then(response => {
      const csvArray = [];
      const rawArray = response.data;
      const keywords = new Set(rawArray.map(row => row[6]));

      csvArray.push('Címszó;Ügyfél;Szerződésszám;Szín;Méret;Nettó ár');

      keywords.forEach(keyword => {
        const appArray = rawArray.filter(row => row[6] === keyword);
        const keywordName = appArray[0][6];
        csvArray.push([keywordName].join(';'));

        appArray.forEach(row => {
          row.splice(-2);
          row.splice(0, 0, '');
          csvArray.push(row.join(';'));
        });
      });

      saveFile('Cimszavak' + object.code +'.csv', csvArray.join('\n'));
    }).catch(function (error) {

    });
  };

  exportCompanyDirectoryList = object => {
    this.props.exportCompanyDirectory(object.id).then(response => {
      const csvArray = [];
      const rawArray = response.data;

      csvArray.push('Cégmutató;Címszó;Ügyfél;Szerződésszám;Méret');

      rawArray.forEach(row => {
        let cegmutato = null
        row.forEach((col, index) => {
          if(index === 0 && col) {
            cegmutato = col
          }
          if(index === 1 && col && cegmutato === null) {
            cegmutato = col
          }
        })
        const arrayWithCegmutato = [ cegmutato, row.slice(2) ].flat()
        csvArray.push(arrayWithCegmutato.join(';'));
      });

      saveFile('Cegmutato' + object.code +'.csv', csvArray.join('\n'));
    }).catch(function (error) {

    });
  };

  exportSalesByProduct = object => {
    this.props.exportSalesByProduct(object.id).then(response => {
      const csvArray = [];
      const rawArray = response.data;

      csvArray.push('Szerződésszám;Ügyfél;Irányítószám;Város;Cím;Kapcsolattartó;Telefonszám;E-mail cím;Levelezési név;Levelezési cím;;;Adószám;Kiadvány;Méret;Címszó;Nettó ár;Üzletkötő');

      rawArray.forEach(row => {
        csvArray.push(row.join(';'));
      });

      saveFile('Forgalom' + object.code +'.csv', csvArray.join('\n'));
    }).catch(function (error) {

    });
  };

  exportDebtByProduct = (id) =>{
    this.props.getDebtByProduct(id)
      .then(response => {
        const csvArray = [];
        const rawArray = response.data;

        csvArray.push('Szerződésszám;Szerződés kelte;Rögzítés dátuma;Ügyfél;Irányítószám;Város;Cím;Fizetési mód;Kapcsolattartó;Telefonszám;E-mail;Adószám;Nettó tartozás;Bruttó tartozás;Kiadvány;Számlázás ideje;Fiz.határidő;+45 nap;Végszámlaszám;Üzletkötő');

        rawArray.forEach(row => {
          csvArray.push(row.join(';'));
        });

        saveFile('Tartozas'+ id + '.csv', csvArray.join('\n'));
      });
  };

  generateDebtByTermList = (startDate, endDate) =>{
    this.props.getDebt(startDate, endDate)
      .then(response => {
        const csvArray = [];
        const rawArray = response.data;

        csvArray.push('Szerződésszám;Szerződés kelte;Rögzítés dátuma;Ügyfél;Irányítószám;Város;Cím;Fizetési mód;Kapcsolattartó;Telefonszám;E-mail;Adószám;Nettó tartozás;Bruttó tartozás;Kiadvány;Számlázás ideje;Fiz. határidő;+45 nap;Végszámlaszám;Üzletkötő');

        rawArray.forEach(row => {
          csvArray.push(row.join(';'));
        });

        saveFile('Tartozas'+startDate +'_' +endDate + '.csv', csvArray.join('\n'));
      });
  };

  render() {
    return (
      <div>
        <QueryPage
          queryTableID='products'
          filterForm={<FilterFormProduct/>}
          pageURL="/kisokostest/productscalc"
          optionalElements={
            [
              <Col className="col-1 justify-content-md-start text-left App-page-label">
                <Label className="App-label App-label-title">Kiadvány</Label>
              </Col>,
              <Col className="col-2 justify-content-md-start text-left">
                <Button color="primary" onClick={() => this.toggleEditModal()}>Új</Button>
              </Col>,
              <Col className="col-1 justify-content-md-end text-right">
                <Button color="primary" onClick={() => this.toggleDebtModal()}>Tartozás</Button>
              </Col>
            ]
          }
          headers={
            [
              {
                name: 'K.kód',
                processor: object => object.code,
                className: "Table-ID",
                orderName: 'code'
              }, {
                name: 'Iroda',
                processor: object => object.office.name,
                className: "Table-address",
                orderName: 'office__name'
              }, {
                name: 'Kötés',
                processor: object => object.sold ? object.sold.toLocaleString("hu-HU") : 0,
                exportProcessor: object => object.sold ? object.sold : 0,
                className: "Table-summ"
              }, {
                name: 'Számlázott',
                processor: object => object.billed ? object.billed.toLocaleString("hu-HU") : 0,
                exportProcessor: object => object.billed ? object.billed : 0,
                className: "Table-summ"
              }, {
                name: 'Kifizetett',
                processor: object => object.paid ? object.paid.toLocaleString("hu-HU") : 0,
                exportProcessor: object => object.paid ? object.paid : 0,
                className: "Table-summ"
              }, {
                name: 'Tartozás',
                processor: object => object.billed ? (object.debt ? object.debt.toLocaleString("hu-HU") : 0) : 0,
                exportProcessor: object => object.billed ? (object.debt ? object.debt : 0): 0,
                className: "Table-summ",
              }, {
                name: 'Előlegtartozás',
                processor: object => object.sold - object.billed ? (object.sold - object.billed).toLocaleString("hu-HU") : 0,
                exportProcessor: object => object.sold - object.billed ? object.sold - object.billed : 0,
                className: "Table-summ"
              }, {
                name: '',
                processor: object => <Button className="App-icon-button"  size={'sm'} onClick={() => this.toggleEditModal(object.id)}><img className="App-icon-button" src={edit}  alt={"Szerk."} data-tip="Szerkesztés"/><ReactTooltip /></Button>,
                className: "Table-btn",
                ignoreExport: true
              }, {
                name: '',
                processor: object => <Button className="App-icon-button"  outline onClick={() => this.exportToCSV(object)}><img className="App-icon-button" src={exporticon}  alt={"Export"} data-tip="Export"/><ReactTooltip /></Button>,
                className: "Table-btn",
                ignoreExport: true
              }, {
                name: '',
                processor: object => <Button className="App-icon-button"  outline disabled={object.invoice_date !== null}
                                            onClick={() => this.makeBilled(object)}><img className="App-icon-button" src={invoiced}  alt={"Számlázva"} data-tip="Számlázva"/><ReactTooltip /></Button>,
                className: "Table-btn",
                ignoreExport: true
              }, {
                name: '',
                processor: object => <Button className="App-icon-button"  outline onClick={() => this.exportKeywordList(object)}><img className="App-icon-button" src={keywords}  alt={"Címszólista"} data-tip="Címszólista"/><ReactTooltip /></Button>,
                className: "Table-btn",
                ignoreExport: true
              }, {
                name: '',
                processor: object => <Button className="App-icon-button"  outline onClick={() => this.exportCompanyDirectoryList(object)}><img className="App-icon-button" src={comp_dir}  alt={"Cégmutató"} data-tip="Cégmutató"/><ReactTooltip /></Button>,
                className: "Table-btn",
                ignoreExport: true
              }, {
                name: '',
                processor: object => <Button className="App-icon-button"  outline onClick={() => this.exportSalesByProduct(object)}><img className="App-icon-button" src={sales}  alt={"Törzslista"} data-tip="Törzslista"/><ReactTooltip /></Button>,
                className: "Table-btn",
                ignoreExport: true
              }, {
                name: '',
                processor: object => <Button className="App-icon-button"  size={'sm'} onClick={() => this.exportDebtByProduct(object.id)}><img className="App-icon-button" src={invoiced}  alt={"Tartozás"} data-tip="Tartozás"/><ReactTooltip /></Button>,
                className: "Table-btn",
                ignoreExport: true
              }, {
                name: '',
                processor: object => <Button className="App-icon-button"  onClick={() => this.exportSzamlazzCSV(object)}><FontAwesomeIcon icon={faFileExcel} className="Excel-export" alt={"Számlázz.hu export"} data-tip="Számlázz.hu"/><ReactTooltip /></Button>,
                className: "Table-btn",
                ignoreExport: true
              }, {
                name: '',
                processor: object=> "",
                className: "Table-filler",
                ignoreExport: true
              }
            ]
          }
        />
        <Modal className="modal-dialog_mid" id='product-edit' headerName="Kiadvány">
          <ProductForm/>
        </Modal>
        <Modal className="modal-dialog_wide" id='debt-export' headerName="Tartozás (Előleg határidő)">
          <CommissionRangeForm handleSubmit={this.generateDebtByTermList}/>
        </Modal>
        <div hidden id="container_mytable">
        </div>
      </div>
    )
  }
}
const mapDispatchToProps = dispatch => ({
  closeProduct: () =>{
    dispatch(closeProduct())
  },
  openProduct: (id) =>{
    dispatch(openProduct(id));
  },
  getDebt: (startDate, endDate) =>{
    return dispatch(getRequest('/kisokostest/debtlist?&start_date=' + startDate + '&end_date=' + endDate));
  },
  getDebtByProduct: (id) =>{
    return dispatch(getRequest('/kisokostest/debtlistbyproduct?product_id=' + id));
  },
  getExport: (id) =>{
    return dispatch(getRequest('/kisokostest/exportcsv1?product_id=' + id));
  },
  getExport2: (id) =>{
    return dispatch(getRequest('/kisokostest/exportcsv2?product_id=' + id));
  },
  makeBilled: (id,data) =>{
    return dispatch(putRequest('/kisokostest/products/' + id,data));
  },
  exportKeyword: (id) =>{
    return dispatch(getRequest('/kisokostest/keywordappearancelist?product_id=' + id));
  },
  exportCompanyDirectory: (id) =>{
    return dispatch(getRequest('/kisokostest/companydirectoryforproduct?product_id=' + id));
  },
  exportSalesByProduct: (id) =>{
    return dispatch(getRequest('/kisokostest/exportSalesByProduct?product_id=' + id));
  },
  toggleModal: id => {
    dispatch(toggleModal(id));
  },
  excelExportSzamlazzHu: product_id => {
    return dispatch(getRequest(`/kisokostest/export/szamlazz-hu?product_id=${product_id}`))
  }
});
export default connect(null,mapDispatchToProps)(ProductPage)
