import {CLOSE_PRODUCT, OPEN_PRODUCT} from "../actions/product";

const product = (state= {
  productId: undefined,
}, action) =>{
  switch (action.type) {
    case OPEN_PRODUCT:
      return{
        ...state,
        productId: action.productId,
      };
    case CLOSE_PRODUCT:
      return{
        ...state,
        productId: undefined,
      };
    default:
      return state;
  }
};

export default product;
