import {Button, Col, Form, Input, Row,} from "reactstrap"
import React from "react";
import InputGroup from "reactstrap/es/InputGroup";

export default class FilterFormSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tax_number: '',
    }
  }

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value; 
    this.setState(prevState => {
      const newState = {...prevState};
      newState[name] = value;
      return newState;
    });
  };

  render() {
    return (
      <div style={{ display: "flex", flexDirection: "row", margin: "0 auto", alignItems: "center" }}>
        <Form style={{ width: 120 }} onSubmit = {e => {
          e.preventDefault();
          this.props.handleSubmit(this.state);
         }}>
          <Row className="mb-3">
            <Col>
              <InputGroup>
                <Input
                style={{ padding: 10}}
                  className="input-search" 
                  onChange={this.handleChange}
                  value={this.state.tax_number}
                  type="text"
                  name="tax_number"
                  maxlength="13"
                  placeholder="12345678-1-13"
                  id="tax_number"/>
              </InputGroup>
              <InputGroup className="App-searchLine">
                <Col className={"p-0"}>
                  <div className="text-right" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button outline color="primary">Keresés</Button>
                  </div>
                </Col>
              </InputGroup>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}