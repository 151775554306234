export const OPEN_CONTRACT_TRACKING = 'OPEN_CONTRACT_TRACKING';
export const CLOSE_CONTRACT_TRACKING = 'CLOSE_CONTRACT_TRACKING';

export const openContractTracking = (id) => ({
  type: OPEN_CONTRACT_TRACKING,
  contractTrackingId: id
});

export const closeContractTracking = () => ({
  type: CLOSE_CONTRACT_TRACKING
});
