import React, {Component} from 'react';
import {connect} from "react-redux";
import {deleteRequest, getRequest, postRequest, putRequest} from "../actions/api";
import {invalidateQuery} from "../actions/query";
import {toggleModal} from "../actions/modal";
import {closeAppearance, openAppearance} from "../actions/appearance";
import {getPropertyOrEmptyString, getPropertyOrUndefined, getPropertyOrZero} from "../Util";
import {Button, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row} from "reactstrap";
import Modal from "../components/Modal";
import CompanyDirectoryForm from "./CompanyDirectoryForm";
import add from "../icons/add.png";
import ReactTooltip from "react-tooltip";

class AppearanceFormSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      contract_item_id: 0,
      size_code: '',
      size_id: 0,
      keyword_id: undefined,
      keyword_keyword: '',
      net_price: 0,
      discount: 0,
      list_price: 0,
      sizes: [],
      keywords: [],
      company_directory_id: undefined,
      company_directories: [],
      contract_date: '',
      company_directory_client: 0,
      company_directory_modal: false,
      final_bill_number: '',
      payment_method: '',
    };
  }

  getContractObject(id) {
    this.props.getContract(id).then(response => {
      this.setState({
        company_directory_client: getPropertyOrUndefined(response.data.results[0], "client.id"),
        contract_date: getPropertyOrEmptyString(response.data.results[0], "contract_date"),
      });
    }).catch(function (error) {

    });
  }

  getContractItemObject(id) {
    this.props.getContractItem(id).then(response => {
      this.setState({
        contract_item_id: getPropertyOrZero(response.data[0], "id"),
        final_bill_number: getPropertyOrEmptyString(response.data[0], 'final_bill_number'),
        payment_method: getPropertyOrEmptyString(response.data[0], 'contract.payment_method.type')
      });
    }).catch(function (error) {

    });
  }

  getAppearanceObject(id) {
    this.props.getAppearance(id).then(response => {
      this.setState({
        id: getPropertyOrUndefined(response.data.results[0], 'id'),
        size_code: getPropertyOrEmptyString(response.data.results[0], 'code'),
        keyword_id: getPropertyOrZero(response.data.results[0], 'keyword.id'),
        company_directory_id: getPropertyOrZero(response.data.results[0], 'company_directory.id'),
        keyword_keyword: getPropertyOrEmptyString(response.data.results[0], 'keyword.keyword'),
        net_price: getPropertyOrZero(response.data.results[0], 'net_price'),
        discount: getPropertyOrZero(response.data.results[0], 'discount'),
        list_price: getPropertyOrZero(response.data.results[0], 'list_price'),
      });
    }).catch(function (error) {

    });
  }

  componentDidMount() {
    this.props.getSizes().then((response) => {
      let sizes = [];
      response.data.map((i) => sizes = [...sizes, i]);
      this.setState({
        sizes: sizes,
        size_code: this.state.size_code ? this.state.size_code : sizes[0].code,
        size_id: this.state.size_id ? this.state.size_id : sizes[0].id,
        list_price: this.state.list_price ? this.state.list_price : sizes[0].price,
        discount: this.state.discount ? this.state.discount : sizes[0].price,
      });
    }).catch(function (error) {

    });

    this.props.getCompanyDirectories(this.props.clientEditId).then((response) => {
      let company_directories = [];
      response.data.map((i) => company_directories = [...company_directories, i]);
      this.setState({
        company_directories: company_directories,
        company_directory_id: this.state.company_directory_id ? this.state.company_directory_id :
          company_directories.length > 0 ? company_directories[0].id : undefined
      });
    }).catch(function (error) {

    });

    this.props.getKeywords().then((response) => {
      let keywords = [];
      response.data.map((i) => keywords = [...keywords, i]);
      this.setState({
        keywords: keywords,
        keyword_id: this.state.keyword_id ? this.state.keyword_id : keywords[0].id
      });
    }).catch(function (error) {

    });
    this.getContractObject(this.props.contractEditId);
    this.getContractItemObject(this.props.contractItemEditId);
    if (this.props.appearanceEditId !== undefined) {
      this.getAppearanceObject(this.props.appearanceEditId)
    }
  }

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => {
      const newState = {...prevState};
      newState[name] = value;
      return newState;
    });
  };

  handleSizes = e => {
    this.handleChange(e);
    let curr_id = e.target.value;
    let curr_size = this.state.sizes.find(function (element) {
      if (element.code === curr_id) {
        return element;
      }
      return null
    });
    this.setState({
      size_id: curr_id,
      size_code: curr_size.code,
      list_price: curr_size.price,
      discount: curr_size.price - this.state.net_price,
    });
  };

  handleKeywords = e => {
    this.handleChange(e);
    this.setState({
      keyword_id: e.target.value
    });
  };

  handleCompanyDirectories = e => {
    this.handleChange(e);
    this.setState({
      company_directory_id: e.target.value
    });
  };


  handleNetList = e => {
    this.handleChange(e);
    const value = e.target.value;
    const name = e.target.name;
    if (name === 'net_price') {
      this.setState({
        discount: this.state.list_price - value
      })
    }
    if (name === 'list_price') {
      this.setState({
        discount: value - this.state.net_price
      })
    }
  };

  stateToObject = () => {
    return ({
      id: this.state.id,
      contract_item: {
        id: this.state.contract_item_id,
        product: {
          office: {}
        },
        contract: {
          contract_date: this.state.contract_date,
          payment_method: {
            id: 0,
          },
          client: {
            billing_address: {},
            shipping_address: {},
            client_type: {},
            client_file: []
          },
          salesperson: {
            office: 5
          },
          processor: {
            office: 5
          }
        }
      },
      company_directory: {
        id: this.state.company_directory_id,
        client: {
          billing_address: {},
          shipping_address: {},
          client_type: {},
          client_file: []
        },
      },
      code: this.state.size_code,
      keyword: {
        id: this.state.keyword_id,
      },
      net_price: this.state.net_price,
      discount: this.state.discount,
      list_price: this.state.list_price
    });
  };

  createOrUpdate = () => {
    if (this.props.appearanceEditId !== undefined)
      this.props.saveEntry(this.props.appearanceEditId, this.stateToObject()).then(() => {
        this.props.requery();
        this.props.toggleModal(this.props.modalId);
      });
    else
      this.props.createEntry(this.stateToObject()).then((response) => {
        this.props.setEdited(response.data.id);
        this.props.requery();
        this.props.toggleModal(this.props.modalId);
      }).catch(function (error) {

      });
  };

  deleteEntry = () => {
    if (this.props.appearanceEditId !== undefined) {
      this.props.deleteEntry(this.props.appearanceEditId).then(() => {
        this.props.requery();
      });
      this.props.toggleModal(this.props.modalId);
    }
  };

  requeryCompanyDirectories = () => {
    this.props.getCompanyDirectories(this.props.clientEditId).then((response) => {
      let company_directories = [];
      response.data.map((i) => company_directories = [...company_directories, i]);
      this.setState({
        company_directories: company_directories,
        company_directory_id: this.state.company_directory_id ? this.state.company_directory_id : company_directories[0].id
      });
    }).catch(function (error) {

    });
  };

  toggleCompanyDirectoryModal = () => {
    this.props.toggleModal('company-directory-single')
  };

  render() {
    return (

            <Form>

              <FormGroup>
                <Col>
                  <Row className="mb-3">
                    <Col md="7">

                      <InputGroup>
                        <InputGroupAddon className="input-group-addon-50" addonType="prepend"><InputGroupText
                          className="input-group-text">Végszámlaszám</InputGroupText></InputGroupAddon>
                        <Input type="text" disabled name="final_bill_number" id="final_bill_number"
                               value={this.state.final_bill_number}/>
                      </InputGroup>

                      <InputGroup>
                        <InputGroupAddon className="input-group-addon-50" color="primary"
                                         addonType="prepend"><InputGroupText
                          className="input-group-text float-right">Felület kódja</InputGroupText></InputGroupAddon>
                        <Input type="select" name="size_id" id="size_id" onChange={this.handleSizes}
                               value={this.state.size_code}>
                          {this.state.sizes.map(i => <option key={i.id} value={i.code}>{i.code}</option>)}
                        </Input>
                      </InputGroup>
                      <InputGroup>
                        <InputGroupAddon className="input-group-addon" addonType="prepend"><InputGroupText
                          className="input-group-text text-right">Címszó</InputGroupText></InputGroupAddon>
                        <Input type="select" name="keyword_id" id="keyword_id" onChange={this.handleKeywords}
                               value={this.state.keyword_id}>
                          {this.state.keywords.map(i => <option key={i.id} value={i.id}>{i.keyword}</option>)}
                        </Input>
                      </InputGroup>

                      <InputGroup>
                        <InputGroupAddon className="input-group-addon" addonType="prepend"><InputGroupText
                          className="input-group-text text-right">Cégmutató</InputGroupText></InputGroupAddon>
                        <Input type="select" name="company_directory_id" id="company_directory_id"
                               onChange={this.handleCompanyDirectories} value={this.state.company_directory_id}>
                          {this.state.company_directories.map(i => <option key={i.id} value={i.id}>{i.company_directory}</option>)}
                        </Input>
                        <Button className="ml-2 App-icon-button" color="primary" onClick={() => this.toggleCompanyDirectoryModal()}><img className="App-icon-button" src={add}  alt={"Hozzáadás"} data-tip="Hozzáadás"/><ReactTooltip /></Button>

                      </InputGroup>



                    </Col>
                    <Col md="5">

                      <InputGroup>
                        <InputGroupAddon className="input-group-addon-50" addonType="prepend"><InputGroupText
                          className="input-group-text">Fizetési mód</InputGroupText></InputGroupAddon>
                        <Input disabled type="text" name="payment_method" id="payment_method"
                               value={this.state.payment_method}/>
                      </InputGroup>

                      <InputGroup>
                        <InputGroupAddon className="input-group-addon-50" addonType="prepend"><InputGroupText
                          className="input-group-text">Nettó</InputGroupText></InputGroupAddon>
                        <Input className={"input-currency"} type="number" name="net_price" id="net_price"
                               onChange={this.handleNetList}
                               value={this.state.net_price}/>
                      </InputGroup>
                      <InputGroup>
                        <InputGroupAddon className="input-group-addon-50" addonType="prepend"><InputGroupText
                          className="input-group-text">Listaár</InputGroupText></InputGroupAddon>
                        <Input  disabled className={"input-currency"} type="number" name="list_price" id="list_price"
                               onChange={this.handleNetList}
                               value={this.state.list_price}/>
                      </InputGroup>
                      <InputGroup>
                        <InputGroupAddon className="input-group-addon-50" addonType="prepend"><InputGroupText
                          className="input-group-text">Kedvezmény</InputGroupText></InputGroupAddon>
                        <Input className={"input-currency"} type="number" name="discount" id="discount"
                               onChange={this.handleChange}
                               value={this.state.discount}/>
                      </InputGroup>
                    </Col>



                  </Row>
                </Col>
                <Modal className="modal-dialog_sml" id='company-directory-single' headerName="Cégmutató Hozzáadása">
                  <CompanyDirectoryForm requeryCD={this.requeryCompanyDirectories}/>
                </Modal>
              </FormGroup>
              <FormGroup>
                <Row className="justify-content-end">
                  <Col className="text-right">
                    <Button color="primary" onClick={this.createOrUpdate}>Mentés</Button>
                  </Col>
                </Row>
              </FormGroup>
            </Form>

    );
  }
}

const mapStateToProps = (state) => {
  const contractEditId = state.contract.contractId;
  const contractItemEditId = state.contractItem.contractItemId;
  const appearanceEditId = state.appearance.appearanceId;
  const clientEditId = state.client.clientId;

  return {
    appearanceEditId,
    contractItemEditId,
    contractEditId,
    clientEditId,
  }
};

const mapDispatchToProps = dispatch => ({
  openAppearance: (id) => {
    dispatch(openAppearance(id));
  },
  closeAppearance: () => {
    dispatch(closeAppearance());
  },
  getContractItem: (id) => {
    return dispatch(getRequest('/kisokostest/contractitems/?id=' + id))
  },
  getAppearance: (id) => {
    return dispatch(getRequest('/kisokostest/appearances/?id=' + id))
  },
  getContract: (id) => {
    return dispatch(getRequest('/kisokostest/contractscalc/?id=' + id))
  },
  getSizes: () => {
    return dispatch(getRequest('/kisokostest/sizes/'))
  },
  getKeywords: () => {
    return dispatch(getRequest('/kisokostest/keywords/'))
  },
  getCompanyDirectories: (id) => {
    return dispatch(getRequest('/kisokostest/companydirectories/?client_id=' + id))
  },
  createEntry: (data) => {
    return dispatch(postRequest('/kisokostest/appearances/', data))
  },
  deleteEntry: (id) => {
    return dispatch(deleteRequest('/kisokostest/appearances/' + id))
  },
  saveEntry: (id, data) => {
    return dispatch(putRequest('/kisokostest/appearances/' + id, data))
  },
  setEdited: (id) => {
    dispatch(openAppearance(id))
  },
  requery: () => {
    dispatch(invalidateQuery('appearance-single'));
  },
  toggleModal: id => {
    dispatch(toggleModal(id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AppearanceFormSingle)