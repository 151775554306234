import React, { Component } from "react";
import logo from "../icons/logo.png";
import signout from "../icons/sign-out-alt.png";
import { Col, Nav, Navbar, NavbarBrand, NavItem } from "reactstrap";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import { connect } from "react-redux";
import { getRequest } from "../actions/api";
import { logout } from "../actions/auth.js";
import ClientPage from "../Pages/ClientPage";
import ContractPage from "../Pages/ContractPage";
import ColleaguePage from "../Pages/ColleaguePage";
import ProductPage from "../Pages/ProductPage";
import PaymentPage from "../Pages/PaymentPage";
import FilesPage from "../Pages/FilesPage";
import InvoicesPage from "../Pages/InvoicesPage";
import ReactTooltip from "react-tooltip";
import SearchTaxNumberPage from "../Pages/SearchTaxNumberPage";
import TestPage from "../Pages/TestPage";
import ContractTrackingPage from "../Pages/ContractTrackingPage";
import Dashboard from "../Pages/Dashboard";
import EmailLogPage from "../Pages/EmailLogPage";
import ReportsPage from "../Pages/ReportsPage";
import AdminDashboard from "../Pages/AdminDashboard";
import DiscountsPage from "../Pages/DiscountsPage";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isReportsAvailable: false,
      isAdminDashboardAvailable: false,
    };
    this.getUserProfile = this.getUserProfile.bind(this);
  }

  logout = (e) => {
    e.preventDefault();
    this.props.logout();
    localStorage.removeItem("token");
  };

  getUserProfile = () => {
    this.props.getUserProfile().then((response) => {
      this.setState({
        isReportsAvailable: response.data.hasAccessToReports,
        isAdminDashboardAvailable:
          response.data.emailAddress.includes("@puredev.hu"),
      });
    });
  };

  componentDidMount() {
    this.getUserProfile();
  }

  render() {
    return (
      <Router>
        <div className="container col-12 ">
          <div className="App-head row justify-content-md-start">
            <div className="offset-1 col-10">
              <Navbar light expand="md" id="mobile-navbar">
                <Col className="col-12 p-0">
                  <Nav className="justify-content-center">
                    <NavbarBrand>
                      <img height={150} src={logo} alt="Kisokos" />
                    </NavbarBrand>
                  </Nav>
                </Col>
                <Col className="col-12">
                  <Nav
                    className="App-navbar-text justify-content-center"
                    navbar
                  >
                    <NavItem>Egyszerűsített mobil menü</NavItem>
                    <NavItem>
                      <Link className="nav-link" to="/kedvezmeny">
                        Kedvezmény
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        className="nav-link App-icon"
                        to="#"
                        onClick={this.logout}
                      >
                        <img
                          src={signout}
                          height="20px"
                          alt={"Kijelentkezés"}
                          data-tip="Kijelentkezés"
                        />
                        <ReactTooltip />
                      </Link>
                    </NavItem>
                  </Nav>
                </Col>
              </Navbar>
              <Navbar light expand="md" id="desktop-navbar">
                <Col className="col-2 justify-content-md-end p-0">
                  <Nav className=" ">
                    <NavbarBrand>
                      <img height={150} src={logo} alt="Kisokos" />
                    </NavbarBrand>
                  </Nav>
                </Col>
                <Col className="col-9 ">
                  <Nav
                    className="App-navbar-text ml-auto justify-content-md-end"
                    navbar
                  >
                    <NavItem>
                      <Link className="nav-link" to="/clients">
                        Ügyfél
                      </Link>
                    </NavItem>
                    <NavItem>
                      <span className="nav-link">|</span>
                    </NavItem>
                    <NavItem>
                      <Link className="nav-link" to="/contracts">
                        Szerződések
                      </Link>
                    </NavItem>
                    <NavItem>
                      <span className="nav-link">|</span>
                    </NavItem>
                    <NavItem>
                      <Link className="nav-link" to="/colleagues">
                        Üzletkötők
                      </Link>
                    </NavItem>
                    <NavItem>
                      <span className="nav-link">|</span>
                    </NavItem>
                    <NavItem>
                      <Link className="nav-link" to="/products">
                        Kiadványok
                      </Link>
                    </NavItem>
                    <NavItem>
                      <span className="nav-link">|</span>
                    </NavItem>
                    <NavItem>
                      <Link className="nav-link" to="/payments">
                        Befizetések
                      </Link>
                    </NavItem>
                    <NavItem>
                      <span className="nav-link">|</span>
                    </NavItem>
                    <NavItem>
                      <Link className="nav-link" to="/files">
                        Fájlok
                      </Link>
                    </NavItem>
                  </Nav>
                  <Nav
                    className="App-navbar-text ml-auto justify-content-md-end"
                    navbar
                  >
                    <NavItem>
                      <Link className="nav-link" to="/invoices">
                        Számlák
                      </Link>
                    </NavItem>
                    <NavItem>
                      <span className="nav-link">|</span>
                    </NavItem>
                    <NavItem>
                      <Link className="nav-link" to="/tax-number">
                        Adószám
                      </Link>
                    </NavItem>
                    <NavItem>
                      <span className="nav-link">|</span>
                    </NavItem>
                    <NavItem>
                      <Link className="nav-link" to="/contract-tracking">
                        Szerződéskövetés
                      </Link>
                    </NavItem>
                    <NavItem>
                      <span className="nav-link">|</span>
                    </NavItem>
                    <NavItem>
                      <Link className="nav-link" to="/kedvezmeny">
                        Kedvezmény
                      </Link>
                    </NavItem>
                    <NavItem>
                      <span className="nav-link">|</span>
                    </NavItem>
                    {this.state.isReportsAvailable && (
                      <>
                        <NavItem>
                          <Link className="nav-link" to="/reports">
                            Riportok
                          </Link>
                        </NavItem>
                        <NavItem>
                          <span className="nav-link">|</span>
                        </NavItem>
                      </>
                    )}
                    <NavItem>
                      <a
                        className="nav-link"
                        href="https://adatbazisadmin.kisokos.hu/hu/admin"
                      >
                        Admin
                      </a>
                    </NavItem>
                    <NavItem>
                      <span className="nav-link">|</span>
                    </NavItem>
                    <NavItem>
                      <Link
                        className="nav-link App-icon"
                        to="#"
                        onClick={this.logout}
                      >
                        <img
                          src={signout}
                          height="20px"
                          alt={"Kijelentkezés"}
                          data-tip="Kijelentkezés"
                        />
                        <ReactTooltip />
                      </Link>
                    </NavItem>
                  </Nav>
                </Col>
              </Navbar>
            </div>
          </div>

          <div className="App-body row justify-content-md-start">
            <div className="offset-1 col-10">
              <Route
                path="/clients"
                render={() => {
                  return <ClientPage />;
                }}
              />

              <Route
                path="/contracts"
                render={() => {
                  return <ContractPage />;
                }}
              />

              <Route
                path="/colleagues"
                render={() => {
                  return <ColleaguePage />;
                }}
              />

              <Route
                path="/products"
                render={() => {
                  return <ProductPage />;
                }}
              />

              <Route
                path="/payments"
                render={() => {
                  return <PaymentPage />;
                }}
              />

              <Route
                path="/files"
                render={() => {
                  return <FilesPage />;
                }}
              />
              <Route
                path="/invoices"
                render={() => {
                  return <InvoicesPage />;
                }}
              />

              <Route
                path="/tax-number"
                render={() => {
                  return <SearchTaxNumberPage />;
                }}
              />

              <Route
                path="/super-secret-test-page"
                render={() => {
                  return <TestPage />;
                }}
              />

              <Route
                path="/dashboard"
                render={() => {
                  return <Dashboard />;
                }}
              />

              <Route
                path="/contract-tracking"
                render={() => {
                  return <ContractTrackingPage />;
                }}
              />

              <Route
                path="/kedvezmeny"
                render={() => {
                  return <DiscountsPage />;
                }}
              />

              <Route
                path="/emails"
                render={() => {
                  return <EmailLogPage />;
                }}
              />

              <Route
                path="/reports"
                render={() => {
                  return <ReportsPage />;
                }}
              />

              <Route
                path="/admin-dashboard"
                render={() => {
                  return <AdminDashboard />;
                }}
              />
            </div>
          </div>
        </div>
      </Router>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(logout());
  },
  getUserProfile: () => {
    return dispatch(getRequest("/kisokostest/user/profile/"));
  },
});

export default connect(null, mapDispatchToProps)(NavBar);
