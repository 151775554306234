import React, {Component} from 'react';
import {connect} from "react-redux";
import {closeContractItem, openContractItem} from "../actions/contractItem";
import {deleteRequest, getRequest, postRequest, putRequest} from "../actions/api";
import {invalidateQuery, setData} from "../actions/query";
import {toggleModal} from "../actions/modal";
import Modal from "../components/Modal";
import {Button, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row} from "reactstrap";
import {getPropertyOrEmptyString, getPropertyOrUndefined, getPropertyOrZero} from "../Util";
import QueryTable from "../components/QueryTable";
import AppearanceFormBatch from "./AppearanceFormBatch";
import Select from "react-select";
import minus from "../icons/minus.png";
import ReactTooltip from "react-tooltip";


class ContractItemFormBatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contract_item_id: 0,
      product_id: 0,
      product_code: '',
      product_spread_date: undefined,
      product_advance_payment_date: undefined,
      contract_id: 0,
      contract_contract_number: '',
      contract_contract_date: undefined,
      products: [],
      final_bill_number: '',
      inputValue: '',
      appearances: [],
      selected_product_ids: [],
      comment: '',
    };

  }

  getContractItemObject(id) {
    this.props.getContractItem(id).then(response => {
      this.setState({
        contract_item_id: getPropertyOrZero(response.data[0], ''),
        product_id: getPropertyOrZero(response.data[0], 'product.id'),
        product_code: getPropertyOrEmptyString(response.data[0], 'product.code'),
        product_spread_date: getPropertyOrUndefined(response.data[0], 'product.spread_date'),
        product_advance_payment_date: getPropertyOrUndefined(response.data[0], 'product.advance_payment_date'),
        contract_id: getPropertyOrZero(response.data[0], 'contract.id'),
        contract_contract_number: getPropertyOrEmptyString(response.data[0], 'contract.contract_number'),
        contract_contract_date: getPropertyOrUndefined(response.data[0], 'contract.contract_date'),
        final_bill_number: getPropertyOrEmptyString(response.data[0], 'final_bill_number'),
        comment: getPropertyOrEmptyString(response.data[0], 'comment')
      });
    }).catch(function (error) {

    });
  }

  getContractObject(id) {
    this.props.getContract(id).then(response => {
      this.setState({
        contract_item_id: undefined,
        contract_id: getPropertyOrZero(response.data.results[0], 'id'),
        contract_contract_number: getPropertyOrEmptyString(response.data.results[0],  'contract_number'),
        contract_contract_date: getPropertyOrUndefined(response.data.results[0], 'contract_date')
      });
    }).catch(function (error) {

    });
  }

  componentDidMount() {
    if (this.props.contractItemEditId === undefined) {
      this.props.getProducts().then((response) => {
        let products = [];
        response.data.map((i) => products = [...products, i]);
        if (products.length > 0) {
          this.setState({
            products: products,
            product_id: this.state.product_id ? this.state.product_id : products[0].id,
            product_code: this.state.product_code ? this.state.product_code : products[0].code
          });
        }
      }).catch(function (error) {

      });
    } else {
      this.props.getProductsAll().then((response) => {
        let products = [];
        response.data.map((i) => products = [...products, i]);
        this.setState({
          products: products,
          product_id: this.state.product_id ? this.state.product_id : products[0].id,
          product_code: this.state.product_code ? this.state.product_code : products[0].code
        });
      }).catch(function (error) {

      });
    }
    if (this.props.contractItemEditId !== undefined) {
      this.getContractItemObject(this.props.contractItemEditId);
    }
    if(this.props.contractEditId !== undefined){
      this.getContractObject(this.props.contractEditId)
    }
  }

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => {
      const newState = {...prevState};
      newState[name] = value;
      return newState;
    });
  };

  handleMultipleSelectChange = e => {
    const options = e.target.options;
    const values = [];

    for (let i = 0; i < options.length; ++i) {
      if (options[i].selected)
        values.push(options[i].value);
    }

    this.setState({
      selected_product_ids: values
    })
  };


  onChange = (inputValue, actionMeta) => {
    let values = [];
    switch (actionMeta.action) {
      case 'select-option':
        values = inputValue.map(i=>i.value);
        this.setState({
          selected_product_ids: values
        });
        return;
      case 'remove-value':
        values = (inputValue && inputValue.map(i=>i.value)) || [];
        this.setState({
          selected_product_ids: values
        });
        return;
      case 'clear':
        this.setState({
          selected_product_ids: []
        });
        return;
      default:
        return
    }

  };

  addToAppearances = appearance => {
    const appearances = [...this.state.appearances, appearance];
    this.setState({
      appearances: appearances
    });
    this.props.setData('appearance-batch',appearances)
  };

  stateToObject = () => {
    const contract_items = [];
    this.state.selected_product_ids.forEach(id => {
      const contract_item = {
        product: {
          id: id,
          office: {
            name: 'Bpest-Harsányi Kft.',
            id: 5
          }
        },
        contract: {
          id: this.state.contract_id,
          contract_date: "1970-01-01",
          payment_method: {
            id: 0,
          },
          processor: {
            id: 0,
            office: {
              name: 'Bpest-Harsányi Kft.',
              id: 5
            }
          },
          salesperson: {
            id: 0,
            office: {
              name: 'Bpest-Harsányi Kft.',
              id: 5
            }
          },
          client: {
            name: '',
            id: 0,
            billing_address: {},
            shipping_address: {},
            client_type: {},
            client_file: [],
          }
        },
        appearances: this.state.appearances,
        comment: this.state.comment,
        company_directory: {
          client: {
            client_file: [],
          }
        }
      };

      contract_items.push(contract_item)
    });

    return contract_items;
  };

  createOrUpdate = () => {
    if (this.props.contractItemEditId !== undefined)
      this.props.saveEntry(this.props.contractItemEditId, this.stateToObject()).then(() => {
        this.props.requery();
        this.props.refreshContract();
        this.props.toggleModal(this.props.modalId);
      });
    else
      this.props.createEntry(this.stateToObject()).then(() => {
        this.props.requery();
        this.props.refreshContract();
        this.props.toggleModal(this.props.modalId);
      });
  };

  deleteEntry = () => {
    if (this.props.contractItemEditId !== undefined) {
      this.props.deleteEntry(this.props.contractItemEditId).then(() => {
        this.props.requery();
      });
      this.props.toggleModal(this.props.modalId);
    }
  };

  toggleAppearanceModal = (editId = undefined) => {
      this.props.toggleModal('appearance-batch');
  };

  deleteAppearance = (id) =>{
    const appearances = this.state.appearances;
    let index = appearances.map(a => {
      return a.id;
    }).indexOf(id);

    appearances.splice(index, 1);
    this.props.refreshAppearances()
  };
  convertProduct = (product) =>{
    return {value: product.id,label: product.code}
  };

  render() {
    return (

            <Form>

              <FormGroup>
                <Row className="mb-3">
                  <Col md="5"  className="pl-5 pr-0">


                    <InputGroup>
                      <InputGroupAddon addonType="prepend"><InputGroupText
                        className="input-group-text bg-light text-right ">Szerződés
                        száma</InputGroupText></InputGroupAddon>
                      <Input disabled type="text" readOnly name="contract_contract_number" id="contract_contract_number"
                             onChange={this.handleChange}
                             value={this.state.contract_contract_number}/>
                    </InputGroup>

                    <InputGroup>
                      <InputGroupAddon color="primary" addonType="prepend"><InputGroupText
                        className="input-group-text float-right align-items-baseline">Kiadvány kódja</InputGroupText></InputGroupAddon>
                      <Select name="selected_products" isMulti='true' onChange={this.onChange}  closeMenuOnSelect={false}
                              className='App-select'
                              options={this.state.products.map(i => this.convertProduct(i))}/>
                    </InputGroup>

                    <InputGroup className="input-group_textarea">
                      <InputGroupAddon className="input-group-addon" addonType="prepend"><InputGroupText
                        className="input-group-text">Komment</InputGroupText></InputGroupAddon>
                      <Input className="textarea"  type="textarea" name="comment" id="comment" onChange={this.handleChange}
                             value={this.state.comment}/>
                    </InputGroup>
                  </Col>

                  <Col md="6" className="text-right pl-5">


                    <InputGroup className="input-group_textarea">
                      <InputGroupAddon className="input-group-addon align-items-baseline App-label App-label-title pr-4 font-weight-bolder "  addonType="prepend"><InputGroupText
                        className="input-group-text">Felületek</InputGroupText></InputGroupAddon>


                      <QueryTable className="tbody-200" id={'appearance-batch'}
                      headers={
                      [
                      {
                        name: 'Kód',
                        processor: object => object.code,
                        className: "Table-ID",
                      }, {
                        name: 'Címszó',
                        processor: object => object.keyword.keyword,
                        className: "Table-city",
                      }, {
                        name: 'Nettó Ár',
                        processor: object => object.net_price ? object.net_price.toLocaleString("hu-HU") : 0,
                        exportProcessor: object => object.net_price ? object.net_price : 0,
                        className: "Table-summ",
                      },  {
                        name: '',
                        processor: object => <Button className="App-icon-button" size={"sm"}
                                                     onClick={() => this.deleteAppearance(object.id)}><img className="App-icon-button" src={minus}  alt={"Visszavon."} data-tip="Visszavonás"/><ReactTooltip /></Button>,
                        className: "Table-btn",
                      }
                      ]
                    }
                    />

                  <Modal className="modal-dialog_mid" id='appearance-batch' headerName="Felület hozzáadása">
                    <AppearanceFormBatch addAppearance={this.addToAppearances}/>
                  </Modal>
                    </InputGroup>
                </Col>
                  <Col className="pl-0 text-right">
                    {<Button  className="App-btn-small"  color="primary"
                             onClick={() => this.toggleAppearanceModal()}>Új</Button>}
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row className="justify-content-end">
                  <Col className="text-right">
                    <Button color="primary" onClick={this.createOrUpdate}>Mentés</Button>
                  </Col>
                </Row>
              </FormGroup>

            </Form>

    )
  }
}

const mapStateToProps = (state) => {
  const contractEditId = state.contract.contractId;
  const contractItemEditId = state.contractItem.contractItemId;
  return {
    contractItemEditId,
    contractEditId
  }
};

const mapDispatchToProps = dispatch => ({
  openContractItem: (id) => {
    dispatch(openContractItem(id));
  },
  closeContractItem: () => {
    dispatch(closeContractItem());
  },
  getContractItem: (id) => {
    return dispatch(getRequest('/kisokostest/contractitems/?id=' + id))
  },
  getContract: (id) => {
    return dispatch(getRequest('/kisokostest/contractscalc/?id=' + id))
  },
  getAppearances: (id) => {
    return dispatch(getRequest('/kisokostest/appearances/?contract_item=' + id))
  },
  getPayments: (id) => {
    return dispatch(getRequest('/kisokostest/payments/?contract_item=' + id))
  },
  getProducts: () => {
    let today = new Date();
    return dispatch(getRequest("/kisokostest/productsall/?press_done__gte=" + today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate()))
  },
  getProductsAll: () => {
    return dispatch(getRequest('/kisokostest/productsall/'))
  },
  createEntry: (data) => {
    
    return dispatch(postRequest('/kisokostest/customcontractitems/', data))
  },
  deleteEntry: (id) => {
    return dispatch(deleteRequest('/kisokostest/batchcontractitem/' + id))
  },
  saveEntry: (id, data) => {
    return dispatch(putRequest('/kisokostest/batchcontractitem/' + id, data))
  },
  setEdited: (id) => {
    dispatch(openContractItem(id))
  },
  requery: () => {
    dispatch(invalidateQuery('contract-items'));
  },
  setData: (id, data) => {
    dispatch(setData(id, data));
  },
  refreshAppearances: () =>{
    dispatch(invalidateQuery('appearance-batch'))
  },
  toggleModal: id => {
    dispatch(toggleModal(id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractItemFormBatch);
