import React, {Component} from 'react';
import {deleteRequest, getRequest, postRequest, putRequest} from "../actions/api";
import {addQuery, invalidateQuery, removeQuery} from "../actions/query";
import {addModal, toggleModal} from "../actions/modal";
import {connect} from "react-redux";
import {Button, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row} from "reactstrap";
import {getPropertyOrEmptyString, getPropertyOrUndefined, getPropertyOrZero, vatkey} from "../Util";
import {openContract} from "../actions/contract";
import QueryTable from "../components/QueryTable";
import Modal from "../components/Modal";
import {closeContractItem, openContractItem} from "../actions/contractItem";
import ContractItemFormBatch from "./ContractItemFormBatch";
import ContractItemFormSingle from "./ContractItemFormSingle";
import edit from "../icons/edit.png";
import ReactTooltip from "react-tooltip";

class ContractForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client_name: '',
      client_id: undefined,
      contract_number: '',
      contract_date: '',
      process_date: '',
      net: 0,
      vat: 0,
      paid_calc: 0,
      billed_calc: 0,
      debt_calc: 0,
      processor_name: '',
      salesperson_name: '',
      processor_id: undefined,
      salesperson_id: undefined,
      caretaker_id: undefined,
      caretaker_name: '',
      colleagues: [],
      contract_items: [],
      contract_item_id: undefined,
      comment: '',
      paymentMethods: [],
      payment_method_id: undefined,
      payment_method_type: '',
      client_file: [],
      isContractEditing: false
    };
  }

  getClientObject(id) {
    this.props.getClient(id).then(response => {
      
      this.setState({
        client_name: getPropertyOrEmptyString(response.data[0], 'client_name'),
        client_id: getPropertyOrEmptyString(response.data[0], 'id'),
        contract_number: '00',
        salesperson_id:getPropertyOrZero(response.data[0],'salesperson.id'),
        salesperson_name:getPropertyOrEmptyString(response.data[0],'salesperson.name'),
        client_file: [9],
      })
    }).catch(function (error) {

    });
  }

  getContractObject(id) {
    this.props.getContract(id).then(response => {
      this.setState({
        client_id: getPropertyOrUndefined(response.data.results[0], 'client.id'),
        client_name: getPropertyOrEmptyString(response.data.results[0], 'client.client_name'),
        contract_number: getPropertyOrEmptyString(response.data.results[0], 'contract_number'),
        contract_date: getPropertyOrUndefined(response.data.results[0], 'contract_date'),
        process_date: getPropertyOrEmptyString(response.data.results[0], 'process_date'),
        net: getPropertyOrEmptyString(response.data.results[0], 'net_calc').toLocaleString("hu-HU"),
        paid_calc: getPropertyOrEmptyString(response.data.results[0], 'paid').toLocaleString("hu-HU"),
        billed_calc: getPropertyOrEmptyString(response.data.results[0], 'billed').toLocaleString("hu-HU"),
        debt_calc: getPropertyOrEmptyString(response.data.results[0], 'debt').toLocaleString("hu-HU"),
        vat: getPropertyOrZero(response.data.results[0], 'vat'),
        payment_method_id: getPropertyOrUndefined(response.data.results[0], 'payment_method.id'),
        payment_method_type: getPropertyOrEmptyString(response.data.results[0], 'payment_method.type'),
        processor_name: getPropertyOrEmptyString(response.data.results[0], 'processor.name'),
        salesperson_name: getPropertyOrEmptyString(response.data.results[0], 'salesperson.name'),
        processor_id: getPropertyOrUndefined(response.data.results[0], 'processor.id'),
        salesperson_id: getPropertyOrUndefined(response.data.results[0], 'salesperson.id'),
        comment: getPropertyOrEmptyString(response.data.results[0], 'comment'),
        isContractEditing: true

      });
    }).catch(function (error) {
    });
  }

  componentDidMount() {
    this.props.getActiveColleagues().then((response) => {
      let colleagues = [];
      response.data.results.map((i) => colleagues = [...colleagues, i]);
      this.setState({
        colleagues: colleagues,
        salesperson_name: this.state.salesperson_name ? this.state.salesperson_name : colleagues[0].name,
        processor_name: this.state.processor_name ? this.state.processor_name : colleagues[0].name,
        salesperson_id: this.state.salesperson_id ? this.state.salesperson_id : colleagues[0].id,
        processor_id: this.state.processor_id ? this.state.processor_id : colleagues[0].id
      });
    }).catch(function (error) {

    });
    this.props.getPaymentMethods().then((response) => {
      let paymentMethods = [];
      response.data.map((i) => paymentMethods = [...paymentMethods, i]);
      this.setState({
        paymentMethods: paymentMethods,
        payment_method_id: this.state.payment_method_id ? this.state.payment_method_id : paymentMethods[0].id,
        payment_method_type: this.state.payment_method_type ? this.state.payment_method_type : paymentMethods[0].type
      });
    }).catch(function (error) {

    });
    if (this.props.clientEditId !== undefined && this.props.contractEditId === undefined) {

      
      
      this.getClientObject(this.props.clientEditId);
    }
    if (this.props.contractEditId !== undefined) {
      this.getContractObject(this.props.contractEditId);
    }
  }


  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => {
      const newState = {...prevState};
      newState[name] = value;
      return newState;
    });
  };

  handleSalesperson = e => {
    this.handleChange(e);
    this.setState({
      salesperson_id: e.target.value
    });
  };

  handleProcessor = e => {
    this.handleChange(e);
    this.setState({
      processor_id: e.target.value
    });
  };

  handlePaymentMethod = e => {
    this.handleChange(e);
    this.setState({
      payment_method_id: e.target.value
    });
  };

  stateToObject = () => {
    return ({
      contract_number: this.state.contract_number,
      contract_date: this.state.contract_date,
      process_date: this.state.process_date,
      comment: this.state.comment,
      payment_method: {
        id: this.state.payment_method_id || 1,
      },
      processor: {
        id: this.state.processor_id || 5000214,
      },
      salesperson: {
        id: this.state.salesperson_id,
        office: 5,
      },
      client:{
        name: this.state.client_name,
        id: this.state.client_id,
        billing_address: {
        },
        shipping_address:{
        },
        client_type: {
        },
        client_file: this.state.client_file,
      }
    });
  };

  createOrUpdate = () =>{
    if(this.props.contractEditId !== undefined)
      this.props.saveEntry(this.props.contractEditId,this.stateToObject()).then(() => {
        this.props.requery();
        if(this.props.refreshClient)
          this.props.refreshClient();
        this.props.toggleModal(this.props.modalId);
      });
    else
      this.props.createEntry(this.stateToObject()).then((response) => {
        this.props.setEdited(response.data.id);
        this.props.addQuery('contract-items', '/kisokostest/contractitemscalc/?contract=' + response.data.id);
        if(this.props.refreshClient)
          this.props.refreshClient();
        this.props.requery();
      }).catch(function (error) {

      });
  };

  deleteEntry = () =>{
    if(this.props.contractEditId !== undefined){
      this.props.deleteEntry(this.props.contractEditId).then(() => {
        this.props.requery();
      });
      this.props.toggleModal('create-edit-contract');
    }
  };

  toggleContractItemModal = (editId = undefined) => {
    if (editId !== undefined) {
      this.props.openContractItem(editId);
      this.props.toggleModal('contract-item-single');
    } else {
      this.props.closeContractItem();
      this.props.toggleModal('contract-item-batch');
    }
  };

  refreshContract = () =>{
    this.getContractObject(this.props.contractEditId);
  };

  render(){

    let contractNumber = this.state.isContractEditing ? this.state.contract_number : "A rendszer generálja automatikusan.";

    return(
    <Form>
      <FormGroup>
                <Row >
                  <Col md="6" className={"pl-5 "}>
                    <InputGroup>
                      <InputGroupAddon  color="primary" addonType="prepend">
                        <InputGroupText className="input-group-text float-right">
                          Ügyfélnév
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input disabled type="text" name="client_name" id="client_name" readOnly onChange={this.handleChange} value={this.state.client_name}/>
                    </InputGroup>

                    <InputGroup>
                      <InputGroupAddon  color="primary" addonType="prepend">
                        <InputGroupText className="input-group-text float-right">
                          Sorszám
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input  type="text" 
                              name="contract_number" 
                              id="contract_number" 
                              onChange={this.handleChange} 
                              value={contractNumber} 
                              disabled/>
                    </InputGroup>

                    <InputGroup>
                      <InputGroupAddon  color="primary" addonType="prepend">
                        <InputGroupText className="input-group-text float-right font-weight-bold">
                          Kelte*
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input  type="date" 
                              min="2000-01-01" 
                              max="2025-12-31" 
                              name="contract_date" 
                              id="contract_date" 
                              onChange={this.handleChange} 
                              value={this.state.contract_date}/>
                    </InputGroup>

                    <InputGroup>
                      <InputGroupAddon  color="primary" addonType="prepend">
                        <InputGroupText className="input-group-text float-right">
                          Üzletkötő
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input  type="select"
                              name="salesperson_name" 
                              id="salesperson_name"
                              onChange={this.handleSalesperson} 
                              value={this.state.salesperson_id}>
                        {this.state.colleagues.map(i => <option key={i.id} value={i.id}>{i.name}</option>)}
                      </Input>
                    </InputGroup>

                    <InputGroup>
                      <InputGroupAddon className="input-group-addon" addonType="prepend">
                        <InputGroupText className="input-group-text font-weight-bold ">
                          Mód*
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input  type="select"
                              name="payment_method_type" 
                              id="payment_method_type" 
                              onChange={this.handlePaymentMethod} 
                              value={this.state.payment_method_id}>
                        {this.state.paymentMethods.map(i => <option key={i.id} value={i.id}>{i.type}</option>)}
                      </Input>
                    </InputGroup>
                  </Col>

                  <Col md="6" className="App-fill-width pl-5 ">
                    <InputGroup>
                      <InputGroupAddon  color="primary" addonType="prepend">
                        <InputGroupText className="input-group-text float-right">
                          Feldolgozó
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input  type="select" 
                              name="processor_name" 
                              id="processor_name" 
                              onChange={this.handleProcessor} 
                              value={this.state.processor_id}>
                        {this.state.colleagues.map(i => <option key={i.id} value={i.id}>{i.name}</option>)}
                      </Input>
                    </InputGroup>

                    <InputGroup>
                      <InputGroupAddon  color="primary" addonType="prepend">
                        <InputGroupText className="input-group-text float-right font-weight-bold">
                          Feld. dátuma*
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input  type="date"
                              min="2000-01-01" 
                              max="2025-12-31" 
                              name="process_date" 
                              id="process_date" 
                              onChange={this.handleChange} 
                              value={this.state.process_date}/>
                    </InputGroup>

                    <InputGroup className="input-group_textarea">
                      <InputGroupAddon className="input-group-addon align-items-baseline" addonType="prepend">
                        <InputGroupText className="input-group-text">
                          Komment
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input  className="textarea" 
                              type="textarea"  
                              name="comment" 
                              id="comment"  
                              onChange={this.handleChange} 
                              value={this.state.comment}/>
                    </InputGroup>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>

                <Row className="mt-3" >
                  <Col md="10" className="pr-0" >
                    <InputGroup className="input-group_textarea">
                      <InputGroupAddon  className="input-group-addon align-items-baseline App-label App-label-title pr-3 font-weight-bolder " addonType="prepend">
                        <InputGroupText className="input-group-text">
                          Kiadványok
                        </InputGroupText>
                      </InputGroupAddon>

                      {this.props.contractEditId ? 
                        <QueryTable className="tbody-200"  
                                    id='contract-items'
                                    headers={
                                      [
                                        {
                                          name: 'Termék',
                                          processor: object => object.product.code,
                                          className: "Table-ID",
                                          orderName: "product__code"
                                        }, {
                                          name: 'Aktív zárás',
                                          processor: object => object.product.active_close,
                                          exportProcessor:  object => object.product.active_close,
                                          className: "Table-date",
                                          orderName: "product__active_close"
                                        }, {
                                          name: 'Számlaszám',
                                          processor: object => object.final_bill_number,
                                          className: "Table-invoice",
                                          orderName: "final_bill_number"
                                        },{
                                          name: 'Kötés',
                                          processor: object => object.net_price ? object.net_price.toLocaleString("hu-HU") : 0,
                                          exportProcessor: object => object.net_price ? object.net_price : 0,
                                          className: "Table-summ",
                                        },{
                                          name: 'Br. kötés',
                                          processor: object => Math.round(object.net_price * vatkey) ? Math.round(object.net_price * vatkey).toLocaleString("hu-HU") : 0,
                                          exportProcessor: object => Math.round(object.net_price * vatkey) ? Math.round(object.net_price * vatkey) : 0,
                                          className: "Table-summ",
                                        }, {
                                          name: 'Számlázott',
                                          processor: object => object.billed ? object.billed.toLocaleString("hu-HU") : 0,
                                          exportProcessor: object => object.billed ? object.billed : 0,
                                          className: "Table-summ",
                                        }, {
                                          name: 'Befizetett',
                                          processor: object =>  object.paid ?  object.paid.toLocaleString("hu-HU") : 0,
                                          exportProcessor: object => object.paid ?  object.paid : 0,
                                          className: "Table-summ",
                                        }, {
                                          name: 'Tartozás',
                                          processor:  object => object.billed ? (object.debt ? object.debt.toLocaleString("hu-HU") : 0):0,
                                          exportProcessor:  object => object.billed ? (object.debt ? object.debt : 0):0,
                                          className: "Table-summ",
                                        }, {
                                          name: 'Előlegtartozás',
                                          processor: object => object.advance_debt ? object.advance_debt.toLocaleString("hu-HU") : 0,
                                          exportProcessor:  object => object.advance_debt ? object.advance_debt : 0,
                                          className: "Table-summ",
                                        },{
                                          name: '',
                                          processor: object => <Button className="App-icon-button" size={"sm"} onClick={() => this.toggleContractItemModal(object.id)}><img className="App-icon-button" src={edit}  alt={"Szerk."} data-tip="Szerkesztés"/><ReactTooltip /></Button>,
                                          className: "Table-btn",
                                          ignoreExport: true,
                                        }
                                      ]
                                    }
                                    defaultURL={'/kisokostest/contractitemscalc/?contract=' + this.props.contractEditId}
                    /> :""}

                      <Modal className="modal-dialog_wide" id='contract-item-batch' headerName="Kiadványok hozzáadása">
                        <ContractItemFormBatch refreshContract={this.refreshContract}/>
                      </Modal>

                      <Modal className="modal-dialog_wide" id='contract-item-single'  headerName="Kiadvány szerkesztése">
                        <ContractItemFormSingle refreshContract={this.refreshContract}/>
                      </Modal>
                    </InputGroup>

                  </Col>

                  <Col md="2" className="pl-0" >

                    <Row className="mb-2">
                      <Col className="text-right">
                        {this.props.contractEditId ? 
                          <Button color="primary" onClick={() => this.toggleContractItemModal()}>Új Kiadvány</Button> 
                          : ''
                        }
                      </Col>
                    </Row>

                    <InputGroup>
                      <InputGroupAddon  color="primary" addonType="prepend"><InputGroupText
                        className="input-group-text float-right input-group-short">Kötés</InputGroupText></InputGroupAddon>
                      <Input type="text" className="input-currency" disabled name="net" id="net" onChange={this.handleChange} value={this.state.net}/>
                    </InputGroup>

                    <InputGroup>
                      <InputGroupAddon  color="primary" addonType="prepend"><InputGroupText
                        className="input-group-text float-right  input-group-short">Befiz</InputGroupText></InputGroupAddon>
                      <Input type="text" className="input-currency" disabled name="paid_calc" id="paid_calc" onChange={this.handleChange} value={this.state.paid_calc}/>
                    </InputGroup><InputGroup>

                      <InputGroupAddon  color="primary" addonType="prepend"><InputGroupText
                        className="input-group-text float-right input-group-short">Számlázott</InputGroupText></InputGroupAddon>
                      <Input type="text" className="input-currency" disabled name="billed_calc" id="billed_calc" onChange={this.handleChange} value={this.state.billed_calc}/>
                    </InputGroup>
                  </Col>
                </Row>
              </FormGroup>

      <FormGroup>
        <Row className="justify-content-end">
          <Col className="text-right">
            <Button color="primary" onClick={this.createOrUpdate}>Mentés</Button>
          </Col>
        </Row>
      </FormGroup>
    </Form>
    )
  }
}
const mapStateToProps = (state) => {
  const contractEditId = state.contract.contractId;
  const clientEditId = state.client.clientId;
  const modals = state.modal;
  return{
    clientEditId,
    contractEditId,
    modals
  }
};

const mapDispatchToProps = dispatch => ({
  openContractItem: (id) =>{
    dispatch(openContractItem(id));
  },
  closeContractItem: () =>{
    dispatch(closeContractItem());
  },
  getClient: (id) =>{
    return dispatch(getRequest('/kisokostest/clientscalc/?id=' +id))
  },
  getContract: (id) =>{
    return dispatch(getRequest('/kisokostest/contractscalc/?id=' +id))
  },
  getActiveColleagues: () =>{
    return dispatch(getRequest('/kisokostest/colleagues/?get_all=1&active=True'))
  },
  getPaymentMethods: () =>{
    return dispatch(getRequest('/kisokostest/paymentmethods/'))
  },
  createEntry: (data) => {
    return dispatch(postRequest('/kisokostest/contracts/',data))
  },
  deleteEntry: (id) =>{
    return dispatch(deleteRequest('/kisokostest/contracts/' + id))
  },
  saveEntry: (id,data) =>{
    return dispatch(putRequest('/kisokostest/contracts/' +id,data))
  },
  setEdited: (id) =>{
    dispatch(openContract(id))
  },
  addQuery: (id,relativeURL) =>{
    dispatch(addQuery(id, relativeURL))
  },
  removeQuery: (id) => {
    dispatch(removeQuery(id));
  },
  requery: () =>{
    dispatch(invalidateQuery('contracts'))
  },
  addModal: id => {
    dispatch(addModal(id));
  },
  toggleModal: id => {
    dispatch(toggleModal(id));
  }
});

export default connect(mapStateToProps,mapDispatchToProps)(ContractForm);
