import QueryPage from "../components/QueryPage";
import edit from "../icons/edit.png";
import payment from "../icons/payment.png";
import sales from "../icons/sales.png";
import FilterFormClient from "../FilterForm/FilterFormClient";
import {Button, Col, Label} from "reactstrap";
import React, {Component} from "react";
import {addQuery, invalidateQuery} from "../actions/query";
import {connect} from "react-redux";
import {getRequest, putRequest} from "../actions/api";
import {closeClient, openClient} from "../actions/client";
import ClientForm from "../DetailedForm/ClientForm";
import ReactTooltip from 'react-tooltip'
import {addModal, toggleModal} from '../actions/modal.js';
import {saveFile} from "../Util";
import Modal from "../components/Modal";


class ClientPage extends Component {
  updateActive= (editObject = undefined) =>{
    if(editObject !== undefined){
      editObject.inactive = !editObject.inactive;
      this.props.updateClient(editObject);
    }
  };

  toggleEditModal=(object = undefined) =>{
    if(object !== undefined){
      this.props.openClient(object.id)
    }
    else{
      this.props.openClient()
    }

    this.props.toggleModal('client-edit');
  };

  toggleRangeModal=(object = undefined) =>{
    if(object !== undefined){
      this.props.openClient(object.id)
    }
    else{
      this.props.openClient()
    }

    this.props.toggleModal('payments-export');
  };

  isSame = (object = undefined) =>{
    return (object.shipping_address.address === object.billing_address.address &&
      object.shipping_address.postcode === object.billing_address.postcode &&
      object.shipping_address.city === object.billing_address.city &&
      (object.mailing_name === null || object.mailing_name === ''))
  };

  queryPayments = (clientId) => {
    this.props.getPayment(clientId , "1970.01.01")
      .then(response => {
        const csvArray = [];
        const rawArray = response.data;

        csvArray.push('Szerződésszám;Szerződés dátuma;Ügyfél;Irányítószám;Település;Számlázási cím;Kiadvány;Nettó;Bruttó;Befizetés dátuma;Számlaszám');
        rawArray.forEach(row => {
          csvArray.push(row.join(';'));
        });

        saveFile('Befizetesek_' + clientId + '.csv', csvArray.join('\n'));
      });
  };

  exportDebtByPClient = (id) =>{
    this.props.getDebtByClient(id)
      .then(response => {
        const csvArray = [];
        const rawArray = response.data;

        csvArray.push('Szerződésszám;Szerződés dátuma;Ügyfél;Irányítószám;Település;Számlázási cím;Kiadvány;Nettó tartozás;Bruttó tartozás;Befizetés dátuma;Számlaszám');

        rawArray.forEach(row => {
          // Insert empty cell after every 8th cell in every row (payment date has to be empty)
          row.splice(9, 0,"");

          csvArray.push(row.join(';'));
        });

        saveFile('UgyfelTartozasok_'+ id + '.csv', csvArray.join('\n'));
      });
  };

  render() {
    return (
      <div>
        <QueryPage
          queryTableID='clients'
          filterForm={<FilterFormClient/>}
          pageURL="/kisokostest/clients"
          optionalElements={
            [
              <Col className="col-1 justify-content-md-start text-left App-page-label">
                <Label className="App-label App-label-title">Ügyfél</Label>
              </Col>,
              <Col className="col-1 justify-content-md-start text-left">
                <Button color="primary" onClick={() => this.toggleEditModal(undefined)}>Új</Button>
              </Col>
            ]
          }
          headers={
            [
            {
              name: 'Vevőkód',
              processor: object => object.id,
              className: "Table-ID",
              orderName: "id"
              }, {
              name: 'Számlázási név',
              processor: object => object.client_name,
              className: "Table-invoice_name",
              orderName: "client_name"
            }, {
              name: 'Adószám',
              processor: object => object.tax_number.slice(0, 8) + '-' + object.tax_number.charAt(8) + '-' + object.tax_number.slice(9, 11),
              className: "Table-city",
              orderName: "tax_number"
            },{
              name: 'Irsz.',
              processor: object => object.billing_address.postcode,
              className: "Table-postcode",
              orderName: "billing_address__postcode"
            }, {
              name: 'Város',
              processor: object => object.billing_address.city,
              className: "Table-city",
              orderName: "billing_address__city"
            }, {
              name: 'Cím',
              processor: object => object.billing_address.address,
              className: "Table-address",
              orderName: "billing_address__address"
            }, {
              name: 'Telefonszám',
              processor: object => object.phone_number1,
              className: "Table-phone",
              orderName: "phone_number1"
            }, {
              name: 'E-mail',
              processor: object => object.email_address,
              className: "Table-email",
              orderName: "email_address"
            }, {
              name: 'Számlaküldési email',
              processor: object => object.szamlakuldesiemail,
              className: "Table-email",
              orderName: "szamlakuldesiemail"
            },{
              name: 'Lev.Cím',
              processor: object => <input type="checkbox" disabled defaultChecked={!this.isSame(object)}/>,
              className: "Table-chkbox",
              ignoreExport: true
            }, {
              name: 'Aktív',
              processor: object => <input type="checkbox"  color={"primary"} onChange={() => this.updateActive(object) }  defaultChecked={!object.inactive}/>,
              className: "Table-chkbox",
              ignoreExport: true

            },{
              name: '',
              processor: object => 
                <Button  className="App-icon-button" size={'sm'} onClick={() => this.toggleEditModal(object)}>
                  <img className="App-icon-button" src={edit}  alt={"Szerk."} data-tip="Szerkesztés"/>
                  <ReactTooltip />
                </Button>,
              className: "Table-btn",
              ignoreExport: true
            },{
              name: '',
              processor: object => 
                <Button className="App-icon-button" size={'sm'} onClick={() => this.queryPayments(object.id)}>
                  <img className="App-icon-button" src={payment}  alt={"Befiz."} data-tip="Befizetések"/>
                  <ReactTooltip />
                </Button>,
              className: "Table-btn",
              ignoreExport: true
            },{
              name: '',
              processor: object => 
                <Button className="App-icon-button" size={'sm'} onClick={() => this.exportDebtByPClient(object.id)}>
                  <img className="App-icon-button" src={sales}  alt={"Tart."} data-tip="Tartozások"/>
                  <ReactTooltip />
                </Button>,
              className: "Table-btn",
              ignoreExport: true
            },{
              name: '',
              processor: object=> "",
              className: "Table-filler",
              ignoreExport: true
            }
            ]
          }
        />
        <Modal className="modal-dialog_wide" id='client-edit' headerName="Ügyfél adatlap">
          <ClientForm/>
        </Modal>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  const editClientId = state.client.clientId;
  return{
    editClientId
  }
};

const mapDispatchToProps = dispatch => ({
  updateClient: (client) =>{
    dispatch(putRequest('/kisokostest/clients/' +client.id,client))
  },
  closeClient: () =>{
    dispatch(closeClient())
  },
  openClient: (id) =>{
    dispatch(openClient(id));
  },
  addQuery: (id, relativeURL) => {
    dispatch(addQuery(id, relativeURL));
  },
  refreshQuery: id => {
    dispatch(invalidateQuery(id));
  },
  addModal: id => {
    dispatch(addModal(id));
  },
  getPayment: (id,startDate) => {
    return dispatch(getRequest('/kisokostest/paymentsfromclientlist?client_id=' + id + '&start_date=' + startDate));
  },
  toggleModal: id => {
    dispatch(toggleModal(id));
  },
  getDebtByClient: (id) =>{
    return dispatch(getRequest('/kisokostest/debtlistbyclient?client_id=' + id));
  }
});

export default connect(mapStateToProps,mapDispatchToProps)(ClientPage);

