import { Container, Row, Col, Button, Alert, Form, FormGroup, Input, Label } from "reactstrap";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getRequest } from "../actions/api";
import ReportsTable from '../components/ReportsTable'
import Select from "react-select";
import moment from 'moment';

const API_ROOT_URL = "/kisokostest"

const reportTypes = [
    { label: 'Daily', value: 'daily-salespersons' },
    { label: 'Weekly', value: 'weekly' },
]

class SalespersonReports extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedDay: moment().format('YYYY-MM-DD'),
            reportData: [],
            sendEmail: false,
            sendDebugEmails: false,
            reportType: reportTypes[0]
        };
    }

    generateDailyReportsForSalespersons = () => {
        const urlParamters = `type=${this.state.reportType.value}&day=${this.state.selectedDay}&sendEmail=${this.state.sendEmail}&sendDebugEmails=${this.state.sendDebugEmails}`
        this.props.generateDailyReportsForSalespersons(urlParamters).then(response => {
            console.log('generateDailyReportsForSalespersons', response.data);
            this.setState({ reportData: response.data.data })
        })
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col>
                        <h3>Napi jelentés az üzletkötőknek</h3>
                        <div style = {{ width: '50%', margin: '0 auto' }}>
                            <Alert className="default-alert" color="danger"><b>Production</b>: a valódi üzletkötők kapnak emaileket!</Alert>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        value={this.state.sendEmail}
                                        onChange={() => this.setState({ sendEmail: !this.statesendEmail })}
                                    />{' '}
                                Send production emails
                                </Label>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <Form>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        value={this.state.sendDebugEmails}
                                        onChange={() => this.setState({ sendDebugEmails: !this.sendDebugEmails })}
                                    />{' '}
                                Send debug emails
                                </Label>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Select
                            className = "App-select-reports"
                            options={reportTypes}
                            defaultValue={reportTypes[0]}
                            onChange={(event) => this.setState({
                                reportType: event,
                                reportData: []
                            })}
                        />
                    </Col>
                </Row>
                <Row style = {{ margin: 10 }}>
                    <Col>
                        <input
                            type="date"
                            value={this.state.selectedDay}
                            style={{ width: "10em", paddingLeft: "5px" }}
                            onChange={(event) => this.setState({ selectedDay: event.target.value })}
                            id="report_date"
                            name="report_date"
                            min="2000-01-01"
                            max="2025-12-31"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button outline color="danger" onClick={this.generateDailyReportsForSalespersons}>Emailek kiküldése</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.state.reportData.length > 0 && <ReportsTable data = {this.state.reportData} emailResponse = {[]} />}
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state) => { return state };

const mapDispatchToProps = dispatch => ({
    generateDailyReportsForSalespersons: (parameters) => {
        return dispatch(getRequest(`${API_ROOT_URL}/reports?${parameters}`));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(SalespersonReports);

