import {Button, Col, Form, Input, Row,} from "reactstrap"
import React from "react";
import InputGroup from "reactstrap/es/InputGroup";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import InputGroupText from "reactstrap/es/InputGroupText";

export default class FilterFormClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client_name: '',
      contact_name: '',
      tax_number: '',
      billing_address: '',
      product_code: '',
      phone_number: '',
      salesperson_name: '',
      inactive: '',
      keyword: '',
      email_address: '',
      szamlakuldesiemail: '',
    };
  }

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => {
      const newState = {...prevState};
      newState[name] = value;
      return newState;
    });
  };

  onRadioBtnClick(inactive) {
    this.setState({ inactive });
  }

  render() {
    return (
      <div>
        <Form onSubmit={e => {
          e.preventDefault();
          this.props.handleSubmit(this.state);
        }}>
          <Row style={{marginRight: 0}} className="mb-3" >
            <Col md={6} className="App-col-l-search">
              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend"><InputGroupText
                  className="input-group-text float-right">Ügyfél neve</InputGroupText></InputGroupAddon>
                <Input className="input-search" onChange={this.handleChange} value={this.state.client_name} type="text" name="client_name"
                       id="client_name" placeholder="Név"/>
              </InputGroup>
              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend"><InputGroupText
                  className="input-group-text float-right">Adószám</InputGroupText></InputGroupAddon>
                <Input className="input-search" onChange={this.handleChange} value={this.state.tax_number} type="text" name="tax_number"
                       id="tax_number" placeholder="Adószám"/>
              </InputGroup>
              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend"><InputGroupText
                  className="input-group-text float-right">Számlázási cím</InputGroupText></InputGroupAddon>
                <Input className="input-search" onChange={this.handleChange} value={this.state.billing_address} type="text" name="billing_address"
                       id="billing_address" placeholder="Számlázási cím"/>
              </InputGroup>
              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend"><InputGroupText
                  className="input-group-text float-right">Kapcsolattartó</InputGroupText></InputGroupAddon>
                <Input className="input-search" onChange={this.handleChange} value={this.state.contact_name} type="text" name="contact_name"
                       id="contact_name" placeholder="Kapcsolattartó"/>
              </InputGroup>

              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend"><InputGroupText
                  className="input-group-text float-right">Telefonszám</InputGroupText></InputGroupAddon>
                <Input className="input-search" onChange={this.handleChange} value={this.state.phone_number} type="text" name="phone_number"
                       id="phone_numer" placeholder="Telefonszám"/>
              </InputGroup>

              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend"><InputGroupText
                  className="input-group-text float-right">Email cím</InputGroupText></InputGroupAddon>
                <Input className="input-search" onChange={this.handleChange} value={this.state.email_address} type="text" name="email_address"
                       id="email_address" placeholder="Email-cím"/>
              </InputGroup>

            </Col>
            <Col md={6} className="App-col-r-search  flex-column">


              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend"><InputGroupText
                  className="input-group-text float-right">Kiadvány kód</InputGroupText></InputGroupAddon>
                <Input className="input-search" onChange={this.handleChange} value={this.state.product_code} type="text" name="product_code"
                       id="product_code" placeholder="Kiadvány kód"/>
              </InputGroup>

              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend"><InputGroupText
                  className="input-group-text float-right">Üzletkötő</InputGroupText></InputGroupAddon>
                <Input className="input-search" onChange={this.handleChange} value={this.state.salesperson_name} type="text" name="salesperson_name"
                       id="salesperson_name" placeholder="Üzletkötő"/>
              </InputGroup>

              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend"><InputGroupText
                  className="input-group-text float-right">Számlaküldési email cím</InputGroupText></InputGroupAddon>
                <Input className="input-search" onChange={this.handleChange} value={this.state.szamlakuldesiemail} type="text" name="szamlakuldesiemail"
                       id="szamlakuldesiemail" placeholder="Számlaküldési email cím"/>
              </InputGroup>

              <InputGroup >
                <InputGroupAddon color="primary" addonType="prepend"><InputGroupText
                  className="input-group-text float-right">Címszó</InputGroupText></InputGroupAddon>
                <Input className="input-search" onChange={this.handleChange} value={this.state.keyword} type="text" name="keyword"
                       id="keyword" placeholder="Címszó"/>
              </InputGroup>



              <InputGroup className="App-searchLine" >
                <InputGroupAddon color="primary" addonType="prepend"><InputGroupText
                  className="input-group-text float-right"></InputGroupText></InputGroupAddon>
                  <Col  className="p-0 App-Option-Select" >
                    <Button className="App-btn-group btn "  onClick={() => this.onRadioBtnClick('False')} active={this.state.inactive === 'False'} >Aktív</Button>
                  </Col>
                <Col className="p-0 App-Option-separator">
                  |
                </Col>

                  <Col  className="p-0 App-Option-Select">
                    <Button className="App-btn-group btn "  onClick={() => this.onRadioBtnClick('True')} active={this.state.inactive === 'True'} >Inaktív</Button>
                  </Col>
                <Col className="p-0 App-Option-separator">
                  |
                </Col>
                  <Col  className="p-0 App-Option-Select">
                    <Button className="App-btn-group btn " onClick={() => this.onRadioBtnClick('')} active={this.state.inactive === ''}>Mind</Button>
                  </Col>

                  <Col  className="p-0">
                    <div className="text-right">
                      <Button outline color="primary">Keresés</Button>
                    </div>
                  </Col>

              </InputGroup>

            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
