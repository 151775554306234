import React, {Component} from "react";
import QueryPage from "../components/QueryPage";
import {Button, Col, Label} from "reactstrap";
import FilterFormContract from "../FilterForm/FilterFormContract";
import {toggleModal} from "../actions/modal";
import {connect} from "react-redux";
import {closeContract, openContract} from "../actions/contract";
import Modal from "../components/Modal";
import ContractForm from "../DetailedForm/ContractForm";
import ContractDeleteForm from "../DetailedForm/ContractDeleteForm";
import edit from "../icons/edit.png";
import {openClient} from "../actions/client";
import ReactTooltip from "react-tooltip";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

class ContractPage extends Component {
  toggleEditModal=(object = undefined) =>{
    if(object !== undefined){
      this.props.openContract(object.id);
      this.props.openClient(object.client.id)

    }
    else{
      this.props.openContract()
    }

    this.props.toggleModal('contract-edit');
  };

  toggleDeleteModal=(object = undefined) =>{
    if(object !== undefined){
      this.props.openContract(object.id);
      this.props.openClient(object.client.id)

    }
    else{
      this.props.openContract()
    }

    this.props.toggleModal('contract-delete');
  };

  render() {
    return (
      <div>
        <QueryPage
          queryTableID='contracts'
          filterForm={<FilterFormContract/>}
          pageURL="/kisokostest/contractscalc"
          optionalElements={
            [
              <Col className="col-2 justify-content-md-start text-left">
                <Label className="App-label App-label-title">Szerződés</Label>
              </Col>
            ]
          }
          headers={
            [
              {
                name: 'Szerződésszám',
                processor: object => object.contract_number,
                className: "Table-contract_no",
                orderName: "contract_number"
              }, {
                name: 'Ügyfél neve',
                processor: object => object.client.client_name,
                className: "Table-invoice_name",
                orderName: "client__client_name"
              }, {
                name: 'E-mail',
                processor: object => object.client.email_address,
                className: "Table-email",
                orderName: "client__email_address"
              }, {
                name: 'Dátum',
                processor: object => object.contract_date,
                className: "Table-date",
                orderName: "contract_date"
              }, {
                name: 'Irsz.',
                processor: object => object.client.billing_address.postcode,
                className: "Table-postcode",
                orderName: "billing_address__postcode"
              }, {
                name: 'Város',
                processor: object => object.client.billing_address.city,
                className: "Table-city",
                orderName: "client__billing_address__city"
              }, {
                name: 'Cím',
                processor: object => object.client.billing_address.address,
                className: "Table-address",
                orderName: "client__billing_address__address"
              }, {
                name: 'Üzletkötő',
                processor: object => object.salesperson.name,
                className: "Table-invoice_name",
                orderName: "salesperson__name"
              },{
                name: 'Kötés',
                processor: object => object.net_calc ? object.net_calc.toLocaleString("hu-HU"): 0,
                exportProcessor: object => object.net_calc ? object.net_calc : 0,
                className: "Table-summ"
              },{
                name: 'Befizetés',
                processor: object => object.paid ? object.paid.toLocaleString("hu-HU") : 0,
                exportProcessor:  object => object.paid ? object.paid : 0,
                className: "Table-summ"
              },{
                name: '',
                processor: object => <Button className="App-icon-button" size={'sm'} onClick={() => this.toggleEditModal(object)}><img className="App-icon-button" src={edit}  alt={"Szerk."} data-tip="Szerkesztés"/><ReactTooltip /></Button>,
                className: "Table-btn",
                ignoreExport: true
              },{
                name: '',
                processor: object=>  (object.isDraft) ? <FontAwesomeIcon className="faRed"  size="2x"  onClick={() => this.toggleDeleteModal(object)} cursor="pointer" icon={faTrash} data-tip="Törlés"><ReactTooltip /></FontAwesomeIcon> : null,
                className: "Table-btn",
                ignoreExport: true
              }
              ,{
                name: '',
                processor: object=> "",
                className: "Table-filler",
                ignoreExport: true
              }
            ]
          }
        />
        <Modal className="modal-dialog_mid" id='contract-edit' headerName="Szerződés">
          <ContractForm isParentContractPage={true}/>
        </Modal>
        <Modal className="modal-dialog_sml" id='contract-delete' headerName="Piszkozat törlése">
          <ContractDeleteForm isParentContractPage={true}/>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const contractEditId = state.contract.contractId;
  const modal = state.modal['contract-edit'];
  const modalOpen = modal ? modal.isOpen : false;
  return{
    contractEditId,
    modalOpen
  }
};

const mapDispatchToProps = dispatch => ({
  closeContract: () =>{
    dispatch(closeContract())
  },
  openContract: (id) =>{
    dispatch(openContract(id));
  },
  openClient: (id) =>{
    dispatch(openClient(id));
  },
  toggleModal: id => {
    dispatch(toggleModal(id));
  }
});

export default connect(mapStateToProps,mapDispatchToProps)(ContractPage)
