import React, {Component} from 'react';
import {deleteRequest} from "../actions/api";
import {invalidateQuery} from "../actions/query";
import {toggleModal} from "../actions/modal";
import {connect} from "react-redux";
import {Button, Col, Form, FormGroup, Row} from "reactstrap";

class ContractDeleteForm extends Component {

    deleteEntry = () =>{
        if(this.props.contractEditId !== undefined){
            this.props.deleteEntry(this.props.contractEditId).then(() => {
                this.props.requery();
            });
            this.props.toggleModal('contract-delete');
        }
    };

    closeModal = () =>{
        this.props.toggleModal('contract-delete');
    };

    render(){
        return(
        <Form>
            <FormGroup>
                <Row className="mt-3" >
                    <Col>
                        <div className='delete-contract-div'>
                            Biztosan törli a piszkozatot és a hozzá tartozó hirdetéseket?
                        </div>
                    </Col>
                </Row>
            </FormGroup>
            
            <FormGroup>
                <Row className="justify-content">
                    <Col className="text-left">
                        <Button color="danger" onClick={this.deleteEntry}>TÖRLÉS</Button>
                    </Col>

                    <Col className="text-right">
                        <Button color="primary" onClick={this.closeModal}>Nem</Button>
                    </Col>
                </Row>
            </FormGroup>
        </Form>
        )
    }
}

const mapStateToProps = (state) => {
  const contractEditId = state.contract.contractId;
  const clientEditId = state.client.clientId;
  const modals = state.modal;
  return{
    clientEditId,
    contractEditId,
    modals
  }
};

const mapDispatchToProps = dispatch => ({
  deleteEntry: (id) =>{
    return dispatch(deleteRequest('/kisokostest/contracts/' + id))
  },
  requery: () =>{
    dispatch(invalidateQuery('contracts'))
  },
  toggleModal: id => {
    dispatch(toggleModal(id));
  }
});

export default connect(mapStateToProps,mapDispatchToProps)(ContractDeleteForm);
