import {MODAL_ADD, MODAL_REMOVE, MODAL_TOGGLE} from '../actions/modal.js';

const modal = (state = {}, action) => {
  switch (action.type) {
  case MODAL_ADD: {
    const newState = {...state};
    newState[action.id] = {
      isOpen: false
    };
    return newState;
  }
  case MODAL_REMOVE: {
    const newState = {...state};
    delete newState[action.id];
    return newState;
  }
  case MODAL_TOGGLE: {
    const newState = {...state};
    newState[action.id].isOpen = !state[action.id].isOpen;
    return newState;
  }
  default:
    return state;
  }
};

export default modal;
