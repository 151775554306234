import {Button, Col, Form, Input, Row,} from "reactstrap"
import React from "react";
import InputGroup from "reactstrap/es/InputGroup";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import InputGroupText from "reactstrap/es/InputGroupText";

export default class FilterFormClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contract_number: '',
      client_name: '',
      salesperson: '',
      processor: '',
      date_gte: '',
      date_lte:'',
      process_date_gte: '',
      process_date_lte:'',
      product_code:'',
      invoice_number: '',
      isDraft: false,
    };
  }

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => {
      const newState = {...prevState};
      newState[name] = value;
      return newState;
    });
  };

  toggleCheckbox = e => {
    const {name} = e.target;
    this.setState(prevState => ({
      ...prevState,
      [name]: !prevState.isDraft
    }));

  };

  render() {
    return (
      <div>
        <Form onSubmit={e => {
          e.preventDefault();
          this.props.handleSubmit(this.state);
        }}>
          <Row style={{marginRight: 0}} className="mb-3">
            <Col md={6}>
              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right">Szerződészám</InputGroupText>
                </InputGroupAddon>
                <Input 
                  className="input-search" 
                  onChange={this.handleChange}
                  value={this.state.contract_number}
                  type="text"
                  name="contract_number" 
                  id="contract_number" 
                  placeholder="Szerződészám"/>
              </InputGroup>

              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right">Ügyfél neve</InputGroupText>
                </InputGroupAddon>
                <Input 
                  className="input-search"
                  onChange={this.handleChange}
                  value={this.state.client_name}
                  type="text"
                  name="client_name"
                  id="client_name" 
                  placeholder="Ügyfél neve"/>
              </InputGroup>

              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right">Számlaszám</InputGroupText>
                </InputGroupAddon>
                <Input 
                  className="input-search"
                  onChange={this.handleChange}
                  value={this.state.invoice_number}
                  type="text"
                  name="invoice_number"
                  id="invoice_number" 
                  placeholder="Számlaszám"/>
              </InputGroup>

              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right">Dátum</InputGroupText>
                </InputGroupAddon>
                <Input 
                  onChange={this.handleChange} 
                  value={this.state.date_gte}  
                  type="date" 
                  name="date_gte"
                  id="date_gte"
                  min="2000-01-01" 
                  max="2025-12-31" 
                  placeholder="Dátum nagyobb mint"
                />
                -
                <Input 
                  onChange={this.handleChange} 
                  value={this.state.date_lte} 
                  type="date" 
                  name="date_lte"
                  id="date_lte" 
                  min="2000-01-01" 
                  max="2025-12-31" 
                  placeholder="Dátum ig"
                />
              </InputGroup>
              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend">
                    <InputGroupText className="input-group-text float-right">Kizárólag piszkozatok megjelenítése</InputGroupText>
                </InputGroupAddon>
                <Input 
                  type="checkbox" 
                  id="isDraft" 
                  onChange={this.toggleCheckbox}
                  name="isDraft" 
                  addon 
                  className="mt-1"
                  defaultChecked={this.state.isDraft}
                  data-tip= {this.state.isDraft ? "Piszkozatokkal" : "Piszkozatok nélkül"}/>
              </InputGroup>

            </Col>
            <Col md={6}>
              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right">Üzletkötő</InputGroupText>
                </InputGroupAddon>
                <Input 
                  className="input-search"
                  onChange={this.handleChange}
                  value={this.state.salesperson}
                  type="text"
                  name="salesperson"
                  id="salesperson"
                  placeholder="Üzletkötő"/>
              </InputGroup>

              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right">Feldolgozó</InputGroupText>
                </InputGroupAddon>
                <Input 
                  className="input-search"
                  onChange={this.handleChange}
                  value={this.state.processor}
                  type="text" 
                  name="processor"
                  id="processor" 
                  placeholder="Feldolgozó"/>
              </InputGroup>

              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right">Feldolg. dátuma</InputGroupText>
                </InputGroupAddon>
                <Input 
                  onChange={this.handleChange}
                  value={this.state.process_date_gte}
                  type="date" 
                  name="process_date_gte"
                  id="process_date_gte" 
                  min="2000-01-01" 
                  max="2025-12-31" 
                  placeholder="Dátum nagyobb mint"
                />
                -
                <Input 
                  onChange={this.handleChange} 
                  value={this.state.process_date_lte}
                  type="date"
                  name="process_date_lte"
                  id="process_date_lte"
                  min="2000-01-01"
                  max="2025-12-31"
                  placeholder="Dátum ig"
                />
              </InputGroup>

              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right">Kiadvány kód</InputGroupText>
                </InputGroupAddon>
                <Input 
                  className="input-search" 
                  onChange={this.handleChange} 
                  value={this.state.product_code} 
                  type="text" 
                  name="product_code"
                  id="product_code" 
                  placeholder="Kiadvány kód"
                />
              </InputGroup>

              <InputGroup className="App-searchLine" >
                <Col className={"p-0"}>
                <div className="text-right">
                  <Button outline color="primary">Keresés</Button>
                </div>
                </Col>
              </InputGroup>
            </Col>

          </Row>
        </Form>

      </div>
    );
  }
}