import {combineReducers} from 'redux';
import auth from './auth';
import query from './query';
import client from "./client";
import modal from './modal';
import contract from "./contract";
import contractItem from "./contractItem";
import appearance from "./appearance";
import payment from "./payment";
import colleague from "./colleague";
import product from "./product";
import apierror from "./apiresponse";
import contractTracking from "./contractTracking";

export default combineReducers({
  auth,
  query,
  client,
  modal,
  contract,
  contractItem,
  contractTracking,
  appearance,
  payment,
  colleague,
  product,
  apierror
});
