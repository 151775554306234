import React, {Component} from 'react';
import {Button, Col, Form, FormGroup, Input, Row} from "reactstrap";
import {deleteRequest, getRequest, postRequest, putRequest} from "../actions/api";
import {toggleModal} from "../actions/modal";
import {connect} from "react-redux";
import {getPropertyOrEmptyString, getPropertyOrZero} from "../Util";
import InputGroup from "reactstrap/es/InputGroup";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import InputGroupText from "reactstrap/es/InputGroupText";
import {openProduct} from "../actions/product";
import {invalidateQuery} from "../actions/query";

class ProductForm extends Component{
  constructor(props) {
    super(props);
    this.state = {
      number_of_copies: 0,
      payment_deadline: '',
      completion_date: '',
      invoice_date: '',
      active_close: '',
      sales_close: '',
      list_send_date: '',
      correcture: '',
      advance_payment_date: '',
      salesperson_signature: '',
      sent_to_press: '',
      press_done: '',
      spread_date: '',
      office_id: '',
      office_name: '',
      code: '',
      sum_sold: 0,
      sum_paid: 0,
      offices: [],
    };
  }

  getProductObject(id){
    this.props.getProduct(id).then((response) => {
      this.setState({
        number_of_copies: getPropertyOrZero(response.data.results[0], 'number_of_copies'),
        payment_deadline: getPropertyOrEmptyString(response.data.results[0], 'payment_deadline'),
        completion_date: getPropertyOrEmptyString(response.data.results[0], 'completion_date'),
        invoice_date: getPropertyOrEmptyString(response.data.results[0], 'invoice_date'),
        active_close: getPropertyOrEmptyString(response.data.results[0], 'active_close'),
        sales_close: getPropertyOrEmptyString(response.data.results[0], 'sales_close'),
        list_send_date: getPropertyOrEmptyString(response.data.results[0], 'list_send_date'),
        correcture: getPropertyOrEmptyString(response.data.results[0], 'correcture'),
        advance_payment_date: getPropertyOrEmptyString(response.data.results[0], 'advance_payment_date'),
        salesperson_signature: getPropertyOrEmptyString(response.data.results[0], 'salesperson_signature'),
        sent_to_press: getPropertyOrEmptyString(response.data.results[0], 'sent_to_press'),
        press_done: getPropertyOrEmptyString(response.data.results[0], 'press_done'),
        spread_date: getPropertyOrEmptyString(response.data.results[0], 'spread_date'),
        office_id: getPropertyOrZero(response.data.results[0], 'office.id'),
        office_name: getPropertyOrEmptyString(response.data.results[0], 'office.name'),
        code: getPropertyOrEmptyString(response.data.results[0], 'code'),
        sum_sold: getPropertyOrZero(response.data.results[0], 'sold'),
        sum_paid: getPropertyOrZero(response.data.results[0], 'paid'),
      });
    }).catch(function (error) {

    });
  }

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => {
      const newState = {...prevState};
      newState[name] = value;
      return newState;
    });
  };

  componentDidMount() {
    this.props.getOffices().then((response) => {
      let offices = [];
      response.data.map((i) => offices = [...offices, i]);
      this.setState({offices: offices});
      this.setState({office_id: this.state.office_id ? this.state.office_id : offices[0].id});
    }).catch(function (error) {

    });

    if (this.props.productEditId !== undefined) {
      this.getProductObject(this.props.productEditId);
    }
  }

  stateToObject = () => {

    return ({
      number_of_copies: this.state.number_of_copies,
      payment_deadline: this.state.payment_deadline === "" ? null : this.state.payment_deadline,
      completion_date: this.state.completion_date === "" ? null : this.state.completion_date,
      invoice_date: this.state.invoice_date === "" ? null : this.state.invoice_date,
      active_close: this.state.active_close === "" ? null : this.state.active_close,
      sales_close: this.state.sales_close === "" ? null : this.state.sales_close,
      list_send_date: this.state.list_send_date === "" ? null : this.state.list_send_date,
      correcture: this.state.correcture === "" ? null : this.state.correcture,
      advance_payment_date: this.state.advance_payment_date === "" ? null : this.state.advance_payment_date,
      salesperson_signature: this.state.salesperson_signature === "" ? null : this.state.salesperson_signature,
      sent_to_press: this.state.sent_to_press === "" ? null : this.state.sent_to_press,
      press_done: this.state.press_done === "" ? null : this.state.press_done,
      spread_date: this.state.spread_date === "" ? null : this.state.spread_date,
      code: this.state.code,
      office: {
        id: this.state.office_id,
      }
    });
  };

  createOrUpdate = () => {
    if (this.props.productEditId !== undefined)
      this.props.saveEntry(this.props.productEditId, this.stateToObject()).then(() => {
        this.props.requery();
        this.props.toggleModal(this.props.modalId);
      });
    else
      this.props.createEntry(this.stateToObject()).then((response) => {
        this.props.setEdited(response.data.id);
        this.props.requery();
        this.props.toggleModal(this.props.modalId);
      }).catch(function (error) {

      });
  };

  deleteEntry = () => {
    if (this.props.productEditId !== undefined) {
      this.props.deleteEntry(this.props.productEditId).then(() => {
        this.props.requery();
      });
    }
    this.props.toggleModal(this.props.modalId)
  };

  render() {
    return (
            <Form>

              <FormGroup>
                <Row>
                  <Col md={4} className={"pl-5 "}>
                    <InputGroup>
                      <InputGroupAddon  color="primary" addonType="prepend"><InputGroupText
                        className="input-group-text float-right">Iroda</InputGroupText></InputGroupAddon>
                      <Input type="select" name="office_id" id="office_id" onChange={this.handleChange} value={this.state.office_id}>
                        {this.state.offices.map(i => <option key={i.id} value={i.id}>{i.name}</option>)}
                      </Input>
                    </InputGroup>
                  </Col>
                  <Col md={4}>
                    <InputGroup>
                      <InputGroupAddon color="primary"
                                       addonType="prepend"><InputGroupText
                        className="input-group-text float-right font-weight-bold">Kód*</InputGroupText></InputGroupAddon>
                      <Input type="text" name="code" id="code"
                             onChange={this.handleChange}
                             value={this.state.code}/>
                    </InputGroup>
                  </Col>
                  <Col md={4}>
                    <InputGroup>
                      <InputGroupAddon color="primary"
                                       addonType="prepend"><InputGroupText
                        className="input-group-text float-right">Példányszám</InputGroupText></InputGroupAddon>
                      <Input type="number" name="number_of_copies" id="number_of_copies"
                             onChange={this.handleChange}
                             value={this.state.number_of_copies}/>
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className={"pl-5 "}>
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon_date" color="primary" addonType="prepend"><InputGroupText
                        className="input-group-text float-right">Nyomda kész</InputGroupText></InputGroupAddon>
                      <Input type="date" min="2000-01-01" max="2025-12-31" name="press_done" id="press_done" onChange={this.handleChange}
                             value={this.state.press_done}/>
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon_date" color="primary" addonType="prepend"><InputGroupText
                        className="input-group-text float-right">Fizetési határidő</InputGroupText></InputGroupAddon>
                      <Input type="date" min="2000-01-01" max="2025-12-31" name="payment_deadline" id="payment_deadline" onChange={this.handleChange}
                             value={this.state.payment_deadline}/>
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon_date" color="primary" addonType="prepend"><InputGroupText
                        className="input-group-text float-right">Teljesítés dátuma</InputGroupText></InputGroupAddon>
                      <Input type="date" min="2000-01-01" max="2025-12-31" name="completion_date" id="completion_date" onChange={this.handleChange}
                             value={this.state.completion_date}/>
                      <InputGroup>
                      </InputGroup>
                      <InputGroupAddon className="input-group-addon_date" color="primary" addonType="prepend"><InputGroupText
                        className="input-group-text float-right">Számlázási dátum</InputGroupText></InputGroupAddon>
                      <Input type="date" min="2000-01-01" max="2025-12-31" name="invoice_date" id="invoice_date" onChange={this.handleChange}
                             value={this.state.invoice_date}/>
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon_date" color="primary" addonType="prepend"><InputGroupText
                        className="input-group-text float-right">Aktív zárás</InputGroupText></InputGroupAddon>
                      <Input type="date" min="2000-01-01" max="2025-12-31" name="active_close" id="active_close" onChange={this.handleChange}
                             value={this.state.active_close}/>
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon_date" color="primary" addonType="prepend"><InputGroupText
                        className="input-group-text float-right">Sales zárás</InputGroupText></InputGroupAddon>
                      <Input type="date" min="2000-01-01" max="2025-12-31" name="sales_close" id="sales_close" onChange={this.handleChange}
                             value={this.state.sales_close}/>
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon_date" color="primary" addonType="prepend"><InputGroupText
                        className="input-group-text float-right">Eladott</InputGroupText></InputGroupAddon>
                      <Input disabled className="input-currency" type="number" name="sum_sold" id="sum_sold" readOnly
                             value={this.state.sum_sold}/>
                    </InputGroup>
                  </Col>
                  <Col md="6" className="App-fill-width pl-5 ">
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon_date" color="primary" addonType="prepend"><InputGroupText
                        className="input-group-text float-right">Listák kiküldése</InputGroupText></InputGroupAddon>
                      <Input type="date" min="2000-01-01" max="2025-12-31" name="list_send_date" id="list_send_date" onChange={this.handleChange}
                             value={this.state.list_send_date}/>
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon_date" color="primary" addonType="prepend"><InputGroupText
                        className="input-group-text float-right">Terjesztés</InputGroupText></InputGroupAddon>
                      <Input type="date" min="2000-01-01" max="2025-12-31" name="spread_date" id="spread_date" onChange={this.handleChange}
                             value={this.state.spread_date}/>
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon_date" color="primary" addonType="prepend"><InputGroupText
                        className="input-group-text float-right">Korrektúra</InputGroupText></InputGroupAddon>
                      <Input type="date" min="2000-01-01" max="2025-12-31" name="correcture" id="correcture" onChange={this.handleChange}
                             value={this.state.correcture}/>
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon_date" color="primary" addonType="prepend"><InputGroupText
                        className="input-group-text float-right">Előleg határidő</InputGroupText></InputGroupAddon>
                      <Input type="date" min="2000-01-01" max="2025-12-31" name="advance_payment_date" id="advance_payment_date"
                             onChange={this.handleChange} value={this.state.advance_payment_date}/>
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon_date" color="primary" addonType="prepend"><InputGroupText
                        className="input-group-text float-right">Üzletkötő aláírása</InputGroupText></InputGroupAddon>
                      <Input type="date" min="2000-01-01" max="2025-12-31" name="salesperson_signature" id="salesperson_signature"
                             onChange={this.handleChange} value={this.state.salesperson_signature}/>
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon_date" color="primary" addonType="prepend"><InputGroupText
                        className="input-group-text float-right">Nyomdába küldés</InputGroupText></InputGroupAddon>
                      <Input type="date" min="2000-01-01" max="2025-12-31" name="sent_to_press" id="sent_to_press" onChange={this.handleChange}
                             value={this.state.sent_to_press}/>
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon className="input-group-addon_date" color="primary" addonType="prepend"><InputGroupText
                        className="input-group-text float-right">Kifizetett</InputGroupText></InputGroupAddon>
                      <Input  disabled className="input-currency" type="number" name="sum_paid" id="sum_paid" readOnly
                             value={this.state.sum_paid}/>
                    </InputGroup>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row className="justify-content-end">
                  <Col className="text-right">
                    <Button color="primary" onClick={this.createOrUpdate}>Mentés</Button>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
    );
  }

}


const mapStateToProps = (state) => {
  const productEditId = state.product.productId;
  return{
    productEditId
  }
};

const mapDispatchToProps = dispatch => ({
  getOffices: () => {
    return dispatch(getRequest('/kisokostest/offices/'))
  },
  getProduct: (id) => {
    return dispatch(getRequest('/kisokostest/productscalc/?id=' + id))
  },
  createEntry: (data) => {
    return dispatch(postRequest('/kisokostest/products/',data))
  },
  deleteEntry: (id) =>{
    return dispatch(deleteRequest('/kisokostest/products/' + id))
  },
  saveEntry: (id,data) =>{
    return dispatch(putRequest('/kisokostest/products/' +id,data))
  },
  requery: () =>{
    dispatch(dispatch(invalidateQuery('products')))
  },
  setEdited: (id) =>{
    dispatch(openProduct(id))
  },
  toggleModal: id => {
    dispatch(toggleModal(id));
  }
});

export default connect(mapStateToProps,mapDispatchToProps)(ProductForm)
