import {CLOSE_COLLEAGUE, OPEN_COLLEAGUE} from "../actions/colleague";

const colleague = (state= {
  colleagueId: undefined,
}, action) =>{
  switch (action.type) {
    case OPEN_COLLEAGUE:
      return{
        ...state,
        colleagueId: action.colleagueId,
      };
    case CLOSE_COLLEAGUE:
      return{
        ...state,
        colleagueId: undefined,
      };
    default:
      return state;
  }
};

export default colleague;
