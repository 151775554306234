import {AUTH_SUCCESS, LOGOUT} from '../actions/auth.js';

const auth = (state = {
  isAuthenticated: false,
  name: '',
  token: '',
}, action) => {
  switch (action.type) {
  case AUTH_SUCCESS:
    return {
      ...state,
      isAuthenticated: true,
      name: action.name,
      token: action.token,
    };
  case LOGOUT:
    return {
      ...state,
      isAuthenticated: false,
      name: '',
      token: ''
    };
  default:
    return state;
  }
};

export default auth;
