import React, { Component } from "react";
import { connect } from "react-redux";
import { getRequest, getRequestBlob } from "../actions/api";
import { Col, Row, Input, InputGroup, InputGroupAddon, InputGroupText, Button, Form, Table } from "reactstrap"
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'

const invoiceOptions = [
  { value: 'bill', label: 'Díjbekérő' },
  { value: 'advance', label: 'Előlegszámla' },
]

class InvoicesPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      client: '',
      contractId: '',
      dateAfter: '',
      dateBefore: '',
      type: '',
      results: [],
      isFetched: false,
      isFetching: false
    };

    this.filterInvoices = this.filterInvoices.bind(this);
  }

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => {
      const newState = { ...prevState };
      newState[name] = value;
      return newState;
    });
  };

  onChangeInvoiceType = (inputValue, actionMeta) => {
    let value = '';
    switch (actionMeta.action) {
      case 'select-option':
        value = inputValue.value;
        this.setState({
          type: value
        });
        return;
      case 'remove-value':
        this.setState({
          type: value
        });
        return;
      case 'clear':
        this.setState({
          type: value
        });
        return;
      default:
        return
    }
  };


  // Wrapper for downloading a single file from the getFileById data access function
  getInvoiceById = (invoiceNumber) => {
    this.props.getInvoiceById(invoiceNumber).then(response => {
      console.log("Responce", response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'invoice.pdf');
      document.body.appendChild(link);
      link.click();
    })
  }

  filterInvoices = () => {
    const { client, contractId, dateAfter, dateBefore, type } = this.state
    let urlEnding = "?"
    if(client !== ''){
      urlEnding += `&clientName=${client}`
    }
    if(contractId !== ''){
      urlEnding += `&contractNumber=${contractId}`
    }
    if(dateAfter !== ''){
      urlEnding += `&dateAfter=${dateAfter}`
    }
    if(dateBefore !== ''){
      urlEnding += `&dateBefore=${dateBefore}`
    }
    if(type !== ''){
      urlEnding += `&invoiceType=${type}`
    }    
    this.props.filterInvoices(urlEnding).then(response => {
      console.log('filterInvoices.then', response.data.results);
      this.setState({
        isFetched: true,
        isFetching: false,
        results: response.data.results
      })
    })
  }

  compare = ( a, b ) => {
    //console.log('compare', a, b);
    if ( a.id < b.id ){
      return 1;
    }
    if ( a.id > b.id ){
      return -1;
    }
    return 0;
  }

  render() {
    return (
      <div style={{ margin: "0 auto", width: "1000px" }}>
        <Form onSubmit={e => {
          e.preventDefault();
          let value
          if (this.state.dateBefore === "" && this.state.dateAfter !== "") {
            let date = new Date().getDate();        // Current Date
            let month = new Date().getMonth() + 1;  // Current Month
            let year = new Date().getFullYear();    // Current Year
            if (month < 10) {
              month = '0' + month;
            }
            value = year + '-' + month + '-' + date;
            this.setState({ dateBefore: value.toString() });
          }

          // Call function to filter with API
          this.setState({ isFetching: true }, () => this.filterInvoices())

        }}>
          <Row className="mb-3">
            <Col md={5}>
              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right">
                    Ügyfélnév
                </InputGroupText>
                </InputGroupAddon>
                <div className="aligning_space" style={{ padding: "0 16px 0 0" }} />
                <Input
                  className="input-search"
                  onChange={this.handleChange}
                  value={this.state.client}
                  type="text"
                  name="client"
                  id="client"
                  placeholder="Ügyfélnév" />
              </InputGroup>
              <InputGroup style={{ margin: "8px 0px 0px" }}>
                <InputGroupAddon color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right">
                    Szerződés azonosító
                </InputGroupText>
                </InputGroupAddon>
                <Input
                  className="input-search"
                  onChange={this.handleChange}
                  value={this.state.contractId}
                  type="text"
                  name="contractId"
                  id="contractId"
                  placeholder="Szerződés azonosító" />
              </InputGroup>
            </Col>

            <Col md={5.5}>
              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right">
                    Létrehozás dátuma
                </InputGroupText>
                </InputGroupAddon>
                <Input
                  className="App-input-date"
                  onChange={this.handleChange}
                  value={this.state.dateAfter}
                  type="date"
                  min="2000-01-01"
                  max="2025-12-31"
                  name="dateAfter"
                  id="dateAfter"
                  placeholder="Dátum nagyobb mint" />
              -
              <Input
                  className="App-input-date"
                  onChange={this.handleChange}
                  value={this.state.dateBefore}
                  type="date"
                  min="2000-01-01"
                  max="2025-12-31"
                  name="dateBefore"
                  id="dateBefore"
                  placeholder="Dátum ig" />
              </InputGroup>
              <InputGroup>
                <InputGroupAddon color="primary" addonType="prepend">
                  <InputGroupText className="input-group-text float-right align-items-baseline" style={{ margin: "10px 0px 0px" }}>
                    Számla típusa
                </InputGroupText>
                  <div style={{ width: 5 }}></div>
                  <Select name="selected_fil"
                    isClearable={true}
                    isSearchable={true}
                    className='App-select-invoice'
                    onChange={this.onChangeInvoiceType}
                    options={invoiceOptions} />
                </InputGroupAddon>
              </InputGroup>
              <InputGroup className="App-searchLine" >
                <Col className={"p-0"}>
                  <div className="text-right">
                    <Button outline color="primary">Keresés</Button>
                  </div>
                </Col>
              </InputGroup>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col>
          { this.state.isFetching && "Betöltés..." }
          { this.state.isFetching === false && this.state.isFetched === true && this.state.results.length === 0 && "Nincs eredmény." }
          { this.state.isFetching === false && this.state.isFetched === true && this.state.results.length > 0 && (
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Számlaszám</th>
                  <th>Letöltés</th>
                </tr>
              </thead>
              <tbody>
                {this.state.results.sort(this.compare).map(result => {
                  return (
                    <tr key = {`td-${result.invoiceNumber}`}>
                      <td>{result.invoiceNumber}</td>
                      <td><FontAwesomeIcon className="faBlue" icon={faFileDownload} size="2x" cursor="pointer" onClick={() => this.getInvoiceById(result.invoiceNumber)} /></td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const editClientId = state.client.clientId;
  return {
    editClientId
  }
};

const mapDispatchToProps = dispatch => ({
  filterInvoices: url => {
    console.log('filterInvoices url', url);
    return dispatch(getRequest('/kisokostest/invoices-new' + url))
  },
  getInvoiceById: invoiceNumber => {
    console.log('getInvoice invoiceNumber', invoiceNumber);
    return dispatch(getRequestBlob('/kisokostest/invoice/pdf?invoiceNumber=' + invoiceNumber))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesPage);

