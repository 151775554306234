import {ALERT_TOGGLE, SET_ALERT} from "../actions/apiresponse";

const apiresponse = (state = {isOpen: false}, action) => {
  switch (action.type) {
  case ALERT_TOGGLE:{
      const newState = {...state};
      newState.isOpen = !state.isOpen;
      return newState;
  }
  case SET_ALERT:{
    const newState = {...state};
    newState.text =  action.text;
    newState.color =  action.color;
    newState.isOpen = true;
    return newState;
  }
  default:
      return state;
  }
};

export default apiresponse;
