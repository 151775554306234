import React from "react";
import {connect} from "react-redux";
import {Button, Col, Label, Modal as BModal, ModalBody, Row} from "reactstrap";
import {addModal, removeModal, toggleModal} from '../actions/modal.js';
import ModalHeader from "reactstrap/es/ModalHeader";
import move from "../icons/moveable.svg";
import close from "../icons/times.png";
import ReactTooltip from "react-tooltip";

import Moveable from "react-moveable";
import { ref } from "framework-utils";

class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      target: null,
      container: null,
      moveableColor: "firebrick",
    };

    props.addModal();
  }

  componentDidMount() {
    window.addEventListener("resize", this.moveable.updateRect());
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.moveable.updateRect());
    this.props.removeModal();
  }

  toggleMoveable(){
    if (this.state.isMoveable === true){
      this.setState({
        target: "",
        isMoveable: false,
        moveableColor: "firebrick"
      });
    }
    else{
      // Get all nodes with the 'moveable' className
      let nodes = document.querySelectorAll(".moveable");
      
      this.setState({
        target: nodes[nodes.length - 1],
        isMoveable: true,
        moveableColor: "forestgreen"
      });
    } 
  }

  render() {
    const { target } = this.state;
    const className_moveable = this.props.className + " moveable";
    const img_filtering = "moveable-img " + this.state.moveableColor;
    
    return (
      <div className="page main">
        <div className="container-moveable">
          <BModal className={className_moveable} 
                  backdrop={false} 
                  isOpen={this.props.isOpen} 
                  toggle={() => this.props.toggleModal()}>
            <ModalHeader>
              <Row >
                <Col className="col-9 justify-content-md-start text-left ">
                  <Label className="App-label App-label-title ">{this.props.headerName}</Label>
                </Col>

                <Col className="col-3 justify-content-md-end text-right">
                  <div className="moveable-div" onClick={() => this.toggleMoveable()}>
                      <img alt="Moveable-sign" src={move} className={img_filtering} data-tip="Mozgatás"/>
                  </div>
                  <Button className="App-icon-button" color="danger" onClick={() => this.props.toggleModal()}>
                    <img className="App-icon-button" src={close}  alt={"Bezárás"} data-tip="Bezárás"/>
                    <ReactTooltip />
                  </Button>
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody>
              {
                React.Children.map(this.props.children, child => React.cloneElement(child, {modalId: this.props.id}))
              }
            </ModalBody>
          </BModal>
        </div>
        <Moveable
            ref={ref(this, "moveable")}
            target={target}
            container={document.body}
            draggable={true}
            origin={false}
            throttleDrag={0}
            onDrag={({ target, left, top }) => {
              //target.style.transform = transform;
              target.style.left = `${left}px`;
              target.style.top = `${top}px`;
            }}
        />
    </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const modal = state.modal[ownProps.id];

  const isOpen = modal ? modal.isOpen : false;

  return {
    isOpen
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  addModal: () => {
    dispatch(addModal(ownProps.id));
  },
  removeModal: () => {
    dispatch(removeModal(ownProps.id));
  },
  toggleModal: () => {
    dispatch(toggleModal(ownProps.id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
