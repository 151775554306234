import {CLOSE_PAYMENT, OPEN_PAYMENT} from "../actions/payment";

const payment = (state= {
  paymentId: undefined,
}, action) =>{
  switch (action.type) {
    case OPEN_PAYMENT:
      return{
        ...state,
        paymentId: action.paymentId,
      };
    case CLOSE_PAYMENT:
      return{
        ...state,
        paymentId: undefined,
      };
    default:
      return state;
  }
};

export default payment;
