import React, { Component } from "react";
import { connect } from "react-redux";
import { getRequest, getRequestBlob } from "../actions/api";
import FilterFormFile from "../FilterForm/FilterFormFile";
import { Col, Label } from "reactstrap"
import QueryPage from "../components/QueryPage";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'

class FilesPage extends Component {

  // Wrapper for downloading a single file from the getFileById data access function
  getFile = (id, fileName) => {
    this.props.getFileById(id).then(response => {
      console.log('getFile', response.data);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const modifiedName = fileName.split(".pdf")[0]
      link.setAttribute('download', modifiedName + '.' + response.data.type);
      document.body.appendChild(link);
      link.click();
    })
  }

  // Query all of the files from the backend on mount
  // filterfiles
  // componentDidMount() {
  //   this.props.getAllFiles().then(response => {
  //       this.setState({ files: response.data.files })
  //     })
  // }

  render() {
    
    return (
      <div>
        <QueryPage
          queryTableID='files'
          filterForm={<FilterFormFile/>}
          pageURL="/kisokostest/files/all"
          optionalElements={
            [
              <Col className="col-1 justify-content-md-start text-left App-page-label">
                <Label className="App-label App-label-title">Fájlok</Label>
              </Col>
            ]
          }
          headers={
            [
            {
              name: 'Dokumentumnév',
              processor: object => object.title,
              className: "Table-title-file",
              orderName: "title"
            },{
              name: 'Feltöltés dátuma',
              processor: object => object.uploaded.toString().slice(0,10).replace(/-/gi, '.') + '.',
              className: "Table-uploaded-file",
              orderName: "uploaded"
            },{
              name: 'Letöltés',
              processor: object => <FontAwesomeIcon className="faBlue" icon={faFileDownload} size="2x" cursor="pointer" onClick={() => this.getFile(object.id, object.title)} />,
              className: "Table-attachmentFileId",
              orderName: "attachmentFileId",
              ignoreExport: true
            },{
              name: '',
              processor: object=> "",
              className: "Table-filler",
              ignoreExport: true
            }
            ]
          }
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const editClientId = state.client.clientId;
  return {
    editClientId
  }
};

const mapDispatchToProps = dispatch => ({
  getFileById: (fileId) => {
    return dispatch(getRequestBlob('/kisokostest/files/' + fileId))
  },
  getAllFiles: () => {
    return dispatch(getRequest('/kisokostest/files/all'))
  },
  filterFiles: filterList => {
    return dispatch(getRequest('/kisokostest/files/all' + filterList))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(FilesPage);

