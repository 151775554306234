import React, { Component } from "react";
import { Table, Alert } from "reactstrap"

export function formatNumberToGroupOfThousands(rawNumber) {
    const rawNumberAsString = rawNumber.toString()
    let formattedNumberAsString = ''
    const stringLength = rawNumberAsString.length
    let counter = 1
    for(var i = 0; i < stringLength; i++) {
      const char = rawNumberAsString[stringLength-1-i]
      formattedNumberAsString += char
      if(counter % 3 === 0 && (i !== stringLength-1)) {
        formattedNumberAsString += '.'
      }
      counter++
    }
    formattedNumberAsString = formattedNumberAsString.split("").reverse().join("")
    return formattedNumberAsString
  }

export default class ReportsTable extends Component {
    render() {
      let alertMargin = '0px'
      let emailResponse
      // We are trying to send an email
      if(this.props.emailResponse.length !== 0) {
        // Feedback depending on emailResponse's succesfulSending variable 
        emailResponse = this.props.emailResponse[0].successfulSending !== true ? 
        (
          <Alert className="reportspage" color="danger">
            Hiba lépett fel az email küldése során a szolgáltatónál. Vegye fel a kapcsolatot a fejlesztő csapattal!
          </Alert>
        ) : (
          <Alert className="reportspage" color="success">
            Az eredményeket tartalmazó emailt kiküldtük a megadott email címre
          </Alert>
        )
        alertMargin = '55px'
      }
      
      const salespersons = this.props.data.map(entry => {
        console.log('Salesperson', entry);
        const salesperson = entry
        const contracts = entry.details
        console.log('salesperson', salesperson);
        let contractsRendered
        if(contracts.length === 0) {
            contractsRendered = (
                <tr key = {`${salesperson.salesperson.email}-no-contracts`}>
                    <td rowSpan = {contracts.length+1} style={{ verticalAlign: 'top' }} >{salesperson.salesperson.name}</td>
                    <td colSpan = {2}>{entry.summary.numberOfContracts} db</td>
                    <td style = {{ textAlign: 'right' }}>{formatNumberToGroupOfThousands(Math.round(entry.summary.totalSumForAllContracts))}</td>
                    <td style = {{ textAlign: 'right' }}>{formatNumberToGroupOfThousands(Math.round(entry.summary.totalSumForAllContracts * 1.27))}</td>
                </tr>
            )
        }
        else {
            contractsRendered = contracts.map((contract, index) => {
                //console.log('contract', contract);
                const productsInContract = contract.products.map((product, index) => {
                  //console.log('product', product);
                  return product.product + (index !== contract.products.length - 1 ? ', ' : '')
                })
                if(index === 0) {
                    //console.log('index === 0', entry);
                  return (
                  <>
                  <tr key = {`${salesperson.salesperson.email}-${contract.contract}-first`}>
                    <td rowSpan = {contracts.length+1} style={{ verticalAlign: 'top' }} >{salesperson.salesperson.name}</td>
                    <td colSpan = {2}>{entry.summary.numberOfContracts} db</td>
                    <td style = {{ textAlign: 'right' }}>{formatNumberToGroupOfThousands(Math.round(entry.summary.totalSumForAllContracts))}</td>
                    <td style = {{ textAlign: 'right' }}>{formatNumberToGroupOfThousands(Math.round(entry.summary.totalSumForAllContracts * 1.27))}</td>
                  </tr>
                  <tr key = {`${salesperson.salesperson.email}-${contract.contract}-second`}>
                    <td>{contract.contract}</td>
                    <td>{productsInContract}</td>
                    <td style = {{ textAlign: 'right' }}>{formatNumberToGroupOfThousands(Math.round(contract.summary.totalSumOfProducts))}</td>
                    <td style = {{ textAlign: 'right' }}>{formatNumberToGroupOfThousands(Math.round(contract.summary.totalSumOfProducts * 1.27))}</td>
                  </tr>
                  </>
                  )
                }
                else {
                  return (
                  <tr key = {`${salesperson.salesperson.email}-${contract.contract}`}>
                    <td>{contract.contract}</td>
                    <td>{productsInContract}</td>
                    <td style = {{ textAlign: 'right' }}>{formatNumberToGroupOfThousands(Math.round(contract.summary.totalSumOfProducts))}</td>
                    <td style = {{ textAlign: 'right' }}>{formatNumberToGroupOfThousands(Math.round(contract.summary.totalSumOfProducts * 1.27))}</td>
                  </tr>
                )
                }
              })
        }
        return contractsRendered
      })
  
      // If we clicked the email sending button on Cash payment querying, we do not need the table
      return (
        <div>
          <div>{emailResponse}</div>
          {this.props.data[0].salesperson === "Cash payment" ? 
            (
              null
            ):(
              <Table bordered hover style={{ marginTop: alertMargin }}>
                <thead>
                  <tr>
                    <th>Üzletkötő</th>
                    <th>Szerződés</th>
                    <th>Kiadványok</th>
                    <th style = {{ textAlign: 'right' }}>Összeg (nettó, HUF)</th>
                    <th style = {{ textAlign: 'right' }}>Összeg (bruttó, HUF)</th>
                  </tr>
                </thead>
                <tbody>{salespersons}</tbody>
              </Table>
            )
          }
        </div>
      )
    }
  }