import React from 'react';
import {getRequest} from '../actions/api.js';
import {addQuery, invalidateQuery, removeQuery, validateQuery} from '../actions/query.js';
import {connect} from 'react-redux';
import {Button, Col, Row, Table} from "reactstrap";

import exporticon from "../icons/export.png";
import left from "../icons/left.png";
import right from "../icons/right.png";
import up from "../icons/up_blue.png";
import down from "../icons/down_blue.png";
import ReactTooltip from "react-tooltip";


class QueryTable extends React.Component {
  constructor(props) {
    super(props);

    this.data = [];
    this.links = {};
    this.page = 1;
    this.pageCount = 1;
    this.querying = false;
    this.orderInfo = {
      headerIndex: -1,
      order: '',
      by: ''
    };

    props.addQuery(props.defaultURL, props.defaultData);
    
    
  }

  componentWillUnmount() {
    this.props.removeQuery();
  }

  objectToColumns = object => (
    this.props.headers.map((item, index) => (
      <td key={index}  className={item.className}>
        {item.processor(object)}
      </td>
    ))
  );

  getHeaderLink = (index, header) => {
    if (!header.orderName)
      return (
        header.name
      );
    if (index === this.orderInfo.headerIndex)
      return (
        <a href='/#' onClick={e => {
          e.preventDefault();
          if (this.orderInfo.order === '-')
            this.orderInfo.headerIndex = -1;
          else
	    this.orderInfo.order = '-';

          this.props.invalidate();
        }}>{header.name}{this.orderInfo.order === '-' ? <img className="App-icon-button" src={up}  alt={"up"} /> : <img className="App-icon-button" src={down}  alt={"Le"} />}</a>
      );

      if (header.orderName === "clientId" || header.orderName === "clientName" ||
          header.orderName === "clientEmailAddress" ||
          header.orderName === "attachmentFileName" || header.orderName === "attachmentFileId")
      return (
        header.name
      );

    return (
      <a href='/#' onClick={e => {
        e.preventDefault();
        this.orderInfo.headerIndex = index;
        this.orderInfo.order = '';
        this.orderInfo.by = header.orderName;

        this.props.invalidate();
      }}>{header.name}</a>
    );
  };

  dataToTable = () => {

    
    return (
      <Table className="table-striped" id={this.props.id}>
        <thead>
          <tr>
            {
              this.props.headers.map((item, index) => (
                <th key={index} className={item.className}>
                  {this.getHeaderLink(index, item)}
                </th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {
            this.data && this.data.map((item, index) => (
              <tr key={index}>
                {
		  this.objectToColumns(item)
                }
              </tr>
            ))
          }
        </tbody>
      </Table>

    );
  };

  queryData = () => {
    if (this.querying)
      return;

    if (!this.props.relativeURL) {
      this.data = this.props.data;
      this.props.validate();
    } else {
      this.querying = true;
      this.props.queryData(this.props.relativeURL, this.orderInfo).then(response => {
        
        this.pageCount = Math.ceil(response.data.count / response.data.page_size);
        this.page = response.data.page;
        this.data = response.data.results;
        this.links = response.data.links;
        this.props.validate();
      }).catch(response => {
        
      }).then(() => {
        this.querying = false;
      });
    }
  };

  queryDataForBackendTesting = () => {
    if (this.querying)
      return;

    if (!this.props.relativeURL) {
      this.data = this.props.data;
      this.props.validate();
    } else {
      this.querying = true;
      this.props.queryData(this.props.relativeURL, this.orderInfo).then(response => {
        
        this.props.handleBackendResponse(response.data)
      }).catch(response => {
	
      }).then(() => {
	      this.querying = false;
      });
    }
  };

  download = (filename, text) => {
    var element = document.createElement('a');
    var universalBOM = "\uFEFF";
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(universalBOM + text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  exportData = () => {
    let URL = this.props.relativeURL;

    if (URL.indexOf('?') < 0)
      URL = `${URL}?get_all=true`;
    else
      URL = URL.replace('?', '?get_all=true&');

    this.props.queryData(URL, this.orderInfo).then(response => {
      let headerNameArray = [];
      this.props.headers.forEach(header => {
        if (!header.ignoreExport)
          headerNameArray = [...headerNameArray, header.name];
      });

      let csvArray = [headerNameArray.join(';')];

      response.data.results.forEach(object => {
        let objectArray = [];
        this.props.headers.forEach(header => {
          if (!header.ignoreExport) {
            const exportFunc = header.exportProcessor || header.processor;
	          objectArray = [...objectArray, exportFunc(object)];
          }
        });

        csvArray = [...csvArray, objectArray.join(';')];
      });

      this.download('export.csv', csvArray.join('\n'));
    });
  };

  render() {
    if (this.props.isValid)
      return (
        <div>
          <Row>
            <Col>
              {this.dataToTable()}
            </Col>
          </Row>
          <Row>
            <Col md={2} className="text-left" style={{ margin: "5px 0 0 5px" }} >
              {this.props.relativeURL && this.data.length > 0 &&<Button className="App-icon-button" color="primary"  onClick={() => this.exportData()}><img className="App-icon-button" src={exporticon}  alt={"Exportálás"} data-tip="Export"/><ReactTooltip /></Button>}
            </Col>
            <Col md={4} style={{ margin: "5px auto", width: 250 }}>
              {this.links.previous && <Button className="App-icon-button" color="primary" onClick={() => this.props.addQuery(this.links.previous)}><img className="App-icon-button" src={left}  alt={"előző"} data-tip="Előző"/><ReactTooltip /></Button>}
              {this.pageCount > 1 && <span style={{ margin: "0 10px" }}>{this.page} / {this.pageCount}</span>}
              {this.links.next && <Button className="App-icon-button" color="primary" onClick={() => this.props.addQuery(this.links.next)}><img className="App-icon-button" src={right}  alt={"Következő"} data-tip="Következő"/><ReactTooltip /></Button>}
            </Col>
            <Col md={2}>
            </Col>
          </Row>
        </div>
      );

    //this.props.backend ? this.queryDataForBackendTesting() : this.queryData();
    this.queryData()
    return (
      <p>Betöltés...</p>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const query = state.query[ownProps.id];

  const relativeURL = query ? query.relativeURL : ownProps.defaultURL;
  const isValid = query ? query.isValid : false;
  const data = query ? query.data : ownProps.defaultData || relativeURL ? undefined : [];

  return {
    relativeURL,
    isValid,
    data
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  addQuery: (relativeURL, data) => {
    dispatch(addQuery(ownProps.id, relativeURL, data));
  },
  queryData: (relativeURL, orderInfo) => {
    let URL = relativeURL;

    if (orderInfo) {
      if (orderInfo.headerIndex !== -1) {
        if (URL.indexOf('?') < 0)
          URL = `${URL}?order_by=${orderInfo.order}${orderInfo.by}&`;
        else
          URL = URL.replace('?', `?order_by=${orderInfo.order}${orderInfo.by}&`);
      }
    }

    return dispatch(getRequest(URL));
  },
  validate: () => {
    dispatch(validateQuery(ownProps.id));
  },
  invalidate: () => {
    dispatch(invalidateQuery(ownProps.id));
  },
  removeQuery: () => {
    dispatch(removeQuery(ownProps.id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(QueryTable);
